/*
GetCustomer API
*/
const customers = {
    "customers": [
      {
        "id": "420bc17c-e104-49e0-9c85-af927a6e589a",
        "code": "20038",
        "name": "Customer Name"
      }
    ]
  }

/*
GetCustomerTopProducts API
*/
const customerTopProducts = {
	"customer": {
		"id": "420bc17c-e104-49e0-9c85-af927a6e589a",
		"code": "20038",
		"name": "Customer Name"
	},
	"top_products": [{
		"qty": 30,
		"code": "ML-52670",
		"itemId": "1badc63d-970b-436a-26e1-57836e0ed3a2",
		"description": "Test Product 124"
	}, {
		"qty": 17,
		"code": "MSU-87490",
		"itemId": "cfc20d52-9391-49d1-8181-f77763ac3d59",
		"description": "Test Product 222"
	},]
}

const selectedProducts = 
	[{
		"qty": 30,
		"code": "ML-52670",
		"itemId": "1badc63d-970b-436a-26e1-57836e0ed3a2",
		"description": "Test Product 124"
	},]


/*
GetCustomerProducts API
*/
const customerProducts = {
	"products": [{
		"qty": 30,
		"code": "ML-52670",
		"itemId": "1badc63d-970b-436a-26e1-57836e0ed3a2",
		"description": "Test Product 124"
	}, {
		"qty": 17,
		"code": "MSU-87490",
		"itemId": "cfc20d52-9391-49d1-8181-f77763ac3d59",
		"description": "Test Product 222"
	}]
}

const mockInventory = {
    "products": [
        {
            "code": "NVDPROTECTION1",
            "description": "Navidium Shipping Protection 0.75",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 29,
            "current_stock": -228
        },
        {
            "code": "Shipment",
            "description": "Shipment",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 63,
            "current_stock": -2861
        },
        {
            "code": "CTHS250-10048011",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Pure Herbs Shampoo 250 ml in bottle Continental.\r\n6 stuks pe",
            "sold": 14,
            "current_stock": 1
        },
        {
            "code": "A58003",
            "description": "waste bin deksel Black 8 liter",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 1,
            "current_stock": -1
        },
        {
            "code": "TBDG250-100200-12",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Hydro Basics 250ml Bath & Shower Gel in bottle Tall Boston R",
            "sold": 19,
            "current_stock": 17
        },
        {
            "code": "PAHB300-100630-15",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Aqua Senses Hand- und Body Lotion 300 ml pompfles.",
            "sold": 5,
            "current_stock": -1
        },
        {
            "code": "NAR300CNHBL-2",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Naturals Remedies Hand & Body Lotion No.14 300ml pomp dispen",
            "sold": 11,
            "current_stock": -15
        },
        {
            "code": "NVDPROTECTION0",
            "description": "Navidium Shipping Protection Default",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 35,
            "current_stock": -1055
        },
        {
            "code": "7342512",
            "description": "Olivia Thinks Shampoo 400ml",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 29,
            "current_stock": 15
        },
        {
            "code": "SMBS300-100620-1",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Eco by green culture 300 ml shampoo hair & body in bottle sm",
            "sold": 150,
            "current_stock": 92
        },
        {
            "code": "30",
            "description": "Eco by Green Culture Liquid Soap - smart care, 300ml",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 60,
            "current_stock": -60
        },
        {
            "code": "A57901 SAT",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Waste bin 8 liter Satin",
            "sold": 1,
            "current_stock": -5
        },
        {
            "code": "PABS300-100630-15",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Aqua Senses Hair & Body Shampoo 300ml",
            "sold": 1,
            "current_stock": -10
        },
        {
            "code": "bundle-product",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Bundel Prijs",
            "sold": 1,
            "current_stock": -57
        },
        {
            "code": "SMBS300 100540 NAT300SMS",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Smart Care 300 ml. naturals Hair & shower gel",
            "sold": 7,
            "current_stock": -48
        },
        {
            "code": "172",
            "description": "natte doekjes eigen logo jacob cats",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 1,
            "current_stock": 0
        },
        {
            "code": "40024555552829",
            "description": "Quartz Line Midirol Dispenser Zilver",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 3,
            "current_stock": -3
        },
        {
            "code": "AQS300SMAIO",
            "description": "Aqua Senses, Smart Care Hand, Hair + Body Wash",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 64,
            "current_stock": 40
        },
        {
            "code": "SOT500CIHCG",
            "description": "Hand Cleansing Gel met 70% alcohol, pompfles  500 ml. Doos a",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 5,
            "current_stock": 0
        },
        {
            "code": "NVDPROTECTION2",
            "description": "Navidium Shipping Protection 1.5",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 3,
            "current_stock": -28
        },
        {
            "code": "cos-2100-LAN",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Verticale toiletrolhouder zwart voor 2 rollen",
            "sold": 1,
            "current_stock": -5
        },
        {
            "code": "verzendkosten",
            "description": "verzendkosten binnen nederland",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 2,
            "current_stock": -195
        },
        {
            "code": "100630-5",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Aqua Sence 3 in 1 330 ml. in press and wash",
            "sold": 33,
            "current_stock": -1
        },
        {
            "code": "CTMO250-100480-3",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Pure Herbs Massage Oil 250 ml in bottle Continental . doos a",
            "sold": 5,
            "current_stock": -7
        },
        {
            "code": "930-18-34",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Lights, S-Light, Aqua, prijs per doos van 18 stuks.",
            "sold": 1,
            "current_stock": 0
        },
        {
            "code": "Uni003PSBRA-1PSHA003-1",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Singel Bracket for 300ml.Pisa dispenser",
            "sold": 1,
            "current_stock": -12
        },
        {
            "code": "911-60-90",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Light, Refills, 52x64 mm oranje, 60 Stuks per doos.",
            "sold": 1,
            "current_stock": -1
        },
        {
            "code": "89172",
            "description": "Servetten Linnclass 40x40 1\/4, love nature pebbel , doos a 3",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 5,
            "current_stock": 0
        },
        {
            "code": "4us072121",
            "description": "plastic bekers per stuk verpakt 500 stuks per doos",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 2,
            "current_stock": 1
        },
        {
            "code": "930-18-90",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Lights, S-Light, Oranje, prijs per doos van 18 stuks.",
            "sold": 1,
            "current_stock": -2
        },
        {
            "code": "822-00-50",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q light stompkaarsen ivoor 60 x 100, doos a 40 stuks",
            "sold": 2,
            "current_stock": -1
        },
        {
            "code": "930-18-21",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Lights, S-Light, Rood, prijs per doos van 18 stuks.",
            "sold": 1,
            "current_stock": 0
        },
        {
            "code": "930-18-30",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Lights, S-Light, Lime, prijs per doos van 18 stuks.",
            "sold": 1,
            "current_stock": 0
        },
        {
            "code": "H-364456",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Pedalbinliners 44 cmx 56cm, 24 x 40 stuks per doos.",
            "sold": 1,
            "current_stock": -4
        },
        {
            "code": "777-60-01",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Lights, Refills standaard, 50x65 mm clear 60 stuks per",
            "sold": 3,
            "current_stock": -13
        },
        {
            "code": "911-60-30",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Q-Lights, refills \u00c3\u0192\u00c2\u00b852x64mm lime groen, doos a 60 stuks.",
            "sold": 3,
            "current_stock": -1
        },
        {
            "code": "77",
            "description": "wordt \/ is voor u verzonden",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 1,
            "current_stock": -5
        },
        {
            "code": "A66401 SAT",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Waste bin 8 liter Satin wall mount",
            "sold": 1,
            "current_stock": -5
        },
        {
            "code": "E015879",
            "description": "Bestekpochettes Eigen logo Brasserie Evertsen groen 23 x 39,",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 54,
            "current_stock": 0
        },
        {
            "code": "173",
            "description": "natte doekjes eigen logo raedthuys",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "sold": 1,
            "current_stock": 0
        },
        {
            "code": "MSU-87513",
			"supplier_name": "",
			"supplier_guid": "",
			"product_guid": "",
            "description": "Airlaid servetten Linclass wit 40x40, 1\/4 vouws, 65gr. 300 s",
            "sold": 4,
            "current_stock": 2
        }
    ]
}

/*
CreateOrder API
*/
const createOrder = {
  "customer_id": "420bc17c-e104-49e0-9c85-af927a6e589a",
  "products": [
      {
       "product_id": "e5463560-4226-403c-853d-a0103bc12fdfg1231a",
       "quantity": 2
       },
       {
       "product_id": "e5463560-4226-403c-853d-a0103bc12fdfg1231a",
       "quantity": 2
       },
  ]
}

const mockCustomerSearch = {"customers":[{"id":"420bc17c-e104-49e0-9c85-af927a6e589a","code":"20038","name":"Rest. `t Vissershuis", "phone": "+32468183543", "email": "jos@gmail.com"}, {"id":"420bc17c-e104-49e0-9c85-af927a6e589b","code":"20039","name":"Joske", "phone": "+32468183544", "email": "wannes@tom-it.nl"}]};

const mockSupplierSearch = {"suppliers":[{"id":"420bc17c-e104-49e0-9c85-af927a6e589a","code":"20038","name":"Autobanden"}, {"id":"420bc17c-e104-49e0-9c85-af927a6e589b","code":"20039","name":"Goedhals"}]};


const mockPurchaseOrders = {
	"message": "Purchase order data loaded successfully.",
	"data": [
	  {
		"purchase_order": {
		  "AmountDC": 319.68,
		  "OrderDate": "22-05-2023",
		  "OrderNumber": 326,
		  "OrderStatus": "Open",
		  "PurchaseOrderID": "ccc4ccc1-00ad-4952-9171-330f96b45c1d",
		  "PurchaseOrderLineCount": 2,
		  "ReceiptDate": "22-05-2023",
		  "ReceiptStatus": "Open",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'ccc4ccc1-00ad-4952-9171-330f96b45c1d')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 21.18,
			"AmountFC": 21.18,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "22-05-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Bracket Smart Care System holder chrome to stick pack of 10",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "c267de57-0774-452a-bf0f-5161fd886258",
			"InvoicedQuantity": 0,
			"InStock": -49,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "c8b8c059-b978-4b5f-9031-581beb769131",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMHA111100005SMA111SMHOL",
			"ItemDescription": "Bracket Smart Care System holder chrome to stick pack of 10 ",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "22-05-2023",
			"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"ModifierFullName": "Thomas Lang",
			"Notes": null,
			"NetPrice": 7.06,
			"PurchaseOrderID": "ccc4ccc1-00ad-4952-9171-330f96b45c1d",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -46,
			"Quantity": 3,
			"QuantityInPurchaseUnits": 3,
			"Rebill": false,
			"ReceiptDate": "23-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 7.06,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 298.5,
			"AmountFC": 298.5,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "22-05-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Aqua Senses bodylotion 35ml",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "2f87db38-d43a-47d1-8a80-ae8b3ab13c46",
			"InvoicedQuantity": 0,
			"InStock": 0,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "44a1976b-af21-4930-ac77-fb030f405a16",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "AQS035SHBOL",
			"ItemDescription": "Aqua Senses bodylotion 35ml",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "22-05-2023",
			"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"ModifierFullName": "Thomas Lang",
			"Notes": null,
			"NetPrice": 0.199,
			"PurchaseOrderID": "ccc4ccc1-00ad-4952-9171-330f96b45c1d",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 5,
			"Quantity": 1500,
			"QuantityInPurchaseUnits": 5,
			"Rebill": false,
			"ReceiptDate": "23-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "AQS035SHBOL",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 59.7,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 2475.77,
		  "OrderDate": "19-05-2023",
		  "OrderNumber": 324,
		  "OrderStatus": "Open",
		  "PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
		  "PurchaseOrderLineCount": 21,
		  "ReceiptDate": "19-05-2023",
		  "ReceiptStatus": "Open",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'a7ecc58d-0fca-4818-ad0d-7274315cd6ce')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 88.08,
			"AmountFC": 88.08,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Body Peeling gel 250 ml in bottle Continental. Pe",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "0b38cd57-0655-4017-982b-0428debd92b9",
			"InvoicedQuantity": 0,
			"InStock": 5,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "14961681-8a73-4819-911c-ae8ece1dafa9",
			"ItemBarcode": "4005162355094",
			"ItemBarcodeAdditional": null,
			"ItemCode": "CTKP250-10048012",
			"ItemDescription": "Pure Herbs Body Peeling gel 250 ml in bottle Continental. Pe",
			"ItemDivisable": false,
			"LineNumber": 15,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.67,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 9,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 4,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "CTKP250-10048012",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 22.02,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 127.8,
			"AmountFC": 127.8,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics 250ml Body Lotion in bottle Tall Boston Round",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "5f925a60-fa1d-40d3-b426-1807c235a86f",
			"InvoicedQuantity": 0,
			"InStock": 0,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "10717ffd-ec4c-48da-bd28-d90d93393f9a",
			"ItemBarcode": "4005162346368",
			"ItemBarcodeAdditional": "4005162346368",
			"ItemCode": "TBBL250-100200-12",
			"ItemDescription": "Hydro Basics 250ml Body Lotion in bottle Tall Boston Round",
			"ItemDivisable": false,
			"LineNumber": 9,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.55,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 36,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 36,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.55,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 73.12,
			"AmountFC": 73.12,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Aqua Senses Hand- Liquid Soap (yellow)",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "1fd4caca-649b-44fb-aee9-229376f5776a",
			"InvoicedQuantity": 0,
			"InStock": 0,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "969b2c71-4006-418b-973a-0089d99724d2",
			"ItemBarcode": "4005162356497",
			"ItemBarcodeAdditional": null,
			"ItemCode": "AQS300PALQS-YW",
			"ItemDescription": "Aqua Senses Hand- Liquid Soap (yellow)",
			"ItemDivisable": false,
			"LineNumber": 21,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.04666666666667,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 2,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 2,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "AQS300PALQS-YW",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 36.56000000000004,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 177,
			"AmountFC": 177,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Shampoo Hair & Body - Smart Care, 300ml",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "63c64505-03e7-4c3e-8cbf-2c6099b8e0d6",
			"InvoicedQuantity": 0,
			"InStock": 148,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "bf082510-ff48-474d-b1c2-63928f54dd9b",
			"ItemBarcode": "4005162337687",
			"ItemBarcodeAdditional": "4005162337687",
			"ItemCode": "PHE300SMSHB",
			"ItemDescription": "Pure Herbs Shampoo Hair & Body - Smart Care, 300ml",
			"ItemDivisable": false,
			"LineNumber": 20,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.95,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 208,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 60,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Liquid Cream Soap 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "102228ac-d01b-4289-973e-351894f9b6b0",
			"InvoicedQuantity": 0,
			"InStock": 5,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "2b37c5e7-463e-4a65-af99-36ac3f21a145",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBLS001100200",
			"ItemDescription": "Hydro Basics Liquid Cream Soap 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 32,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Liquid Cream Soap 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "e850d3aa-3182-4068-82bd-358db4ec41cc",
			"InvoicedQuantity": 0,
			"InStock": 7,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "e41a1481-b668-494c-a7c5-6a6bc398083f",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBLS001-100480",
			"ItemDescription": "Pure Herbs Liquid Cream Soap 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 11,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 34,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 87.76,
			"AmountFC": 87.76,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Aqua Senses Hand- und Body Lotion 300 ml pompfles.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "029081b5-4721-430a-bf27-3d1e9c138c62",
			"InvoicedQuantity": 0,
			"InStock": 0.4166666666666667,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "a878ddc9-4684-4a00-88b4-a30be88827ac",
			"ItemBarcode": "4005162356497",
			"ItemBarcodeAdditional": null,
			"ItemCode": "PAHB300-100630-15",
			"ItemDescription": "Aqua Senses Hand- und Body Lotion 300 ml pompfles.",
			"ItemDivisable": false,
			"LineNumber": 22,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.65666666666667,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 2.4166666666666665,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 2,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "PAHB300-100630-15",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 43.88000000000004,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 65.04,
			"AmountFC": 65.04,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics 250ml Bath & Shower Gel in bottle Tall Boston R",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "971f8d92-c5b3-4127-b54d-6682ef14301e",
			"InvoicedQuantity": 0,
			"InStock": 56,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "e4598595-3e28-4981-b510-c05c3a236ce9",
			"ItemBarcode": "4014029107615",
			"ItemBarcodeAdditional": "4014029107615",
			"ItemCode": "TBDG250-100200-12",
			"ItemDescription": "Hydro Basics 250ml Bath & Shower Gel in bottle Tall Boston R",
			"ItemDivisable": false,
			"LineNumber": 8,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.71,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 80,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.71,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 88.5,
			"AmountFC": 88.5,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Shampoo Hair & Body 300ml, smart care",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "d5a7e83d-e811-48f5-a7f3-77a372b0f89b",
			"InvoicedQuantity": 0,
			"InStock": 120,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "8bf50ee8-2f13-4cfe-8b92-0636333d2d6f",
			"ItemBarcode": "4005162355230",
			"ItemBarcodeAdditional": "4005162355230",
			"ItemCode": "HYB300SMSHB",
			"ItemDescription": "Hydro Basics Shampoo Hair & Body 300ml, smart care",
			"ItemDivisable": false,
			"LineNumber": 5,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.95,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 150,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 97.56,
			"AmountFC": 97.56,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Showergel; 250ml, Prijs perstuk 6 stuks per doos",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "aa627e12-715e-46c8-9f87-7c1e3e0d5f75",
			"InvoicedQuantity": 0,
			"InStock": 15.333333333333334,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "e62147d5-857e-42b1-81af-ed2fed432d6f",
			"ItemBarcode": "4005162128032",
			"ItemBarcodeAdditional": null,
			"ItemCode": "CTDG250-100480-1",
			"ItemDescription": "Pure Herbs Showergel; 250ml, Prijs perstuk 6 stuks per doos",
			"ItemDivisable": false,
			"LineNumber": 18,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.71,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 21.333333333333332,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 6,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "CTDG250-100480-1",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 16.259999999999998,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Shampoo Hair & Body 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "af32b227-12aa-40f6-ad53-8bbdac103c67",
			"InvoicedQuantity": 0,
			"InStock": 48,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "117fd0ba-bbdc-4ff9-948e-ac4543dc9ed5",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBBS001-100200",
			"ItemDescription": "Hydro Basics Shampoo Hair & Body 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 75,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 125.64,
			"AmountFC": 125.64,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "36bdeb85-6fd9-48ea-aa2c-9591ccac6685",
			"InvoicedQuantity": 0,
			"InStock": 57,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "b2c328aa-88ca-4b39-a0be-585d11b9fab1",
			"ItemBarcode": "4005162417518",
			"ItemBarcodeAdditional": "4005162417518",
			"ItemCode": "PSHB300-100480-17",
			"ItemDescription": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
			"ItemDivisable": false,
			"LineNumber": 13,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.49,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 93,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 36,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.49,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.3,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 235.2,
			"AmountFC": 235.2,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Natural Remedies 300 ml, Hair & Body Shampoo Nr. 16 smartcar",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "09699d07-6176-4e9c-af55-96e99274069c",
			"InvoicedQuantity": 0,
			"InStock": 2.7333333333333334,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "2c15e729-e5e0-40c1-8eca-51cd63dcf7a3",
			"ItemBarcode": "4005162443593",
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMBS300-101640",
			"ItemDescription": "Natural Remedies 300 ml, Hair & Body Shampoo Nr. 16 smartcar",
			"ItemDivisable": false,
			"LineNumber": 10,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.92,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 4.733333333333333,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 2,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMBS300-101640",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 117.6,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 83.76,
			"AmountFC": 83.76,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Hand & Bodylotion 300ml pompdispenser",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "18b84433-43ca-4998-9cd6-bd71b591c939",
			"InvoicedQuantity": 0,
			"InStock": 34,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "26732ebb-3d24-4114-9744-a6907780d7a1",
			"ItemBarcode": "4005162411370",
			"ItemBarcodeAdditional": "4005162411370",
			"ItemCode": "PSHB300-100200-16",
			"ItemDescription": "Hydro Basics Hand & Bodylotion 300ml pompdispenser",
			"ItemDivisable": false,
			"LineNumber": 7,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.49,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 58,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.49,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 177,
			"AmountFC": 177,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Liquid cream soap 300ml, Smart Care",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "6ec07646-afef-4307-91a1-bebdea29fa7f",
			"InvoicedQuantity": 0,
			"InStock": 30,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "78cbb85a-a8bb-4581-86fe-d7495f718024",
			"ItemBarcode": "4005162298568",
			"ItemBarcodeAdditional": "4005162298568",
			"ItemCode": "SMLS300-100200-HYB300SML",
			"ItemDescription": "Hydro Basics Liquid cream soap 300ml, Smart Care",
			"ItemDivisable": false,
			"LineNumber": 4,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.95,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 90,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 60,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 79.8,
			"AmountFC": 79.8,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Hand & Koerperlotion - Smart Care, 300ml",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "8bf3004f-aa8a-4b15-948b-c30071024583",
			"InvoicedQuantity": 0,
			"InStock": 0,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "431b2c81-e58f-4162-93b3-3ca6d8dbdec2",
			"ItemBarcode": "4005162415378",
			"ItemBarcodeAdditional": "4005162415378",
			"ItemCode": "PHE300SMHBL",
			"ItemDescription": "Pure Herbs Hand & Koerperlotion - Smart Care, 300ml",
			"ItemDivisable": false,
			"LineNumber": 19,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.66,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 30,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.66,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 104.04,
			"AmountFC": 104.04,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics 300ml Hair & Body Shampoo pompdispenser",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "44e8229d-34d8-45b1-a14c-c9cee3223fb2",
			"InvoicedQuantity": 0,
			"InStock": -2,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "48dd4d0b-27aa-4be2-bf73-418468ac9e5a",
			"ItemBarcode": "4005162362795",
			"ItemBarcodeAdditional": "4005162362795",
			"ItemCode": "PSBS300-100200-17",
			"ItemDescription": "Hydro Basics 300ml Hair & Body Shampoo pompdispenser",
			"ItemDivisable": false,
			"LineNumber": 6,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.89,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 34,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 36,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.89,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.3,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 127.8,
			"AmountFC": 127.8,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Body Lotion 250 ml.  Prijs per stuk. 6 per doos",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "013689bc-c017-4fcb-ad50-cd91b982365a",
			"InvoicedQuantity": 0,
			"InStock": 0,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "2fe26422-645e-4535-b7b9-d312366bb098",
			"ItemBarcode": "4005162128063",
			"ItemBarcodeAdditional": null,
			"ItemCode": "CTBL250-10048012",
			"ItemDescription": "Pure Herbs Body Lotion 250 ml.  Prijs per stuk. 6 per doos",
			"ItemDivisable": false,
			"LineNumber": 16,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 3.55,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 6,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 6,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "CTBL250-10048012",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 21.299999999999997,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 107.1,
			"AmountFC": 107.1,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Hand & Body Lotion 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "726ebb9c-afe5-4442-b54d-e3589198e971",
			"InvoicedQuantity": 0,
			"InStock": 17,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "a87cdd88-bb9e-4c68-81d5-93788f9ec400",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBHB001-100480",
			"ItemDescription": "Pure Herbs Hand & Body Lotion 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 12,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 5.95,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 35,
			"Quantity": 18,
			"QuantityInPurchaseUnits": 18,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 5.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 138.72,
			"AmountFC": 138.72,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "a1b214bb-cdaa-4f28-8409-f8403bc9e5fa",
			"InvoicedQuantity": 0,
			"InStock": 46,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "15bb518f-77fb-43f3-af94-4007e9ac7aef",
			"ItemBarcode": "4005162339247",
			"ItemBarcodeAdditional": "4005162339247",
			"ItemCode": "PSBS300-100480-18",
			"ItemDescription": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
			"ItemDivisable": false,
			"LineNumber": 14,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 2.89,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 94,
			"Quantity": 48,
			"QuantityInPurchaseUnits": 48,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.89,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 107.1,
			"AmountFC": 107.1,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Bodylotion 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "889ea9bf-39b2-4efb-9d91-fd8d27aa4c0d",
			"InvoicedQuantity": 0,
			"InStock": 9,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "d82c6092-ae80-4edc-85be-c6866e7a0495",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBBL001-100200",
			"ItemDescription": "Hydro Basics Bodylotion 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 3,
			"Modified": "19-05-2023",
			"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"ModifierFullName": "Cris Lang",
			"Notes": null,
			"NetPrice": 5.95,
			"PurchaseOrderID": "a7ecc58d-0fca-4818-ad0d-7274315cd6ce",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 27,
			"Quantity": 18,
			"QuantityInPurchaseUnits": 18,
			"Rebill": false,
			"ReceiptDate": "20-05-2023",
			"ReceivedQuantity": 0,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 5.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 51.7,
		  "OrderDate": "09-05-2023",
		  "OrderNumber": 316,
		  "OrderStatus": "Complete",
		  "PurchaseOrderID": "3fd72377-aa49-43f6-b7db-0833e61e45dc",
		  "PurchaseOrderLineCount": 1,
		  "ReceiptDate": "09-05-2023",
		  "ReceiptStatus": "Complete",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'3fd72377-aa49-43f6-b7db-0833e61e45dc')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 51.7,
			"AmountFC": 51.7,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "09-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure herbs Wellness Set prijs per stuk / 5 sets per doos.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "0dc7b563-9ef5-43fb-9b83-d5fc7fe541ab",
			"InvoicedQuantity": 10,
			"InStock": 42,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "593a72ac-eec5-4dad-8f40-36d4f4cd737e",
			"ItemBarcode": "4005162358903",
			"ItemBarcodeAdditional": "4005162358903",
			"ItemCode": "KOSK007-100480-5",
			"ItemDescription": "Pure herbs Wellness Set prijs per stuk / 5 sets per doos.",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 5.17,
			"PurchaseOrderID": "3fd72377-aa49-43f6-b7db-0833e61e45dc",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 42,
			"Quantity": 10,
			"QuantityInPurchaseUnits": 10,
			"Rebill": false,
			"ReceiptDate": "10-05-2023",
			"ReceivedQuantity": 10,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 5.17,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 2775,
		  "OrderDate": "08-05-2023",
		  "OrderNumber": 310,
		  "OrderStatus": "Complete",
		  "PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
		  "PurchaseOrderLineCount": 14,
		  "ReceiptDate": "08-05-2023",
		  "ReceiptStatus": "Complete",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'b4ca3c5b-e2f8-4ab0-8836-292c83016dbd')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 65.04,
			"AmountFC": 65.04,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics 250ml Bath & Shower Gel in bottle Tall Boston R",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "75d8f5d6-034e-4da9-842d-029954968822",
			"InvoicedQuantity": 24,
			"InStock": 56,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "e4598595-3e28-4981-b510-c05c3a236ce9",
			"ItemBarcode": "4014029107615",
			"ItemBarcodeAdditional": "4014029107615",
			"ItemCode": "TBDG250-100200-12",
			"ItemDescription": "Hydro Basics 250ml Bath & Shower Gel in bottle Tall Boston R",
			"ItemDivisable": false,
			"LineNumber": 7,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.71,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 80,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.71,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 86.4,
			"AmountFC": 86.4,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Fair Trade Hand & Bodylotion 300ml pompdispenser Fair CosmEt",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "77af2f81-37cf-4f5d-80de-213e804d6873",
			"InvoicedQuantity": 24,
			"InStock": 105,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "3b80ad0a-d031-4f40-9fbc-6d559a64f536",
			"ItemBarcode": "4005162416344",
			"ItemBarcodeAdditional": "4005162416344",
			"ItemCode": "PSHB300-101100-3",
			"ItemDescription": "Fair Trade Hand & Bodylotion 300ml pompdispenser Fair CosmEt",
			"ItemDivisable": false,
			"LineNumber": 4,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.6,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 105,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "PSHB300-101100-3",
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.6,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 881.28,
			"AmountFC": 881.28,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Naturals Remedies Hand & Body Wash No. 09 300 pomp dispenser",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "117c1b49-edf9-4654-b7b7-2fb56b224c25",
			"InvoicedQuantity": 24,
			"InStock": 28.75,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "425a06dd-9e37-4e2a-8a56-591c2752ed25",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NAR300CNHBW-2",
			"ItemDescription": "Naturals Remedies Hand & Body Wash No. 09 300 pomp dispenser",
			"ItemDivisable": false,
			"LineNumber": 10,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.06,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 28.75,
			"Quantity": 288,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "NAR300CNHBW-2",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 36.72,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 116.7,
			"AmountFC": 116.7,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Fair CosmEthics Body Lotion- smart care, 300ml",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "6ff4abc7-2ef7-4199-bbf3-397596929050",
			"InvoicedQuantity": 30,
			"InStock": 60,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "6902fe48-6260-43c8-b280-6cd3144ecc7c",
			"ItemBarcode": "4005162407175",
			"ItemBarcodeAdditional": "4005162407175",
			"ItemCode": "FCO300SMHBL",
			"ItemDescription": "Fair CosmEthics Body Lotion- smart care, 300ml",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.89,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 60,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 30,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.89,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 73.68,
			"AmountFC": 73.68,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "hyir Trade Liquid Soap 300ml zeeppomp per stuk",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "e80bd12f-1117-4f66-b47d-490859b6a86c",
			"InvoicedQuantity": 24,
			"InStock": 72,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "29185180-0b0b-4abd-9c7e-77d6cc90e58f",
			"ItemBarcode": "4005162365871",
			"ItemBarcodeAdditional": "4005162365871",
			"ItemCode": "PSLS300-101100-3",
			"ItemDescription": "Fair Trade Liquid Soap 300ml zeeppomp per stuk",
			"ItemDivisable": false,
			"LineNumber": 3,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.07,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 72,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "PSLS300-101100-3",
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.07,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 34.68,
			"AmountFC": 34.68,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "670c0a97-6880-48f9-9793-6879fcc06842",
			"InvoicedQuantity": 12,
			"InStock": 46,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "15bb518f-77fb-43f3-af94-4007e9ac7aef",
			"ItemBarcode": "4005162339247",
			"ItemBarcodeAdditional": "4005162339247",
			"ItemCode": "PSBS300-100480-18",
			"ItemDescription": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
			"ItemDivisable": false,
			"LineNumber": 12,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.89,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 94,
			"Quantity": 12,
			"QuantityInPurchaseUnits": 12,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 12,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.89,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 440.4,
			"AmountFC": 440.4,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Naturals Remedies Hair & Body Shampoo No. 16 300 pomp dispen",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "c09de914-40ba-4250-a157-6bbe841547f2",
			"InvoicedQuantity": 12,
			"InStock": 11.5,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "7892f6c3-f6ac-497b-94e6-f0307f0f2617",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NAR300CNSHB-2",
			"ItemDescription": "Naturals Remedies Hair & Body Shampoo No. 16 300 pomp dispen",
			"ItemDivisable": false,
			"LineNumber": 11,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.05833333333333,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 11.5,
			"Quantity": 144,
			"QuantityInPurchaseUnits": 12,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 12,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "NAR300CNSHB-2",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 36.69999999999996,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 88.2,
			"AmountFC": 88.2,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Naturals 300 ml, shampoo Hair & Body smartcare",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "670d0c2d-9a78-4ed5-804a-8e4dfe8f2051",
			"InvoicedQuantity": 30,
			"InStock": 60,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "a8a3a03a-ee6a-4465-9849-60eb1357b85c",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NAT300SMSHB-15",
			"ItemDescription": "Naturals 300 ml, shampoo Hair & Body smartcare",
			"ItemDivisable": false,
			"LineNumber": 6,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.94,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 60,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 30,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "NAT300SMSHB-15",
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.94,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 442.5,
			"AmountFC": 442.5,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Eco by green culture 300 ml shampoo hair & body in bottle sm",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "42b1f565-9d63-4cb6-bec1-ac46b1f864da",
			"InvoicedQuantity": 150,
			"InStock": 109,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "18469505-723b-4994-8ce4-bc8bf06b0dc6",
			"ItemBarcode": "4005162382106",
			"ItemBarcodeAdditional": "4005162382106",
			"ItemCode": "SMBS300-100620-1",
			"ItemDescription": "Eco by green culture 300 ml shampoo hair & body in bottle sm",
			"ItemDivisable": false,
			"LineNumber": 13,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.95,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 109,
			"Quantity": 150,
			"QuantityInPurchaseUnits": 150,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 150,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 83.76,
			"AmountFC": 83.76,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "297faeb4-5670-4d47-ac16-afd516ea775a",
			"InvoicedQuantity": 24,
			"InStock": 57,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "b2c328aa-88ca-4b39-a0be-585d11b9fab1",
			"ItemBarcode": "4005162417518",
			"ItemBarcodeAdditional": "4005162417518",
			"ItemCode": "PSHB300-100480-17",
			"ItemDescription": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
			"ItemDivisable": false,
			"LineNumber": 14,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.49,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 93,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.49,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.3,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 176.4,
			"AmountFC": 176.4,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Naturals 300 ml, Liquid Soap smartcare",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "ee60f360-74c1-4416-80b6-b9dbaf2ced11",
			"InvoicedQuantity": 60,
			"InStock": 60,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "18f40fb8-3665-401d-ac3c-549fc11b334a",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMLS300-101640",
			"ItemDescription": "Naturals 300 ml, Liquid Soap smartcare",
			"ItemDivisable": false,
			"LineNumber": 5,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.94,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 60,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 60,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 60,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.94,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 83.76,
			"AmountFC": 83.76,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Hand & Bodylotion 300ml pompdispenser",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "4c2f871c-63c5-40cd-894d-c955852f30d4",
			"InvoicedQuantity": 24,
			"InStock": 34,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "26732ebb-3d24-4114-9744-a6907780d7a1",
			"ItemBarcode": "4005162411370",
			"ItemBarcodeAdditional": "4005162411370",
			"ItemCode": "PSHB300-100200-16",
			"ItemDescription": "Hydro Basics Hand & Bodylotion 300ml pompdispenser",
			"ItemDivisable": false,
			"LineNumber": 9,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.49,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 58,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.49,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 88.5,
			"AmountFC": 88.5,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Hydro Basics Shampoo Hair & Body 300ml, smart care",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "3f2b305a-6cb3-4584-9662-cdd90caf355f",
			"InvoicedQuantity": 30,
			"InStock": 120,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "8bf50ee8-2f13-4cfe-8b92-0636333d2d6f",
			"ItemBarcode": "4005162355230",
			"ItemBarcodeAdditional": "4005162355230",
			"ItemCode": "HYB300SMSHB",
			"ItemDescription": "Hydro Basics Shampoo Hair & Body 300ml, smart care",
			"ItemDivisable": false,
			"LineNumber": 8,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.95,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 150,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 30,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 113.7,
			"AmountFC": 113.7,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "08-05-2023",
			"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
			"CreatorFullName": "Cris Lang",
			"Description": "Fairtrade 300ml Hair & Body Shampoo in bottle Smart Care",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "d54e5006-243d-4788-bdbf-ce8ff6583776",
			"InvoicedQuantity": 30,
			"InStock": 105,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "ef912069-d9cc-4867-a4f8-59b3581b1080",
			"ItemBarcode": "4005162361583",
			"ItemBarcodeAdditional": "4005162361583",
			"ItemCode": "FCO300SMSHB",
			"ItemDescription": "Fairtrade 300ml Hair & Body Shampoo in bottle Smart Care",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "19-05-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.79,
			"PurchaseOrderID": "b4ca3c5b-e2f8-4ab0-8836-292c83016dbd",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 105,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "09-05-2023",
			"ReceivedQuantity": 30,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.79,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 76,
		  "OrderDate": "25-04-2023",
		  "OrderNumber": 300,
		  "OrderStatus": "Complete",
		  "PurchaseOrderID": "a5db94be-7e34-4b1b-8174-a304519b255a",
		  "PurchaseOrderLineCount": 2,
		  "ReceiptDate": "25-04-2023",
		  "ReceiptStatus": "Complete",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'a5db94be-7e34-4b1b-8174-a304519b255a')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 76,
			"AmountFC": 76,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "25-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "houder mat chrom voor aqua sence,",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "66a968f7-1502-4764-a47b-88462d06f392",
			"InvoicedQuantity": 1,
			"InStock": 0.9,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "d9209479-b977-455a-8078-bde8d03a70dd",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "ADA mat chroom",
			"ItemDescription": "houder mat chrom voor aqua sence,",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 7.6,
			"PurchaseOrderID": "a5db94be-7e34-4b1b-8174-a304519b255a",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 0.9,
			"Quantity": 10,
			"QuantityInPurchaseUnits": 1,
			"Rebill": false,
			"ReceiptDate": "26-04-2023",
			"ReceivedQuantity": 1,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "ADA mat chroom",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 76,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 0,
			"AmountFC": 0,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "25-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Bracket Smart Care System holder black to stick fix pack of",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "54c19706-3685-4d5b-983e-e18d56fcd185",
			"InvoicedQuantity": 2,
			"InStock": 2,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "87287b55-e37a-4023-b349-74b429902bfe",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMHA014-100005-1",
			"ItemDescription": "Bracket Smart Care System holder black to stick fix pack of ",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 0,
			"PurchaseOrderID": "a5db94be-7e34-4b1b-8174-a304519b255a",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 2,
			"Quantity": 20,
			"QuantityInPurchaseUnits": 2,
			"Rebill": false,
			"ReceiptDate": "26-04-2023",
			"ReceivedQuantity": 2,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMHA014-100005-1",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 0,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 861.3,
		  "OrderDate": "21-04-2023",
		  "OrderNumber": 297,
		  "OrderStatus": "Complete",
		  "PurchaseOrderID": "8e8493a3-3cd3-4311-aa4e-15d546ed96cf",
		  "PurchaseOrderLineCount": 3,
		  "ReceiptDate": "21-04-2023",
		  "ReceiptStatus": "Complete",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'8e8493a3-3cd3-4311-aa4e-15d546ed96cf')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 110.1,
			"AmountFC": 110.1,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "21-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Body Peeling gel 250 ml in bottle Continental. Pe",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "b1e7b856-b8c2-4e64-886f-154cb38fdbfb",
			"InvoicedQuantity": 5,
			"InStock": 5,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "14961681-8a73-4819-911c-ae8ece1dafa9",
			"ItemBarcode": "4005162355094",
			"ItemBarcodeAdditional": null,
			"ItemCode": "CTKP250-10048012",
			"ItemDescription": "Pure Herbs Body Peeling gel 250 ml in bottle Continental. Pe",
			"ItemDivisable": false,
			"LineNumber": 3,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.67,
			"PurchaseOrderID": "8e8493a3-3cd3-4311-aa4e-15d546ed96cf",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 9,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 5,
			"Rebill": false,
			"ReceiptDate": "22-04-2023",
			"ReceivedQuantity": 5,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "CTKP250-10048012",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 22.02,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 352.8,
			"AmountFC": 352.8,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "21-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Natural Remedies 300 ml, Hair & Body Shampoo Nr. 16 smartcar",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "d76253a0-6dcf-4eb8-a73b-5d3ac971e387",
			"InvoicedQuantity": 3,
			"InStock": 2.7333333333333334,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "2c15e729-e5e0-40c1-8eca-51cd63dcf7a3",
			"ItemBarcode": "4005162443593",
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMBS300-101640",
			"ItemDescription": "Natural Remedies 300 ml, Hair & Body Shampoo Nr. 16 smartcar",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.92,
			"PurchaseOrderID": "8e8493a3-3cd3-4311-aa4e-15d546ed96cf",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 4.733333333333333,
			"Quantity": 90,
			"QuantityInPurchaseUnits": 3,
			"Rebill": false,
			"ReceiptDate": "22-04-2023",
			"ReceivedQuantity": 3,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMBS300-101640",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 117.6,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 398.4,
			"AmountFC": 398.4,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "21-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Naturals Pouche Resposible Living, filled",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "ea0e870d-a834-4cc2-a1b6-894df48f23a2",
			"InvoicedQuantity": 6,
			"InStock": 6.625,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "c8c46597-841e-4f25-b86b-32e93119a0ce",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NAT076KFBAG",
			"ItemDescription": "Naturals Pouche Resposible Living, filled",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 8.3,
			"PurchaseOrderID": "8e8493a3-3cd3-4311-aa4e-15d546ed96cf",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 6.625,
			"Quantity": 48,
			"QuantityInPurchaseUnits": 6,
			"Rebill": false,
			"ReceiptDate": "22-04-2023",
			"ReceivedQuantity": 6,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "NAT076KFBAG",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 66.4,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 525,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 450.36,
		  "OrderDate": "19-04-2023",
		  "OrderNumber": 294,
		  "OrderStatus": "Complete",
		  "PurchaseOrderID": "f153b390-0c3f-4dcd-88f2-06ea159bb682",
		  "PurchaseOrderLineCount": 2,
		  "ReceiptDate": "19-04-2023",
		  "ReceiptStatus": "Complete",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'f153b390-0c3f-4dcd-88f2-06ea159bb682')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 97.56,
			"AmountFC": 97.56,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Showergel; 250ml, Prijs perstuk 6 stuks per doos",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "47f24ee7-8f17-4473-a7ed-0ade5987e22c",
			"InvoicedQuantity": 6,
			"InStock": 15.333333333333334,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "e62147d5-857e-42b1-81af-ed2fed432d6f",
			"ItemBarcode": "4005162128032",
			"ItemBarcodeAdditional": null,
			"ItemCode": "CTDG250-100480-1",
			"ItemDescription": "Pure Herbs Showergel; 250ml, Prijs perstuk 6 stuks per doos",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "28-04-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 2.71,
			"PurchaseOrderID": "f153b390-0c3f-4dcd-88f2-06ea159bb682",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 21.333333333333332,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 6,
			"Rebill": false,
			"ReceiptDate": "20-04-2023",
			"ReceivedQuantity": 6,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "CTDG250-100480-1",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 16.259999999999998,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 352.8,
			"AmountFC": 352.8,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "19-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Natural Remedies 300 ml, Hand & Body wash Nr. 09 smartcare",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "adaf3c26-1531-4ff5-a068-c3257c30a0aa",
			"InvoicedQuantity": 3,
			"InStock": 6.966666666666667,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "c020934a-6469-4ddf-9792-ee6a6dfc46d9",
			"ItemBarcode": "4005162443593",
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMHBW300-101640",
			"ItemDescription": "Natural Remedies 300 ml, Hand & Body wash Nr. 09 smartcare",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "28-04-2023",
			"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
			"ModifierFullName": "WEA Exact Online",
			"Notes": null,
			"NetPrice": 3.92,
			"PurchaseOrderID": "f153b390-0c3f-4dcd-88f2-06ea159bb682",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 6.966666666666667,
			"Quantity": 90,
			"QuantityInPurchaseUnits": 3,
			"Rebill": false,
			"ReceiptDate": "20-04-2023",
			"ReceivedQuantity": 3,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMHBW300-101640",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 117.6,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  }
		]
	  },
	  {
		"purchase_order": {
		  "AmountDC": 2458.08,
		  "OrderDate": "13-04-2023",
		  "OrderNumber": 290,
		  "OrderStatus": "Complete",
		  "PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
		  "PurchaseOrderLineCount": 17,
		  "ReceiptDate": "13-04-2023",
		  "ReceiptStatus": "Complete",
		  "Supplier": "0b13868b-f03e-4792-bc5f-d2e258622c01",
		  "SupplierCode": "             40015",
		  "SupplierName": "ADA Cosmetics International Gmbh",
		  "PurchaseOrderLines": {
			"__deferred": {
			  "uri": "https://start.exactonline.nl/api/v1/3120592/purchaseorder/PurchaseOrders(guid'855a06b2-2f78-4383-a4fe-e239b511bcf3')/PurchaseOrderLines"
			}
		  }
		},
		"lines": [
		  {
			"AmountDC": 47.04,
			"AmountFC": 47.04,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Naturals Remedies Shampoo with conditioner Nr. 18 - smart ca",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "51cf89df-2bcb-4812-bae7-1240d9eee728",
			"InvoicedQuantity": 2.5,
			"InStock": 1.5833333333333333,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "8ac3f54a-127b-4a7b-8a1c-505e755cc2a4",
			"ItemBarcode": "4005162488228",
			"ItemBarcodeAdditional": null,
			"ItemCode": "NAR300SMSHC",
			"ItemDescription": "Naturals Remedies Shampoo with conditioner Nr. 18 - smart ca",
			"ItemDivisable": false,
			"LineNumber": 3,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.92,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 1.5833333333333333,
			"Quantity": 12,
			"QuantityInPurchaseUnits": 1,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 1,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "NAR300SMSHC",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 47.04,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 170.4,
			"AmountFC": 170.4,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Body Lotion 250 ml.  Prijs per stuk. 6 per doos",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "a7e323dc-b252-435c-8304-2507d32a9cbf",
			"InvoicedQuantity": 8,
			"InStock": 1.5,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "2fe26422-645e-4535-b7b9-d312366bb098",
			"ItemBarcode": "4005162128063",
			"ItemBarcodeAdditional": null,
			"ItemCode": "CTBL250-10048012",
			"ItemDescription": "Pure Herbs Body Lotion 250 ml.  Prijs per stuk. 6 per doos",
			"ItemDivisable": false,
			"LineNumber": 1,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.55,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 1.1666666666666667,
			"Quantity": 48,
			"QuantityInPurchaseUnits": 8,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 8,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "CTBL250-10048012",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 21.299999999999997,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 250,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Shampoo Hair & Body 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "754fb9de-cab0-41bb-80ea-28e1fb323dbd",
			"InvoicedQuantity": 27,
			"InStock": -16,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "bf482f77-2734-434e-80d0-d2548f4a747d",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBBS001-100480",
			"ItemDescription": "Pure Herbs Shampoo Hair & Body 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 6,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -16,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 27,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 273,
			"AmountFC": 273,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Naturals Remedies 300ml Hand & Body Lotion Nr. 14 Smartcare",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "0a88b058-8296-44e0-843c-414eed2adf52",
			"InvoicedQuantity": 2,
			"InStock": 1.9333333333333333,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "414bc574-b5b5-4757-8b47-da81aebe2a2e",
			"ItemBarcode": "4005162443609",
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMHB300-101640-2",
			"ItemDescription": "Naturals Remedies 300ml Hand & Body Lotion Nr. 14 Smartcare",
			"ItemDivisable": false,
			"LineNumber": 16,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 4.55,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 1.9333333333333333,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 2,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 2,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMHB300-101640-2",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 136.5,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 177,
			"AmountFC": 177,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Shampoo Hair & Body - Smart Care, 300ml",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "159e34e1-2667-488b-80fe-418375798b56",
			"InvoicedQuantity": 60,
			"InStock": -69,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "bf082510-ff48-474d-b1c2-63928f54dd9b",
			"ItemBarcode": "4005162337687",
			"ItemBarcodeAdditional": "4005162337687",
			"ItemCode": "PHE300SMSHB",
			"ItemDescription": "Pure Herbs Shampoo Hair & Body - Smart Care, 300ml",
			"ItemDivisable": false,
			"LineNumber": 10,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 2.95,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -69,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 60,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 60,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 53.55,
			"AmountFC": 53.55,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Hydro Basics Bodylotion 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "f6e92541-05fe-4b26-9879-440ae005f8c8",
			"InvoicedQuantity": 9,
			"InStock": 3,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "d82c6092-ae80-4edc-85be-c6866e7a0495",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBBL001-100200",
			"ItemDescription": "Hydro Basics Bodylotion 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 4,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 5.95,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 3,
			"Quantity": 9,
			"QuantityInPurchaseUnits": 9,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 9,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 5.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Liquid Cream Soap 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "2cba9889-4fbc-4c19-945f-624e9bb2c49e",
			"InvoicedQuantity": 27,
			"InStock": 1,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "e41a1481-b668-494c-a7c5-6a6bc398083f",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBLS001-100480",
			"ItemDescription": "Pure Herbs Liquid Cream Soap 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 9,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 1,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 27,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 88.2,
			"AmountFC": 88.2,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Naturals Liquid cream soap 300ml in pump dispenser, Smart Ca",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "2bbe8b8a-f33f-4d27-8bd3-74c80d358797",
			"InvoicedQuantity": 30,
			"InStock": 11,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "fc986ee1-a55f-469f-a123-a1c54624f963",
			"ItemBarcode": "4005162290913",
			"ItemBarcodeAdditional": "4005162290913",
			"ItemCode": "SMLS300-100540-10",
			"ItemDescription": "Naturals Liquid cream soap 300ml in pump dispenser, Smart Ca",
			"ItemDivisable": false,
			"LineNumber": 17,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 2.94,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 11,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 30,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.94,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Hydro Basics Liquid Cream Soap 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "fe2f6d8e-cf80-441f-ac35-75966ff81c79",
			"InvoicedQuantity": 27,
			"InStock": 0,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "2b37c5e7-463e-4a65-af99-36ac3f21a145",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBLS001100200",
			"ItemDescription": "Hydro Basics Liquid Cream Soap 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 8,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 0,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 27,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 282.24,
			"AmountFC": 282.24,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Naturals Remedies Hair & Body Shampoo No. 16 300 pomp dispen",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "07a75be2-95d4-4d0c-ac70-8356aec3a9af",
			"InvoicedQuantity": 6,
			"InStock": 5.333333333333333,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "7892f6c3-f6ac-497b-94e6-f0307f0f2617",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NAR300CNSHB-2",
			"ItemDescription": "Naturals Remedies Hair & Body Shampoo No. 16 300 pomp dispen",
			"ItemDivisable": false,
			"LineNumber": 2,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.92,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 5.333333333333333,
			"Quantity": 72,
			"QuantityInPurchaseUnits": 6,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 6,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "NAR300CNSHB-2",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 47.04,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 104.04,
			"AmountFC": 104.04,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "444a172e-bf37-46d7-915d-867b2a063db1",
			"InvoicedQuantity": 36,
			"InStock": -48,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "15bb518f-77fb-43f3-af94-4007e9ac7aef",
			"ItemBarcode": "4005162339247",
			"ItemBarcodeAdditional": "4005162339247",
			"ItemCode": "PSBS300-100480-18",
			"ItemDescription": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
			"ItemDivisable": false,
			"LineNumber": 11,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 2.89,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -48,
			"Quantity": 36,
			"QuantityInPurchaseUnits": 36,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 36,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.89,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.6,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 83.76,
			"AmountFC": 83.76,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "2d9512d0-0505-40f4-beff-a5c47e3cc53a",
			"InvoicedQuantity": 24,
			"InStock": -44,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "b2c328aa-88ca-4b39-a0be-585d11b9fab1",
			"ItemBarcode": "4005162417518",
			"ItemBarcodeAdditional": "4005162417518",
			"ItemCode": "PSHB300-100480-17",
			"ItemDescription": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
			"ItemDivisable": false,
			"LineNumber": 12,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.49,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -44,
			"Quantity": 24,
			"QuantityInPurchaseUnits": 24,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 24,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.49,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0.3,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 128.25,
			"AmountFC": 128.25,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Hydro Basics Shampoo Hair & Body 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "9cbb938f-fbe4-452f-afb6-b3771fb5f0b2",
			"InvoicedQuantity": 27,
			"InStock": -34,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "117fd0ba-bbdc-4ff9-948e-ac4543dc9ed5",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBBS001-100200",
			"ItemDescription": "Hydro Basics Shampoo Hair & Body 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 5,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 4.75,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -34,
			"Quantity": 27,
			"QuantityInPurchaseUnits": 27,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 27,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 4.75,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 109.2,
			"AmountFC": 109.2,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Hydro Basics, Body Lotion Smart Care",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "e47e2a4b-23b5-4f5e-957d-c8d95433ea53",
			"InvoicedQuantity": 30,
			"InStock": 30,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "0397a0a0-1046-45c8-92f1-12072b2071ab",
			"ItemBarcode": "4005162408226",
			"ItemBarcodeAdditional": "4005162408226",
			"ItemCode": "SMHB300-100200-HYB300SMH",
			"ItemDescription": "Hydro Basics, Body Lotion Smart Care",
			"ItemDivisable": false,
			"LineNumber": 14,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.64,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 30,
			"Quantity": 30,
			"QuantityInPurchaseUnits": 30,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 30,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 3.64,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 176.4,
			"AmountFC": 176.4,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Smart Care 300 ml. naturals Hair & shower gel",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "45702e48-797a-4861-a3a1-cce519d60937",
			"InvoicedQuantity": 60,
			"InStock": -36,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "39a07e08-ec1e-4100-82a6-fcc9d1c5bdfd",
			"ItemBarcode": "4005162301367",
			"ItemBarcodeAdditional": "4005162301367",
			"ItemCode": "SMBS300 100540 NAT300SMS",
			"ItemDescription": "Smart Care 300 ml. naturals Hair & shower gel",
			"ItemDivisable": false,
			"LineNumber": 13,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 2.94,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -41,
			"Quantity": 60,
			"QuantityInPurchaseUnits": 60,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 60,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMBS300 100540 NAT300SMS",
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 2.94,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 0,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 326.7,
			"AmountFC": 326.7,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Smart Care 300 ml. naturals bodylotion olive",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "9a92a568-2e41-4391-b0a0-f6a16e2a313f",
			"InvoicedQuantity": 3,
			"InStock": 2.466666666666667,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "fca7646c-c15b-44d8-ac70-7dc46270b083",
			"ItemBarcode": "4005162409070",
			"ItemBarcodeAdditional": null,
			"ItemCode": "SMHB300100540NAT300SMHBL",
			"ItemDescription": "Smart Care 300 ml. naturals bodylotion olive",
			"ItemDivisable": false,
			"LineNumber": 15,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 3.63,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": 2.466666666666667,
			"Quantity": 90,
			"QuantityInPurchaseUnits": 3,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 3,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": "SMHB300100540NAT300SMHBL",
			"SupplierItemCopyRemarks": 0,
			"Unit": "doos",
			"UnitDescription": "Karton",
			"UnitPrice": 108.89999999999999,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 300,
			"NetWeightUnit": 0
		  },
		  {
			"AmountDC": 53.55,
			"AmountFC": 53.55,
			"CostCenter": null,
			"CostCenterDescription": null,
			"CostUnit": null,
			"CostUnitDescription": null,
			"Created": "13-04-2023",
			"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
			"CreatorFullName": "Thomas Lang",
			"Description": "Pure Herbs Hand & Body Lotion 1 Ltr.",
			"Discount": 0,
			"Division": 3120592,
			"Expense": null,
			"ExpenseDescription": null,
			"ID": "2383c6d6-c121-455f-878c-fd9fa82ac9db",
			"InvoicedQuantity": 9,
			"InStock": -7,
			"IsBatchNumberItem": 0,
			"IsSerialNumberItem": 0,
			"Item": "a87cdd88-bb9e-4c68-81d5-93788f9ec400",
			"ItemBarcode": null,
			"ItemBarcodeAdditional": null,
			"ItemCode": "NBHB001-100480",
			"ItemDescription": "Pure Herbs Hand & Body Lotion 1 Ltr.",
			"ItemDivisable": false,
			"LineNumber": 7,
			"Modified": "04-05-2023",
			"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
			"ModifierFullName": "Lanza Accounting",
			"Notes": null,
			"NetPrice": 5.95,
			"PurchaseOrderID": "855a06b2-2f78-4383-a4fe-e239b511bcf3",
			"Project": null,
			"ProjectCode": null,
			"ProjectDescription": null,
			"ProjectedStock": -7,
			"Quantity": 9,
			"QuantityInPurchaseUnits": 9,
			"Rebill": false,
			"ReceiptDate": "14-04-2023",
			"ReceivedQuantity": 9,
			"SalesOrder": null,
			"SalesOrderLine": null,
			"SalesOrderLineNumber": null,
			"SalesOrderNumber": null,
			"SupplierItemCode": null,
			"SupplierItemCopyRemarks": 0,
			"Unit": "pc",
			"UnitDescription": "Piece",
			"UnitPrice": 5.95,
			"VATAmount": 0,
			"VATCode": "10 ",
			"VATDescription": "Verwerving binnen de EU",
			"VATPercentage": 0.21,
			"CustomField": null,
			"NetWeight": 1,
			"NetWeightUnit": 0
		  }
		]
	  }
	],
	"success": true
};

const mockCustomer =  {
	"customer":{
	   "id":"420bc17c-e104-49e0-9c85-af927a6e589a",
	   "code":3333,
	   "name":"vissershuis"
	},
	"top_products":[
	   {
		  "qty":35,
		  "code":"ML-52670",
		  "item_id":"1badc63d-970b-436a-86e1-57836e0ed3a2",
		  "description":"Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4"
	   },
	   {
		  "qty":22,
		  "code":"MSU-87490",
		  "item_id":"efc20f52-9391-49d1-8181-f77763ac3d59",
		  "description":"Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300"
	   },
	   {
		  "qty":9,
		  "code":"LA300-6",
		  "item_id":"dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
		  "description":"Poetsrollen Midi, recycling,  verpakt per 6 stuks."
	   },
	   {
		  "qty":7,
		  "code":"911-60-01",
		  "item_id":"ae1a1ea0-fa2c-4d80-9725-8c4fd034cff9",
		  "description":"Q-Lights, Refills \u00c3\u0192\u00c2\u00b8 52x64 mm clear 60 stuks per doos."
	   },
	   {
		  "qty":6,
		  "code":"911-60-90",
		  "item_id":"11d59473-b19b-486f-a396-9299ef2844dc",
		  "description":"Q-Light, Refills, 52x64 mm oranje, 60 Stuks per doos."
	   },
	   {
		  "qty":3,
		  "code":"LA100-6",
		  "item_id":"0bdff01b-3d50-40c0-a5ee-1897e7824c66",
		  "description":"Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks"
	   },
	   {
		  "qty":1,
		  "code":"171",
		  "item_id":"c7eaa186-b618-4821-aa64-21bf34bf2c18",
		  "description":"Lanza Luxe Verfrissingsdoeken biodegradable 80 gr. doos a 1."
	   },
	   {
		  "qty":0,
		  "code":"LA380-6",
		  "item_id":"d1edd5d9-e40e-4900-bb67-69124c77fc6d",
		  "description":"Maxi Jumbo Eco rol 380 , recycled wit, verpakt per 6 stuks"
	   },
	   {
		  "qty":1,
		  "code":"I-0318",
		  "item_id":"c482d78f-abf8-476e-97b2-9d9b857073d2",
		  "description":"Tissue Servetten infibra 25 x 25 1\/4 vouws, wit doos a 3000"
	   }
	]
 }


 const mockSearchProducts = {
    "products": [
        {
            "item_id": "c7eaa186-b618-4821-aa64-21bf34bf2c18",
            "code": "171",
            "description": "Lanza Luxe Verfrissingsdoeken biodegradable 80 gr. doos a 1.",
            "qty": 96,
            "unit": "pc"
        },
        {
            "item_id": "11cf586f-3ad9-40d5-9f1f-371a252f9187",
            "code": "FLX300SMLQS-10",
            "description": "Floraluxe 300 ml liquid soap Smartcare",
            "qty": 74,
            "unit": "pc"
        },
        {
            "item_id": "e756cb80-c1ba-4e2a-b0fd-43ff7a8228e8",
            "code": "M-63235",
            "description": "Tissue servetten DELUXE, 4 laags,Grazia Kleur vanille, doos ",
            "qty": 0,
            "unit": "pc"
        },
        {
            "item_id": "86d0c7ca-f58e-4151-ae4e-642aa5e7dfab",
            "code": "fair-trade-giftset",
            "description": "Fair Trade gift set, luxe bewaardoos.",
            "qty": 25,
            "unit": "pc"
        },
        {
            "item_id": "df3bfe7f-ba59-4290-8afe-67e07e05b443",
            "code": "M-49407",
            "description": "Tissueservetten DELUXE, 4 Laags, Creme, doos a 1.200 stuks.",
            "qty": 0,
            "unit": "pc"
        },
        {
            "item_id": "b12ab40e-8751-4b7f-bd61-b3b64eec235b",
            "code": "170",
            "description": "Lanza Luxe Verfrissingsdoeken 80 gr. doos a 1.000 Stuks",
            "qty": 93,
            "unit": "pc"
        },
        {
            "item_id": "31b479df-3bb7-43b7-ab8a-d00de8ad7244",
            "code": "SMBS300-100660",
            "description": "Floraluxe Hair & Body Shampoo 300ml.  Smartcare",
            "qty": -5,
            "unit": "pc"
        },
        {
            "item_id": "46bc2d86-d813-4ef2-a480-d257c51d8718",
            "code": "M-63236",
            "description": "Tissue servetten DELUXE, 4 laags,Grazia Kleur terrakotta, do",
            "qty": 0,
            "unit": "pc"
        },
        {
            "item_id": "e3b2754a-64b5-4e7b-9099-dab0cb0e4dda",
            "code": "M-49467",
            "description": "Servetten,Serie DELUXE motief Klaproos, 40x40,1\/4 vouw, 4 la",
            "qty": 0,
            "unit": "pc"
        },
        {
            "item_id": "9e4de7f4-01fd-434b-b4c2-dcb86bd1da78",
            "code": "MS-70307",
            "description": "Tissue Deluxe Servetten 40x40cm, Sidney brown doos a 600 stu",
            "qty": 0,
            "unit": "pc"
        }
    ]
};

 const mockSupplier =  {
	"customer":{
	   "id":"420bc17c-e104-49e0-9c85-af927a6e589a",
	   "code":3333,
	   "name":"Autobanden"
	},
	"top_products":[
	   {
		  "qty":35,
		  "code":"ML-52670",
		  "item_id":"1badc63d-970b-436a-86e1-57836e0ed3a2",
		  "description":"Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4"
	   },
	   {
		  "qty":22,
		  "code":"MSU-87490",
		  "item_id":"efc20f52-9391-49d1-8181-f77763ac3d59",
		  "description":"Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300"
	   },
	   {
		  "qty":9,
		  "code":"LA300-6",
		  "item_id":"dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
		  "description":"Poetsrollen Midi, recycling,  verpakt per 6 stuks."
	   },
	   {
		  "qty":7,
		  "code":"911-60-01",
		  "item_id":"ae1a1ea0-fa2c-4d80-9725-8c4fd034cff9",
		  "description":"Q-Lights, Refills \u00c3\u0192\u00c2\u00b8 52x64 mm clear 60 stuks per doos."
	   },
	   {
		  "qty":6,
		  "code":"911-60-90",
		  "item_id":"11d59473-b19b-486f-a396-9299ef2844dc",
		  "description":"Q-Light, Refills, 52x64 mm oranje, 60 Stuks per doos."
	   },
	   {
		  "qty":3,
		  "code":"LA100-6",
		  "item_id":"0bdff01b-3d50-40c0-a5ee-1897e7824c66",
		  "description":"Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks"
	   },
	   {
		  "qty":1,
		  "code":"171",
		  "item_id":"c7eaa186-b618-4821-aa64-21bf34bf2c18",
		  "description":"Lanza Luxe Verfrissingsdoeken biodegradable 80 gr. doos a 1."
	   },
	   {
		  "qty":0,
		  "code":"LA380-6",
		  "item_id":"d1edd5d9-e40e-4900-bb67-69124c77fc6d",
		  "description":"Maxi Jumbo Eco rol 380 , recycled wit, verpakt per 6 stuks"
	   },
	   {
		  "qty":1,
		  "code":"I-0318",
		  "item_id":"c482d78f-abf8-476e-97b2-9d9b857073d2",
		  "description":"Tissue Servetten infibra 25 x 25 1\/4 vouws, wit doos a 3000"
	   }
	]
 }


const mockCustomerOrders = {
    "message": "Customer sales order data loaded successfully.",
    "data": [
        {
            "sales_order": {
                "AmountDC": 528.87,
                "AmountDiscount": 0,
                "AmountDiscountExclVat": 0,
                "AmountFC": 528.87,
                "AmountFCExclVat": 437.07,
                "ApprovalStatus": "Automatically",
                "ApprovalStatusDescription": "Automatically",
                "Approved": "19-07-2023",
                "Approver": null,
                "ApproverFullName": null,
                "Created": "19-07-2023",
                "Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "CreatorFullName": "Cris Lang",
                "Currency": "EUR",
                "DeliverTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "DeliverToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "DeliverToContactPersonFullName": "Dhr. A. Taal",
                "DeliverToName": "Rest. `t Vissershuis",
                "DeliveryDate": "20-07-2023",
                "DeliveryStatus": "Complete",
                "DeliveryStatusDescription": "Volledig",
                "DeliveryAddress": "fdab2ddc-c964-4c5a-8c70-e15135a82ddd",
                "Description": null,
                "Discount": 0,
                "Division": 3120592,
                "Document": null,
                "DocumentNumber": null,
                "DocumentSubject": null,
                "IncotermAddress": null,
                "IncotermCode": null,
                "IncotermVersion": null,
                "InvoiceStatus": "Complete",
                "InvoiceStatusDescription": "Volledig",
                "InvoiceTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "InvoiceToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "InvoiceToContactPersonFullName": "Dhr. A. Taal",
                "InvoiceToName": "Rest. `t Vissershuis",
                "Modified": "20-07-2023",
                "Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
                "ModifierFullName": "Lanza Accounting",
                "OrderDate": "19-07-2023",
                "OrderedBy": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "OrderedByContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "OrderedByContactPersonFullName": "Dhr. A. Taal",
                "OrderedByName": "Rest. `t Vissershuis",
                "OrderID": "dec2e763-9a01-47b5-8734-889afceebc9b",
                "OrderNumber": 8807,
                "PaymentCondition": "11",
                "PaymentConditionDescription": "7 Dagen Incasso",
                "PaymentReference": null,
                "Remarks": null,
                "SalesChannel": null,
                "SalesChannelCode": null,
                "SalesChannelDescription": null,
                "SalesOrderOrderChargeLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'dec2e763-9a01-47b5-8734-889afceebc9b')\/SalesOrderOrderChargeLines"
                    }
                },
                "Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "SalespersonFullName": "Cris Lang",
                "SelectionCode": null,
                "SelectionCodeCode": null,
                "SelectionCodeDescription": null,
                "ShippingMethod": null,
                "ShippingMethodDescription": null,
                "Status": "Complete",
                "StatusDescription": "Volledig",
                "WarehouseID": "0b05b996-bdc0-446f-aabd-3a5e0fb772e3",
                "WarehouseCode": "1",
                "WarehouseDescription": "Magazijn",
                "YourRef": null,
                "SalesOrderLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'dec2e763-9a01-47b5-8734-889afceebc9b')\/SalesOrderLines"
                    }
                },
                "TotalCost": 205.13,
                "margin": "46.93%"
            },
            "lines": [
                {
                    "id": "01363d26-aa02-49e8-be89-3ecf3741d55a",
                    "item_id": "1badc63d-970b-436a-86e1-57836e0ed3a2",
                    "item_code": "ML-52670",
                    "item_description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4 ",
                    "cost_price": 34.92,
                    "cost_price_total": 69.84,
                    "description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4",
                    "quantity": 2,
                    "line_total": 138,
                    "order_status": "Complete"
                },
                {
                    "id": "4fa9d98d-4a85-4501-8251-ac726979bd52",
                    "item_id": "0bdff01b-3d50-40c0-a5ee-1897e7824c66",
                    "item_code": "LA100-6",
                    "item_description": "Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks",
                    "cost_price": 14.49,
                    "cost_price_total": 14.49,
                    "description": "Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks",
                    "quantity": 1,
                    "line_total": 76.75,
                    "order_status": "Complete"
                },
                {
                    "id": "49a63ec0-af87-40ce-a021-ca5fb194cdab",
                    "item_id": "ae1a1ea0-fa2c-4d80-9725-8c4fd034cff9",
                    "item_code": "911-60-01",
                    "item_description": "Q-Lights, Refills \u00c3\u0192\u00c2\u00b8 52x64 mm clear 60 stuks per doos.",
                    "cost_price": 22,
                    "cost_price_total": 22,
                    "description": "Q-Lights, Refills \u00c3\u0192\u00c2\u00b8 52x64 mm clear 60 stuks per doos.",
                    "quantity": 1,
                    "line_total": 32.5,
                    "order_status": "Complete"
                },
                {
                    "id": "5d351a7c-3dd6-4af7-b38d-f649aec3badf",
                    "item_id": "efc20f52-9391-49d1-8181-f77763ac3d59",
                    "item_code": "MSU-87490",
                    "item_description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "cost_price": 38.15,
                    "cost_price_total": 76.3,
                    "description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "quantity": 2,
                    "line_total": 157.32,
                    "order_status": "Complete"
                },
                {
                    "id": "4e4d8fc7-88af-452b-96c8-fddacdd68877",
                    "item_id": "11d59473-b19b-486f-a396-9299ef2844dc",
                    "item_code": "911-60-90",
                    "item_description": "Q-Light, Refills, 52x64 mm oranje, 60 Stuks per doos.",
                    "cost_price": 22.5,
                    "cost_price_total": 22.5,
                    "description": "Q-Light, Refills, 52x64 mm oranje, 60 Stuks per doos.",
                    "quantity": 1,
                    "line_total": 32.5,
                    "order_status": "Complete"
                }
            ]
        },
        {
            "sales_order": {
                "AmountDC": 410.29,
                "AmountDiscount": 0,
                "AmountDiscountExclVat": 0,
                "AmountFC": 410.29,
                "AmountFCExclVat": 339.08,
                "ApprovalStatus": "Automatically",
                "ApprovalStatusDescription": "Automatically",
                "Approved": "14-07-2023",
                "Approver": null,
                "ApproverFullName": null,
                "Created": "14-07-2023",
                "Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "CreatorFullName": "Cris Lang",
                "Currency": "EUR",
                "DeliverTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "DeliverToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "DeliverToContactPersonFullName": "Dhr. A. Taal",
                "DeliverToName": "Rest. `t Vissershuis",
                "DeliveryDate": "14-07-2023",
                "DeliveryStatus": "Complete",
                "DeliveryStatusDescription": "Volledig",
                "DeliveryAddress": "fdab2ddc-c964-4c5a-8c70-e15135a82ddd",
                "Description": null,
                "Discount": 0,
                "Division": 3120592,
                "Document": null,
                "DocumentNumber": null,
                "DocumentSubject": null,
                "IncotermAddress": null,
                "IncotermCode": null,
                "IncotermVersion": null,
                "InvoiceStatus": "Complete",
                "InvoiceStatusDescription": "Volledig",
                "InvoiceTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "InvoiceToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "InvoiceToContactPersonFullName": "Dhr. A. Taal",
                "InvoiceToName": "Rest. `t Vissershuis",
                "Modified": "14-07-2023",
                "Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
                "ModifierFullName": "Lanza Accounting",
                "OrderDate": "14-07-2023",
                "OrderedBy": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "OrderedByContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "OrderedByContactPersonFullName": "Dhr. A. Taal",
                "OrderedByName": "Rest. `t Vissershuis",
                "OrderID": "8eb24991-e83f-4e31-809f-b28875d80c54",
                "OrderNumber": 8729,
                "PaymentCondition": "11",
                "PaymentConditionDescription": "7 Dagen Incasso",
                "PaymentReference": null,
                "Remarks": null,
                "SalesChannel": null,
                "SalesChannelCode": null,
                "SalesChannelDescription": null,
                "SalesOrderOrderChargeLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'8eb24991-e83f-4e31-809f-b28875d80c54')\/SalesOrderOrderChargeLines"
                    }
                },
                "Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "SalespersonFullName": "Cris Lang",
                "SelectionCode": null,
                "SelectionCodeCode": null,
                "SelectionCodeDescription": null,
                "ShippingMethod": null,
                "ShippingMethodDescription": null,
                "Status": "Complete",
                "StatusDescription": "Volledig",
                "WarehouseID": "0b05b996-bdc0-446f-aabd-3a5e0fb772e3",
                "WarehouseCode": "1",
                "WarehouseDescription": "Magazijn",
                "YourRef": null,
                "SalesOrderLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'8eb24991-e83f-4e31-809f-b28875d80c54')\/SalesOrderLines"
                    }
                },
                "TotalCost": 165.35,
                "margin": "48.76%"
            },
            "lines": [
                {
                    "id": "e507f68e-28a9-4287-b785-0b6b65f01ead",
                    "item_id": "dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
                    "item_code": "LA300-6",
                    "item_description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "cost_price": 15.98,
                    "cost_price_total": 15.98,
                    "description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "quantity": 1,
                    "line_total": 34.1,
                    "order_status": "Complete"
                },
                {
                    "id": "a0b18e81-db5d-4616-8a16-3db519a3e8d7",
                    "item_id": "1badc63d-970b-436a-86e1-57836e0ed3a2",
                    "item_code": "ML-52670",
                    "item_description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4 ",
                    "cost_price": 34.92,
                    "cost_price_total": 34.92,
                    "description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4",
                    "quantity": 1,
                    "line_total": 69,
                    "order_status": "Complete"
                },
                {
                    "id": "be374cab-e1d5-4650-a2f7-bdaae56ff56c",
                    "item_id": "efc20f52-9391-49d1-8181-f77763ac3d59",
                    "item_code": "MSU-87490",
                    "item_description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "cost_price": 38.15,
                    "cost_price_total": 114.45,
                    "description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "quantity": 3,
                    "line_total": 235.98,
                    "order_status": "Complete"
                }
            ]
        },
        {
            "sales_order": {
                "AmountDC": 437.92,
                "AmountDiscount": 0,
                "AmountDiscountExclVat": 0,
                "AmountFC": 437.92,
                "AmountFCExclVat": 361.92,
                "ApprovalStatus": "Automatically",
                "ApprovalStatusDescription": "Automatically",
                "Approved": "07-07-2023",
                "Approver": null,
                "ApproverFullName": null,
                "Created": "07-07-2023",
                "Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "CreatorFullName": "Cris Lang",
                "Currency": "EUR",
                "DeliverTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "DeliverToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "DeliverToContactPersonFullName": "Dhr. A. Taal",
                "DeliverToName": "Rest. `t Vissershuis",
                "DeliveryDate": "07-07-2023",
                "DeliveryStatus": "Complete",
                "DeliveryStatusDescription": "Volledig",
                "DeliveryAddress": "fdab2ddc-c964-4c5a-8c70-e15135a82ddd",
                "Description": "mobile order - 2 ds stockholm mee terug nemen",
                "Discount": 0,
                "Division": 3120592,
                "Document": null,
                "DocumentNumber": null,
                "DocumentSubject": null,
                "IncotermAddress": null,
                "IncotermCode": null,
                "IncotermVersion": null,
                "InvoiceStatus": "Complete",
                "InvoiceStatusDescription": "Volledig",
                "InvoiceTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "InvoiceToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "InvoiceToContactPersonFullName": "Dhr. A. Taal",
                "InvoiceToName": "Rest. `t Vissershuis",
                "Modified": "07-07-2023",
                "Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
                "ModifierFullName": "Lanza Accounting",
                "OrderDate": "07-07-2023",
                "OrderedBy": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "OrderedByContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "OrderedByContactPersonFullName": "Dhr. A. Taal",
                "OrderedByName": "Rest. `t Vissershuis",
                "OrderID": "8facb600-87e7-4c99-bc7e-7280a1d435c3",
                "OrderNumber": 8612,
                "PaymentCondition": "11",
                "PaymentConditionDescription": "7 Dagen Incasso",
                "PaymentReference": null,
                "Remarks": null,
                "SalesChannel": null,
                "SalesChannelCode": null,
                "SalesChannelDescription": null,
                "SalesOrderOrderChargeLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'8facb600-87e7-4c99-bc7e-7280a1d435c3')\/SalesOrderOrderChargeLines"
                    }
                },
                "Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "SalespersonFullName": "Cris Lang",
                "SelectionCode": null,
                "SelectionCodeCode": null,
                "SelectionCodeDescription": null,
                "ShippingMethod": null,
                "ShippingMethodDescription": null,
                "Status": "Complete",
                "StatusDescription": "Volledig",
                "WarehouseID": "0b7cded5-f48a-4212-ac03-145ca89f4624",
                "WarehouseCode": "HEM",
                "WarehouseDescription": "Hem 26a",
                "YourRef": null,
                "SalesOrderLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'8facb600-87e7-4c99-bc7e-7280a1d435c3')\/SalesOrderLines"
                    }
                },
                "TotalCost": 165.56,
                "margin": "45.74%"
            },
            "lines": [
                {
                    "id": "3301cbbc-0d66-428d-9993-168eefb17558",
                    "item_id": "1badc63d-970b-436a-86e1-57836e0ed3a2",
                    "item_code": "ML-52670",
                    "item_description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4 ",
                    "cost_price": 34.92,
                    "cost_price_total": 69.84,
                    "description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4",
                    "quantity": 2,
                    "line_total": 138,
                    "order_status": "Complete"
                },
                {
                    "id": "a74a0a00-40ec-4ef4-9046-3e901c3b8f09",
                    "item_id": "011248f2-de54-4a99-9502-3f27db487fed",
                    "item_code": "M-88281",
                    "item_description": "Servetten Linnclass 40x40 1\/4, stockholm stone, doos a 300 S",
                    "cost_price": 47.43,
                    "cost_price_total": -94.86,
                    "description": "Servetten Linnclass 40x40 1\/4, stockholm stone, doos a 300 S",
                    "quantity": -2,
                    "line_total": -157.32,
                    "order_status": "Complete"
                },
                {
                    "id": "35c32562-86b2-41d3-bd7f-7f4e17c51ded",
                    "item_id": "efc20f52-9391-49d1-8181-f77763ac3d59",
                    "item_code": "MSU-87490",
                    "item_description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "cost_price": 38.15,
                    "cost_price_total": 152.6,
                    "description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "quantity": 4,
                    "line_total": 314.64,
                    "order_status": "Complete"
                },
                {
                    "id": "d05e5bd3-d258-450d-9300-b96becfa8a67",
                    "item_id": "dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
                    "item_code": "LA300-6",
                    "item_description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "cost_price": 15.98,
                    "cost_price_total": 15.98,
                    "description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "quantity": 1,
                    "line_total": 34.1,
                    "order_status": "Complete"
                },
                {
                    "id": "06a1b1a7-d258-4937-9f98-f6af0273406d",
                    "item_id": "ae1a1ea0-fa2c-4d80-9725-8c4fd034cff9",
                    "item_code": "911-60-01",
                    "item_description": "Q-Lights, Refills \u00c3\u0192\u00c2\u00b8 52x64 mm clear 60 stuks per doos.",
                    "cost_price": 22,
                    "cost_price_total": 22,
                    "description": "Q-Lights, Refills \u00c3\u0192\u00c2\u00b8 52x64 mm clear 60 stuks per doos.",
                    "quantity": 1,
                    "line_total": 32.5,
                    "order_status": "Complete"
                }
            ]
        },
        {
            "sales_order": {
                "AmountDC": 890.06,
                "AmountDiscount": 0,
                "AmountDiscountExclVat": 0,
                "AmountFC": 890.06,
                "AmountFCExclVat": 735.59,
                "ApprovalStatus": "Automatically",
                "ApprovalStatusDescription": "Automatically",
                "Approved": "30-06-2023",
                "Approver": null,
                "ApproverFullName": null,
                "Created": "30-06-2023",
                "Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "CreatorFullName": "Cris Lang",
                "Currency": "EUR",
                "DeliverTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "DeliverToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "DeliverToContactPersonFullName": "Dhr. A. Taal",
                "DeliverToName": "Rest. `t Vissershuis",
                "DeliveryDate": "30-06-2023",
                "DeliveryStatus": "Complete",
                "DeliveryStatusDescription": "Volledig",
                "DeliveryAddress": "fdab2ddc-c964-4c5a-8c70-e15135a82ddd",
                "Description": null,
                "Discount": 0,
                "Division": 3120592,
                "Document": null,
                "DocumentNumber": null,
                "DocumentSubject": null,
                "IncotermAddress": null,
                "IncotermCode": null,
                "IncotermVersion": null,
                "InvoiceStatus": "Complete",
                "InvoiceStatusDescription": "Volledig",
                "InvoiceTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "InvoiceToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "InvoiceToContactPersonFullName": "Dhr. A. Taal",
                "InvoiceToName": "Rest. `t Vissershuis",
                "Modified": "30-06-2023",
                "Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
                "ModifierFullName": "Lanza Accounting",
                "OrderDate": "30-06-2023",
                "OrderedBy": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "OrderedByContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "OrderedByContactPersonFullName": "Dhr. A. Taal",
                "OrderedByName": "Rest. `t Vissershuis",
                "OrderID": "21c59b36-cdd8-4202-bff2-feec0f8de1aa",
                "OrderNumber": 8491,
                "PaymentCondition": "11",
                "PaymentConditionDescription": "7 Dagen Incasso",
                "PaymentReference": null,
                "Remarks": null,
                "SalesChannel": null,
                "SalesChannelCode": null,
                "SalesChannelDescription": null,
                "SalesOrderOrderChargeLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'21c59b36-cdd8-4202-bff2-feec0f8de1aa')\/SalesOrderOrderChargeLines"
                    }
                },
                "Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
                "SalespersonFullName": "Cris Lang",
                "SelectionCode": null,
                "SelectionCodeCode": null,
                "SelectionCodeDescription": null,
                "ShippingMethod": null,
                "ShippingMethodDescription": null,
                "Status": "Complete",
                "StatusDescription": "Volledig",
                "WarehouseID": "0b05b996-bdc0-446f-aabd-3a5e0fb772e3",
                "WarehouseCode": "1",
                "WarehouseDescription": "Magazijn",
                "YourRef": null,
                "SalesOrderLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'21c59b36-cdd8-4202-bff2-feec0f8de1aa')\/SalesOrderLines"
                    }
                },
                "TotalCost": 375.85,
                "margin": "51.1%"
            },
            "lines": [
                {
                    "id": "2a5eeacd-3c6a-46c3-8fd0-18a291e1926b",
                    "item_id": "0bdff01b-3d50-40c0-a5ee-1897e7824c66",
                    "item_code": "LA100-6",
                    "item_description": "Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks",
                    "cost_price": 14.49,
                    "cost_price_total": 14.49,
                    "description": "Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks",
                    "quantity": 1,
                    "line_total": 76.75,
                    "order_status": "Complete"
                },
                {
                    "id": "646132b5-9cff-4e3f-8304-a66c0acca6ce",
                    "item_id": "dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
                    "item_code": "LA300-6",
                    "item_description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "cost_price": 15.98,
                    "cost_price_total": 31.96,
                    "description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "quantity": 2,
                    "line_total": 68.2,
                    "order_status": "Complete"
                },
                {
                    "id": "22a07ae2-a9e0-4c31-84a0-a8a6134373f7",
                    "item_id": "011248f2-de54-4a99-9502-3f27db487fed",
                    "item_code": "M-88281",
                    "item_description": "Servetten Linnclass 40x40 1\/4, stockholm stone, doos a 300 S",
                    "cost_price": 47.43,
                    "cost_price_total": 189.72,
                    "description": "Servetten Linnclass 40x40 1\/4, stockholm stone, doos a 300 S",
                    "quantity": 4,
                    "line_total": 314.64,
                    "order_status": "Complete"
                },
                {
                    "id": "f2dedbda-aed9-493a-841f-c332ac185815",
                    "item_id": "1badc63d-970b-436a-86e1-57836e0ed3a2",
                    "item_code": "ML-52670",
                    "item_description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4 ",
                    "cost_price": 34.92,
                    "cost_price_total": 139.68,
                    "description": "Airlaid Tafelloper, 40cmx24 mtr, Kleur Royalblauw, doos a 4",
                    "quantity": 4,
                    "line_total": 276,
                    "order_status": "Complete"
                }
            ]
        },
        {
            "sales_order": {
                "AmountDC": 377.92,
                "AmountDiscount": 0,
                "AmountDiscountExclVat": 0,
                "AmountFC": 377.92,
                "AmountFCExclVat": 312.33,
                "ApprovalStatus": "Automatically",
                "ApprovalStatusDescription": "Automatically",
                "Approved": "22-06-2023",
                "Approver": null,
                "ApproverFullName": null,
                "Created": "22-06-2023",
                "Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
                "CreatorFullName": "Thomas Lang",
                "Currency": "EUR",
                "DeliverTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "DeliverToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "DeliverToContactPersonFullName": "Dhr. A. Taal",
                "DeliverToName": "Rest. `t Vissershuis",
                "DeliveryDate": "22-06-2023",
                "DeliveryStatus": "Complete",
                "DeliveryStatusDescription": "Volledig",
                "DeliveryAddress": "fdab2ddc-c964-4c5a-8c70-e15135a82ddd",
                "Description": "exacter.online order",
                "Discount": 0,
                "Division": 3120592,
                "Document": null,
                "DocumentNumber": null,
                "DocumentSubject": null,
                "IncotermAddress": null,
                "IncotermCode": null,
                "IncotermVersion": null,
                "InvoiceStatus": "Complete",
                "InvoiceStatusDescription": "Volledig",
                "InvoiceTo": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "InvoiceToContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "InvoiceToContactPersonFullName": "Dhr. A. Taal",
                "InvoiceToName": "Rest. `t Vissershuis",
                "Modified": "22-06-2023",
                "Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
                "ModifierFullName": "Lanza Accounting",
                "OrderDate": "22-06-2023",
                "OrderedBy": "420bc17c-e104-49e0-9c85-af927a6e589a",
                "OrderedByContactPerson": "d76eebaa-cb76-4e93-95f5-7836ab4939dc",
                "OrderedByContactPersonFullName": "Dhr. A. Taal",
                "OrderedByName": "Rest. `t Vissershuis",
                "OrderID": "a39a55be-9c73-48b6-9a2d-f5bb735514f8",
                "OrderNumber": 8363,
                "PaymentCondition": "11",
                "PaymentConditionDescription": "7 Dagen Incasso",
                "PaymentReference": null,
                "Remarks": null,
                "SalesChannel": null,
                "SalesChannelCode": null,
                "SalesChannelDescription": null,
                "SalesOrderOrderChargeLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'a39a55be-9c73-48b6-9a2d-f5bb735514f8')\/SalesOrderOrderChargeLines"
                    }
                },
                "Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
                "SalespersonFullName": "Thomas Lang",
                "SelectionCode": null,
                "SelectionCodeCode": null,
                "SelectionCodeDescription": null,
                "ShippingMethod": null,
                "ShippingMethodDescription": null,
                "Status": "Complete",
                "StatusDescription": "Volledig",
                "WarehouseID": "0b05b996-bdc0-446f-aabd-3a5e0fb772e3",
                "WarehouseCode": "1",
                "WarehouseDescription": "Magazijn",
                "YourRef": null,
                "SalesOrderLines": {
                    "__deferred": {
                        "uri": "https:\/\/start.exactonline.nl\/api\/v1\/3120592\/salesorder\/SalesOrders(guid'a39a55be-9c73-48b6-9a2d-f5bb735514f8')\/SalesOrderLines"
                    }
                },
                "TotalCost": 142.75,
                "margin": "45.7%"
            },
            "lines": [
                {
                    "id": "e2a65be3-a3cf-4811-9ea8-392c501d460c",
                    "item_id": "d1edd5d9-e40e-4900-bb67-69124c77fc6d",
                    "item_code": "LA380-6",
                    "item_description": "Maxi Jumbo Eco rol 380 , recycled wit, verpakt per 6 stuks",
                    "cost_price": 12.32,
                    "cost_price_total": 12.32,
                    "description": "Maxi Jumbo Eco rol 380 , recycled wit, verpakt per 6 stuks",
                    "quantity": 1,
                    "line_total": 42.25,
                    "order_status": "Complete"
                },
                {
                    "id": "6b0ba6cc-76b6-455e-8df2-62681c68e1b8",
                    "item_id": "dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
                    "item_code": "LA300-6",
                    "item_description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "cost_price": 15.98,
                    "cost_price_total": 15.98,
                    "description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
                    "quantity": 1,
                    "line_total": 34.1,
                    "order_status": "Complete"
                },
                {
                    "id": "77e2fb9a-152b-489d-b95f-ffa1a7a2e467",
                    "item_id": "efc20f52-9391-49d1-8181-f77763ac3d59",
                    "item_code": "MSU-87490",
                    "item_description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "cost_price": 38.15,
                    "cost_price_total": 114.45,
                    "description": "Airlaid Servetten Linclass Royalblauw 40x40cm, 65gr. 300",
                    "quantity": 3,
                    "line_total": 235.98,
                    "order_status": "Complete"
                }
            ]
        }
    ],
    "success": true
}

const mockInvoices = 
{
"message": "Sales invoice data loaded successfully.",
"data": [
	{
	"sales_invoice": {
		"AmountFC": 37.8,
		"AmountDiscount": 0,
		"AmountDC": 37.8,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 31.76,
		"Created": "01-04-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "1ee3fd0c-d009-40f2-8176-1278b24ba721",
		"DeliverToContactPerson": "754e4421-7307-49ae-9504-fb48ddedc4c4",
		"DeliverToContactPersonFullName": "Manuela Fischer",
		"DeliverToAddress": "9450c167-0663-4666-bf6b-86c119fc12b7",
		"DeliverToName": "Manuela Fischer",
		"Description": "#7193",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "01-04-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "01-04-2022",
		"InvoiceID": "1aa0260b-0db4-4c80-9728-001d828aab56",
		"InvoiceTo": "1ee3fd0c-d009-40f2-8176-1278b24ba721",
		"InvoiceToContactPerson": "754e4421-7307-49ae-9504-fb48ddedc4c4",
		"InvoiceToContactPersonFullName": "Manuela Fischer",
		"InvoiceToName": "Manuela Fischer",
		"InvoiceNumber": 22700966,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "01-04-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "01-04-2022",
		"OrderedBy": "1ee3fd0c-d009-40f2-8176-1278b24ba721",
		"OrderedByContactPerson": "754e4421-7307-49ae-9504-fb48ddedc4c4",
		"OrderedByContactPersonFullName": "Manuela Fischer",
		"OrderedByName": "Manuela Fischer",
		"OrderNumber": 1054,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7193",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 6.04,
		"VATAmountDC": 6.04,
		"YourRef": "#7193",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'1aa0260b-0db4-4c80-9728-001d828aab56')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'1aa0260b-0db4-4c80-9728-001d828aab56')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 6.68,
		"AmountFC": 6.68,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipment",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "47d281ab-63e8-4da5-8808-4235e81eafa6",
		"InvoiceID": "1aa0260b-0db4-4c80-9728-001d828aab56",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 2,
		"NetPrice": 6.68,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "37b255eb-b103-4bc2-aec6-c5836424db2a",
		"SalesOrderLine": "5a6d0e58-1283-4578-a485-f38cd422ff38",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 1054,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 1.27,
		"VATAmountDC": 1.27,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 25.08,
		"AmountFC": 25.08,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure Herbs Bodylotion 300ml",
		"Discount": 0.0312862108922365,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "0aad23ad-9116-4b54-ba34-f2228a4cb9e2",
		"InvoiceID": "1aa0260b-0db4-4c80-9728-001d828aab56",
		"Item": "b2c328aa-88ca-4b39-a0be-585d11b9fab1",
		"ItemDescription": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
		"ItemCode": "PSHB300-100480-17",
		"LineNumber": 1,
		"NetPrice": 8.36,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 3,
		"SalesOrder": "37b255eb-b103-4bc2-aec6-c5836424db2a",
		"SalesOrderLine": "3f082bb6-21c7-4dd5-85c5-691a373410ed",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 1054,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.63,
		"VATAmountFC": 4.77,
		"VATAmountDC": 4.77,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 108.78,
		"AmountDiscount": 0,
		"AmountDC": 108.78,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 89.9,
		"Created": "01-03-2023",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "f2bfe83b-cec8-43ca-b951-551aefe79b10",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "6e9382f1-fde9-46e8-937c-7d9ba09238ff",
		"DeliverToName": "Nathan van Hattem",
		"Description": "tafelaankleding.myshopify.com order #3603",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "22-03-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "01-03-2023",
		"InvoiceID": "98f6a300-e355-4d43-82fe-001dca342831",
		"InvoiceTo": "f2bfe83b-cec8-43ca-b951-551aefe79b10",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Nathan van Hattem",
		"InvoiceNumber": 23700635,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "01-03-2023",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "01-03-2023",
		"OrderedBy": "f2bfe83b-cec8-43ca-b951-551aefe79b10",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Nathan van Hattem",
		"OrderNumber": 6713,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 18.88,
		"VATAmountDC": 18.88,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'98f6a300-e355-4d43-82fe-001dca342831')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'98f6a300-e355-4d43-82fe-001dca342831')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 89.9,
		"AmountFC": 89.9,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Q-Lights, Special, brandduur 8 uur, doos a 400 stuks.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "2fce6206-5adf-4a89-b226-fa8b9d33929c",
		"GLAccountDescription": "Opbrengst kaarsen 21%",
		"ID": "1fcc707d-dee3-4864-bb6a-f21b655ee50d",
		"InvoiceID": "98f6a300-e355-4d43-82fe-001dca342831",
		"Item": "221c812b-3d32-4fc0-947b-53c1e29497aa",
		"ItemDescription": "Q-Lights, Special, brandduur 8 uur, doos a 400 stuks.",
		"ItemCode": "103-50-00",
		"LineNumber": 1,
		"NetPrice": 44.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "1c053d19-a621-4b70-be4c-7757c9dedaff",
		"SalesOrderLine": "afc441fc-dcaa-4c3e-9330-b89858071c3c",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 6713,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "doos",
		"UnitDescription": "Karton",
		"UnitPrice": 44.95,
		"VATAmountFC": 18.88,
		"VATAmountDC": 18.88,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 61.46,
		"AmountDiscount": 0,
		"AmountDC": 61.46,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 51.64,
		"Created": "02-08-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "70048466-a9fd-4a4a-9dbf-780afa703005",
		"DeliverToContactPerson": "90dfe066-9d31-4889-9714-aa613090c378",
		"DeliverToContactPersonFullName": "Elmar Nitz",
		"DeliverToAddress": "d480cbb4-3778-49fe-803c-00b88fa2654a",
		"DeliverToName": "Elmar Nitz",
		"Description": "#8487",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "02-08-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "02-08-2022",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"InvoiceTo": "70048466-a9fd-4a4a-9dbf-780afa703005",
		"InvoiceToContactPerson": "90dfe066-9d31-4889-9714-aa613090c378",
		"InvoiceToContactPersonFullName": "Elmar Nitz",
		"InvoiceToName": "Elmar Nitz",
		"InvoiceNumber": 22702894,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "02-08-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "02-08-2022",
		"OrderedBy": "70048466-a9fd-4a4a-9dbf-780afa703005",
		"OrderedByContactPerson": "90dfe066-9d31-4889-9714-aa613090c378",
		"OrderedByContactPersonFullName": "Elmar Nitz",
		"OrderedByName": "Elmar Nitz",
		"OrderNumber": 3112,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#8487",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 9.82,
		"VATAmountDC": 9.82,
		"YourRef": "#8487",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'f8aa355f-bc4c-4ab8-abac-00238ad21f2a')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'f8aa355f-bc4c-4ab8-abac-00238ad21f2a')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 3.78,
		"AmountFC": 3.78,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure herbs bodylotion 60ml",
		"Discount": 0.1,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "14c3d723-cd4d-421a-a521-25d81ca45ff8",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "c5ce04f1-5f00-4021-a27d-b3c6992779db",
		"ItemDescription": "Pure Herbs Body Lotion 60m in bottle Continental, doos a 160",
		"ItemCode": "CTBL060-100480-18",
		"LineNumber": 4,
		"NetPrice": 1.89,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "2fdb92ca-6664-46b3-9fa4-54ca9a8f13be",
		"SalesOrderLineNumber": 4,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.1,
		"VATAmountFC": 0.72,
		"VATAmountDC": 0.72,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 7.52,
		"AmountFC": 7.52,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure Herbs Massageolie 250ml",
		"Discount": 0.187027027027027,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "f7b44bb9-e7f3-48da-a1f0-3ba40897d58b",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "049d1b86-43e5-4c12-8dab-e837dd5f508a",
		"ItemDescription": "Pure Herbs Massage Oil 250 ml in bottle Continental . doos a",
		"ItemCode": "CTMO250-100480-3",
		"LineNumber": 2,
		"NetPrice": 7.52,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "c8d69baa-bd5b-4660-9e4e-47f7e4d53b96",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 9.25,
		"VATAmountFC": 1.43,
		"VATAmountDC": 1.43,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 8.36,
		"AmountFC": 8.36,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure Herbs Haar & Body Shampoo 300ml",
		"Discount": 0.0962162162162163,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "625f5aab-223a-4f44-bdb5-6577c7249c17",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "15bb518f-77fb-43f3-af94-4007e9ac7aef",
		"ItemDescription": "Pure Herbs Shampoo Hair & Body 300ml in fles met pompje prij",
		"ItemCode": "PSBS300-100480-18",
		"LineNumber": 6,
		"NetPrice": 8.36,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "e9c0fabb-07eb-4827-8d68-cf6554f63f09",
		"SalesOrderLineNumber": 6,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 9.25,
		"VATAmountFC": 1.59,
		"VATAmountDC": 1.59,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 1.89,
		"AmountFC": 1.89,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure herbs bodypeeling 60ml",
		"Discount": 0.1,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "9018eb3e-c98e-41ce-b776-84a943ecbf62",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "b6547273-3aec-4da5-8a81-9e219b283f6f",
		"ItemDescription": "Pure Herbs  Body Peeling 60ml in bottle Continental",
		"ItemCode": "CTKP060-100480-12",
		"LineNumber": 1,
		"NetPrice": 1.89,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "20bbb116-9b0e-454d-8d80-e93206552d5f",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.1,
		"VATAmountFC": 0.36,
		"VATAmountDC": 0.36,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 8.36,
		"AmountFC": 8.36,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure Herbs Bodylotion 300ml",
		"Discount": 0.0312862108922365,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "1b7c8b76-c3c6-4efe-8794-a1b4a2740905",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "b2c328aa-88ca-4b39-a0be-585d11b9fab1",
		"ItemDescription": "Pure Herbs Hand & Body Lotion 300ml in Pisa pump",
		"ItemCode": "PSHB300-100480-17",
		"LineNumber": 7,
		"NetPrice": 8.36,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "d8a4890c-2d8d-4dac-a599-a94a8ed82fac",
		"SalesOrderLineNumber": 7,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.63,
		"VATAmountFC": 1.59,
		"VATAmountDC": 1.59,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 3.28,
		"AmountFC": 3.28,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Pure herbs shampoo 60ml",
		"Discount": 0.0939226519337017,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "f5c7d496-544d-4759-aece-b07301072fa3",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "dc93485b-3774-4ff4-ba1a-3baa2dd6a7e3",
		"ItemDescription": "Pure Herbs Shampoo 60 ml in bottle Continental .",
		"ItemCode": "CTHS060-100480-18",
		"LineNumber": 5,
		"NetPrice": 1.64,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "dc9bbd5f-6d20-4e2d-8fd0-37cfcd4d1dd0",
		"SalesOrderLineNumber": 5,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 1.81,
		"VATAmountFC": 0.62,
		"VATAmountDC": 0.62,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 18.45,
		"AmountFC": 18.45,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Body & Spa Shop Pure Herbs Hand & Bodylotion 1 Ltr.",
		"Discount": 0.0314960629921261,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "70b8db42-7a8b-45b6-8ea1-be50940ee468",
		"InvoiceID": "f8aa355f-bc4c-4ab8-abac-00238ad21f2a",
		"Item": "a87cdd88-bb9e-4c68-81d5-93788f9ec400",
		"ItemDescription": "Pure Herbs Hand & Body Lotion 1 Ltr.",
		"ItemCode": "NBHB001-100480",
		"LineNumber": 3,
		"NetPrice": 18.45,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "27ab8c37-c64a-4ee5-a42d-82585a2a1ff0",
		"SalesOrderLine": "66356b49-4a74-4b4c-86d0-7c99731b048c",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 3112,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 19.05,
		"VATAmountFC": 3.51,
		"VATAmountDC": 3.51,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 19.9,
		"AmountDiscount": 0,
		"AmountDC": 19.9,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 16.72,
		"Created": "27-04-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "04a87243-18e1-41a9-a05e-75d17d5c358c",
		"DeliverToContactPerson": "60ff6485-e5bf-463a-83bd-c736ef70f53c",
		"DeliverToContactPersonFullName": "Larissa Burghardt",
		"DeliverToAddress": "2c696102-39ed-4e74-bed3-2c04a65a8a15",
		"DeliverToName": "Larissa Burghardt",
		"Description": "#7437",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "27-04-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "27-04-2022",
		"InvoiceID": "47dd7485-686c-41a8-9433-002c816a7278",
		"InvoiceTo": "04a87243-18e1-41a9-a05e-75d17d5c358c",
		"InvoiceToContactPerson": "60ff6485-e5bf-463a-83bd-c736ef70f53c",
		"InvoiceToContactPersonFullName": "Larissa Burghardt",
		"InvoiceToName": "Larissa Burghardt",
		"InvoiceNumber": 22701398,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "27-04-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "27-04-2022",
		"OrderedBy": "04a87243-18e1-41a9-a05e-75d17d5c358c",
		"OrderedByContactPerson": "60ff6485-e5bf-463a-83bd-c736ef70f53c",
		"OrderedByContactPersonFullName": "Larissa Burghardt",
		"OrderedByName": "Larissa Burghardt",
		"OrderNumber": 1510,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7437",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 3.18,
		"VATAmountDC": 3.18,
		"YourRef": "#7437",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'47dd7485-686c-41a8-9433-002c816a7278')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'47dd7485-686c-41a8-9433-002c816a7278')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 6.68,
		"AmountFC": 6.68,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipment",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "9e78b0f2-189e-4336-b1bc-3b0de1571570",
		"InvoiceID": "47dd7485-686c-41a8-9433-002c816a7278",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 2,
		"NetPrice": 6.68,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "8beb6136-d5f7-4954-8fdd-513ed1c5072e",
		"SalesOrderLine": "c7eb2c34-9dae-4ee9-a994-bd79af6176ce",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 1510,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 1.27,
		"VATAmountDC": 1.27,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 10.04,
		"AmountFC": 10.04,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Aqua Senses Aqua Senses Hand & Body lotion 300ml",
		"Discount": -0.185360094451003,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "cc82b881-f6e2-44aa-be3b-5cf94ccc7f9f",
		"InvoiceID": "47dd7485-686c-41a8-9433-002c816a7278",
		"Item": "a878ddc9-4684-4a00-88b4-a30be88827ac",
		"ItemDescription": "Aqua Senses Hand- und Body Lotion 300 ml pompfles.",
		"ItemCode": "PAHB300-100630-15",
		"LineNumber": 1,
		"NetPrice": 10.04,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "8beb6136-d5f7-4954-8fdd-513ed1c5072e",
		"SalesOrderLine": "6bf21504-f212-430b-a0a7-1ac064eaabcd",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 1510,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.47,
		"VATAmountFC": 1.91,
		"VATAmountDC": 1.91,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 58.39,
		"AmountDiscount": 0,
		"AmountDC": 58.39,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 48.26,
		"Created": "05-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "32b1ed9c-55ae-46d9-9b47-f964691c9766",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "ed50362f-6130-4707-b779-2bb33b0fec56",
		"DeliverToName": "C. A. Drost",
		"Description": "Webshop order welness-store.myshopify.com #9221",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "03-11-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "13-10-2022",
		"InvoiceID": "dab101b4-7ee4-45ee-98eb-0038d1483a52",
		"InvoiceTo": "32b1ed9c-55ae-46d9-9b47-f964691c9766",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "C. A. Drost",
		"InvoiceNumber": 22704733,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "13-10-2022",
		"OrderedBy": "32b1ed9c-55ae-46d9-9b47-f964691c9766",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "C. A. Drost",
		"OrderNumber": 5332,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.13,
		"VATAmountDC": 10.13,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'dab101b4-7ee4-45ee-98eb-0038d1483a52')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'dab101b4-7ee4-45ee-98eb-0038d1483a52')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 40.45,
		"AmountFC": 40.45,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Vegan theelichten 8 uur 240 stuks",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "2fce6206-5adf-4a89-b226-fa8b9d33929c",
		"GLAccountDescription": "Opbrengst kaarsen 21%",
		"ID": "32a160f7-9be2-4148-9c23-371506704a08",
		"InvoiceID": "dab101b4-7ee4-45ee-98eb-0038d1483a52",
		"Item": "207c368b-0cf7-4507-9a68-a667e6046318",
		"ItemDescription": "Vegan theelichten 8 uur 240 stuks",
		"ItemCode": "F-251",
		"LineNumber": 2,
		"NetPrice": 40.45,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "57f18013-3bcc-41a7-beaa-ab32da111ddd",
		"SalesOrderLine": "b8df7bbf-5123-482f-87b1-7033d55030f2",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 5332,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 40.45,
		"VATAmountFC": 8.49,
		"VATAmountDC": 8.49,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 5.74,
		"AmountFC": 5.74,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipping",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "062d5e17-8e61-460d-9609-e364e0e0f94a",
		"InvoiceID": "dab101b4-7ee4-45ee-98eb-0038d1483a52",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 3,
		"NetPrice": 5.74,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "57f18013-3bcc-41a7-beaa-ab32da111ddd",
		"SalesOrderLine": "fa174800-9cc3-42a9-8431-482884ca7bfc",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 5332,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 5.74,
		"VATAmountFC": 1.21,
		"VATAmountDC": 1.21,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 2.07,
		"AmountFC": 2.07,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Navidium Shipping Protection Default",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "8f4f315d-bac8-4159-9871-fe1b5ca2597a",
		"InvoiceID": "dab101b4-7ee4-45ee-98eb-0038d1483a52",
		"Item": "2f76e532-8ac6-40db-aab7-83ae9e683f2e",
		"ItemDescription": "Navidium Shipping Protection Default",
		"ItemCode": "NVDPROTECTION0",
		"LineNumber": 1,
		"NetPrice": 2.07,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "57f18013-3bcc-41a7-beaa-ab32da111ddd",
		"SalesOrderLine": "425f0584-480a-4837-9ef5-1e73a4e4baf1",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 5332,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.07,
		"VATAmountFC": 0.43,
		"VATAmountDC": 0.43,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 1165.57,
		"AmountDiscount": 0,
		"AmountDC": 1165.57,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 963.29,
		"Created": "29-10-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "9ed1789b-e2e1-4db4-9184-9abf0542de9f",
		"DeliverToContactPerson": "23d481db-5c14-470f-9b15-40e7e88f3edf",
		"DeliverToContactPersonFullName": "Marcel en Franciska de Bil",
		"DeliverToAddress": "803ff092-4bbe-45ae-b390-475ff9c8a73d",
		"DeliverToName": "Hotel `t Klokje",
		"Description": "Order placed in mobile app",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "05-11-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "29-10-2022",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"InvoiceTo": "9ed1789b-e2e1-4db4-9184-9abf0542de9f",
		"InvoiceToContactPerson": "23d481db-5c14-470f-9b15-40e7e88f3edf",
		"InvoiceToContactPersonFullName": "Marcel en Franciska de Bil",
		"InvoiceToName": "Hotel `t Klokje",
		"InvoiceNumber": 22704107,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "29-10-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "29-10-2022",
		"OrderedBy": "9ed1789b-e2e1-4db4-9184-9abf0542de9f",
		"OrderedByContactPerson": "23d481db-5c14-470f-9b15-40e7e88f3edf",
		"OrderedByContactPersonFullName": "Marcel en Franciska de Bil",
		"OrderedByName": "Hotel `t Klokje",
		"OrderNumber": 4647,
		"PaymentCondition": "11",
		"PaymentConditionDescription": "7 Dagen Incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "8fb1cc8a-85cf-467f-9ac3-bdd3b0b41c13",
		"SalespersonFullName": "Rick van Drongelen",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 202.28,
		"VATAmountDC": 202.28,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2dd8f41d-5cbb-4a03-a3cf-00416614f042')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2dd8f41d-5cbb-4a03-a3cf-00416614f042')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 499.75,
		"AmountFC": 499.75,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Toiletpapier Lanza 9,8 x 140,cellulose 2 laags , doos a 32 r",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "39ac2cb6-5137-4490-b867-6c5ecc629b49",
		"GLAccountDescription": "Opbrengst Maxi-, Mini Toiletrollen 21%",
		"ID": "313ff75e-4a82-4095-8261-0ac345a6bd3f",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "84aa63aa-9183-4366-b4e6-08de86aa8584",
		"ItemDescription": "Toiletpapier Lanza 9,8 x 140,cellulose 2 laags , doos a 32 r",
		"ItemCode": "TP140",
		"LineNumber": 2,
		"NetPrice": 99.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 5,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "fea50bc0-5ef1-4c0f-bc2b-d94f0b581761",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 99.95,
		"VATAmountFC": 104.95,
		"VATAmountDC": 104.95,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 49.25,
		"AmountFC": 49.25,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Facial Tissues verpakt in doos van 40 doosjes a 100 stuks ce",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "262e7a71-7ef6-416f-88d7-2469b043eda9",
		"GLAccountDescription": "Opbrengst tissues, 21%",
		"ID": "e97c4f34-6d76-4e98-a575-174324035521",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "10dbd9a2-5859-4296-b935-8da96ba6ed0c",
		"ItemDescription": "Facial Tissues verpakt in doos van 40 doosjes a 100 stuks ce",
		"ItemCode": "LA4000-40",
		"LineNumber": 3,
		"NetPrice": 49.25,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "d627d142-1865-4e4f-b5c2-4f3bd38933f4",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 49.25,
		"VATAmountFC": 10.34,
		"VATAmountDC": 10.34,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 52.64,
		"AmountFC": 52.64,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "a8ab015a-9958-4aea-a545-0af4e7dccd0f",
		"GLAccountDescription": "Opbrengst Handdoekrollen 21%",
		"ID": "3cbe4bfa-3371-4495-80d6-2bd79df4110c",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "0bdff01b-3d50-40c0-a5ee-1897e7824c66",
		"ItemDescription": "Handdoekrollen, 3 lgs, extra strong, , verpakt per 6 stuks",
		"ItemCode": "LA100-6",
		"LineNumber": 4,
		"NetPrice": 52.64,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "500f2c8c-a4d9-438c-8b75-130d9aabb871",
		"SalesOrderLineNumber": 4,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 52.64,
		"VATAmountFC": 11.05,
		"VATAmountDC": 11.05,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 22.72,
		"AmountFC": 22.72,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Zeepschuim voor cos 1000ml. antibacterieel per fles (6 in do",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "b91ee64e-079d-4bc9-a8e1-35364a110ac2",
		"GLAccountDescription": "Opbrengst Zeep 21%",
		"ID": "6bb7a8db-1530-49a3-a04f-2e4890add3d3",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "7910cac6-b1fb-4df3-8263-6fc5ed07fbf3",
		"ItemDescription": "Zeepschuim voor cos 1000ml. antibacterieel per fles (6 in do",
		"ItemCode": "FAS1000",
		"LineNumber": 7,
		"NetPrice": 11.36,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "f11870dc-231b-4366-a541-87b80591fe28",
		"SalesOrderLineNumber": 7,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 11.36,
		"VATAmountFC": 4.77,
		"VATAmountDC": 4.77,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 237,
		"AmountFC": 237,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Aqua Senses, Smart Care Hand, Hair + Body Wash",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "6be251be-5563-4cf3-9d8b-d33349ee665b",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "20e68a62-3fad-4c14-a41d-d1a75b63f39f",
		"ItemDescription": "Aqua Senses, Smart Care Hand, Hair + Body Wash",
		"ItemCode": "AQS300SMAIO",
		"LineNumber": 1,
		"NetPrice": 3.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 60,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "43fb9569-9976-407d-8020-8a0c2c8f9b59",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 3.95,
		"VATAmountFC": 49.77,
		"VATAmountDC": 49.77,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 34.64,
		"AmountFC": 34.64,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Zeepschuim voor cos 1000ml. per fles (6 in doos)",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "b91ee64e-079d-4bc9-a8e1-35364a110ac2",
		"GLAccountDescription": "Opbrengst Zeep 21%",
		"ID": "e6570f59-4095-400d-8d47-d65a150f4d0b",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "b915b2ef-78c3-4ccc-9499-534a4ac9b557",
		"ItemDescription": "Zeepschuim voor cos 1000ml. per fles (6 in doos)",
		"ItemCode": "FS1000",
		"LineNumber": 6,
		"NetPrice": 8.66,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 4,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "d0cf9544-cf75-4683-ba9e-d3163316b287",
		"SalesOrderLineNumber": 6,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.66,
		"VATAmountFC": 7.27,
		"VATAmountDC": 7.27,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 67.29,
		"AmountFC": 67.29,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs showercap in rectangularcardboard box\r\n250 stuks",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "34165746-ac53-4382-8454-e34b25c265f2",
		"InvoiceID": "2dd8f41d-5cbb-4a03-a3cf-00416614f042",
		"Item": "49401ba2-b012-4aa2-a4ae-0a3ac2528fcb",
		"ItemDescription": "Pure Herbs showercap in rectangularcardboard box250 stuks",
		"ItemCode": "HKDH012-100480-8",
		"LineNumber": 5,
		"NetPrice": 67.29,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "a9da8924-3e47-4622-bbe7-950966c1ecc6",
		"SalesOrderLine": "9b31a6c6-4af1-4af3-a649-8b73c3f73800",
		"SalesOrderLineNumber": 5,
		"SalesOrderNumber": 4647,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 67.29,
		"VATAmountFC": 14.13,
		"VATAmountDC": 14.13,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 253.51,
		"AmountDiscount": 0,
		"AmountDC": 253.51,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 209.51,
		"Created": "17-02-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "91878b72-1cc2-4857-8968-d01d740a2c9b",
		"DeliverToContactPerson": "42a59f18-ef6f-4501-b8f5-6a74ba20793b",
		"DeliverToContactPersonFullName": "Linda Meijer",
		"DeliverToAddress": "71393170-3d13-46e1-bb85-f870f35a1b9c",
		"DeliverToName": "Pand13",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "20-02-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "17-02-2022",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"InvoiceTo": "91878b72-1cc2-4857-8968-d01d740a2c9b",
		"InvoiceToContactPerson": "42a59f18-ef6f-4501-b8f5-6a74ba20793b",
		"InvoiceToContactPersonFullName": "Linda Meijer",
		"InvoiceToName": "Pand13",
		"InvoiceNumber": 22700452,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "17-02-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "17-02-2022",
		"OrderedBy": "91878b72-1cc2-4857-8968-d01d740a2c9b",
		"OrderedByContactPerson": "42a59f18-ef6f-4501-b8f5-6a74ba20793b",
		"OrderedByContactPersonFullName": "Linda Meijer",
		"OrderedByName": "Pand13",
		"OrderNumber": 509,
		"PaymentCondition": "11",
		"PaymentConditionDescription": "7 Dagen Incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 44,
		"VATAmountDC": 44,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'3c665105-c025-4523-9a8e-0046624244b5')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'3c665105-c025-4523-9a8e-0046624244b5')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Luchtverfrisservulling 100 ML, geur Herbal Fern, prijs per s",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "b78cf4cf-0abf-4b01-bbd6-4b031bda4f9a",
		"GLAccountDescription": "Opbrengst Luchtverfrisser, 21%",
		"ID": "4d2478b5-9ef0-4b3f-ba45-0cfe7b95d9d4",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "010849f7-8d9a-4c8a-83a1-1d92a7157bf1",
		"ItemDescription": "Luchtverfrisservulling 100 ML, geur Herbal Fern, prijs per s",
		"ItemCode": "BAERO-03",
		"LineNumber": 3,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 3,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "1c21da86-5dc3-43bb-b384-14d1f33096e2",
		"GLAccountDescription": "Opbrengst Poetsrollen 21%",
		"ID": "41120303-7963-47cd-8527-61aabb7d3666",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "dc8a7a1e-4ed3-4331-bc21-efc017268f8b",
		"ItemDescription": "Poetsrollen Midi, recycling,  verpakt per 6 stuks.",
		"ItemCode": "LA300-6",
		"LineNumber": 5,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 8,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Toiletpapier Lanza 9,8 x 140,cellulose 2 laags , doos a 32 r",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "39ac2cb6-5137-4490-b867-6c5ecc629b49",
		"GLAccountDescription": "Opbrengst Maxi-, Mini Toiletrollen 21%",
		"ID": "3f465d1b-9145-43f0-ace0-6f4ecfd3bf87",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "84aa63aa-9183-4366-b4e6-08de86aa8584",
		"ItemDescription": "Toiletpapier Lanza 9,8 x 140,cellulose 2 laags , doos a 32 r",
		"ItemCode": "TP140",
		"LineNumber": 4,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Handdoekrollen, 2 lgs, cellulose, biologisch afbreekbaar, ve",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "a8ab015a-9958-4aea-a545-0af4e7dccd0f",
		"GLAccountDescription": "Opbrengst Handdoekrollen 21%",
		"ID": "5ce0bb0f-f2cf-40ff-bf33-997c1ea2fe1a",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "6529feb8-cfec-4886-8b77-5cfe8df3d85f",
		"ItemDescription": "Handdoekrollen, 2 lgs, cellulose, biologisch afbreekbaar, ve",
		"ItemCode": "Lanza140",
		"LineNumber": 1,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Zeepschuimdispenser zwart manueel",
		"Discount": 1,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "f24f31e9-2560-479b-b8a7-cae145ede139",
		"GLAccountDescription": "Opbrengst Zeepdispenser 21%",
		"ID": "219db847-07ad-4e2c-bd42-be7082d1773e",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "a2632805-197e-4c49-8131-036bd0a50fef",
		"ItemDescription": "Zeepschuimdispenser zwart manueel",
		"ItemCode": "Cos-1100-LAN",
		"LineNumber": 7,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 49.27,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 209.51,
		"AmountFC": 209.51,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Q-Lights, Refills ÃƒÂ¸ 52x64 mm clear 60 stuks per doos.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "2fce6206-5adf-4a89-b226-fa8b9d33929c",
		"GLAccountDescription": "Opbrengst kaarsen 21%",
		"ID": "21bb828c-b101-4262-ad5a-e85ef586ea60",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "ae1a1ea0-fa2c-4d80-9725-8c4fd034cff9",
		"ItemDescription": "Q-Lights, Refills ÃƒÂ¸ 52x64 mm clear 60 stuks per doos.",
		"ItemCode": "911-60-01",
		"LineNumber": 8,
		"NetPrice": 29.93,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 7,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 29.93,
		"VATAmountFC": 44,
		"VATAmountDC": 44,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Alkaline Batterijen voor Luchtverfrisser, zeepdispenser. C",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "07250481-3a3d-4a15-935f-8efde8ad9062",
		"GLAccountDescription": "Opbrengst Batterijen, 21%",
		"ID": "3d2777d1-e3f1-4a8a-815f-ee8da804a33a",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "8a0223fd-6655-4a01-9f08-5d6aae091d27",
		"ItemDescription": "Alkaline Batterijen voor Luchtverfrisser, zeepdispenser. C",
		"ItemCode": "H-MN1400B6",
		"LineNumber": 2,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Airfreshner Micro Airoma MVP Dispenser Black/chrome with Lan",
		"Discount": 1,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "b78cf4cf-0abf-4b01-bbd6-4b031bda4f9a",
		"GLAccountDescription": "Opbrengst Luchtverfrisser, 21%",
		"ID": "e6fd4477-f1a7-4bc2-ad26-fd268abc0a15",
		"InvoiceID": "3c665105-c025-4523-9a8e-0046624244b5",
		"Item": "0bcd38fa-a6be-4f0e-8e01-aaeaf139f0a0",
		"ItemDescription": "Airfreshner Micro Airoma MVP Dispenser Black/chrome with Lan",
		"ItemCode": "BDIS-MVBPC",
		"LineNumber": 6,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 82.33,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 1852.75,
		"AmountDiscount": 0,
		"AmountDC": 1852.75,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 1531.2,
		"Created": "31-10-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "4a36cbf1-fe15-46e6-ba38-eb5f23773e8f",
		"DeliverToContactPerson": "b829971a-d8c5-4690-b285-9fd2e616c8a1",
		"DeliverToContactPersonFullName": "Grand Caf Hotel De Bourgondir",
		"DeliverToAddress": "cda738d0-6de0-4156-8e19-a76d90c8e01a",
		"DeliverToName": "Grand Caf Hotel De Bourgondiër",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "07-11-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "31-10-2022",
		"InvoiceID": "2ed4b8a6-60a7-4045-aca6-005e1c2f4000",
		"InvoiceTo": "4a36cbf1-fe15-46e6-ba38-eb5f23773e8f",
		"InvoiceToContactPerson": "b829971a-d8c5-4690-b285-9fd2e616c8a1",
		"InvoiceToContactPersonFullName": "Grand Caf Hotel De Bourgondir",
		"InvoiceToName": "Grand Caf Hotel De Bourgondiër",
		"InvoiceNumber": 22704121,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "31-10-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "31-10-2022",
		"OrderedBy": "4a36cbf1-fe15-46e6-ba38-eb5f23773e8f",
		"OrderedByContactPerson": "b829971a-d8c5-4690-b285-9fd2e616c8a1",
		"OrderedByContactPersonFullName": "Grand Caf Hotel De Bourgondir",
		"OrderedByName": "Grand Caf Hotel De Bourgondiër",
		"OrderNumber": 4662,
		"PaymentCondition": "11",
		"PaymentConditionDescription": "7 Dagen Incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 321.55,
		"VATAmountDC": 321.55,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2ed4b8a6-60a7-4045-aca6-005e1c2f4000')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2ed4b8a6-60a7-4045-aca6-005e1c2f4000')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "volgens afspraak voor u bij ons op voorraad besteld",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "4ffd298c-95a8-4832-b61d-2508ae19455e",
		"GLAccountDescription": "Opbrengst verkopen diversen 21%",
		"ID": "f5d5bbce-2d17-45eb-a753-3d2de20a2f8e",
		"InvoiceID": "2ed4b8a6-60a7-4045-aca6-005e1c2f4000",
		"Item": "42d61804-e3ce-467c-8314-2764338e440e",
		"ItemDescription": "volgens afspraak voor u bij ons op voorraad besteld",
		"ItemCode": "33",
		"LineNumber": 1,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 1531.2,
		"AmountFC": 1531.2,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Airlaid Servetten Linclass creme 40 x 40 1/4 doos a 300 stuk",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3e80a560-6a10-4d7a-a8b1-576a429f57a0",
		"GLAccountDescription": "Opbrengst Servetten 21%",
		"ID": "39587eef-3ae2-42e4-9a80-66f1f567482a",
		"InvoiceID": "2ed4b8a6-60a7-4045-aca6-005e1c2f4000",
		"Item": "376bdd41-bcc6-4d39-ad24-921b48e0d5f1",
		"ItemDescription": "Airlaid Servetten Linclass creme 40 x 40 1/4 doos a 300 stuk",
		"ItemCode": "MSU-87512",
		"LineNumber": 2,
		"NetPrice": 69.6,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 22,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 69.6,
		"VATAmountFC": 321.55,
		"VATAmountDC": 321.55,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 62.75,
		"AmountDiscount": 0,
		"AmountDC": 62.75,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 52.73,
		"Created": "20-06-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "ab10c1e1-b161-472b-b8d3-f31e6dd9c722",
		"DeliverToContactPerson": "39c99089-fdcd-4c9b-8810-5f1e1848288f",
		"DeliverToContactPersonFullName": "Peggy Joos",
		"DeliverToAddress": "8a805f1b-9c3f-49e7-b642-057056bdb5a1",
		"DeliverToName": "Peggy Joos",
		"Description": "#8021",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "20-06-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "20-06-2022",
		"InvoiceID": "840eecb9-8808-43f6-adcc-006260524667",
		"InvoiceTo": "ab10c1e1-b161-472b-b8d3-f31e6dd9c722",
		"InvoiceToContactPerson": "39c99089-fdcd-4c9b-8810-5f1e1848288f",
		"InvoiceToContactPersonFullName": "Peggy Joos",
		"InvoiceToName": "Peggy Joos",
		"InvoiceNumber": 22702258,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "20-06-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "20-06-2022",
		"OrderedBy": "ab10c1e1-b161-472b-b8d3-f31e6dd9c722",
		"OrderedByContactPerson": "39c99089-fdcd-4c9b-8810-5f1e1848288f",
		"OrderedByContactPersonFullName": "Peggy Joos",
		"OrderedByName": "Peggy Joos",
		"OrderNumber": 2423,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#8021",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.02,
		"VATAmountDC": 10.02,
		"YourRef": "#8021",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'840eecb9-8808-43f6-adcc-006260524667')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'840eecb9-8808-43f6-adcc-006260524667')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 22.65,
		"AmountFC": 22.65,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Hydro Basics Hydro Basics 300ml set",
		"Discount": 0.199646643109541,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "21ed545d-3590-4921-9899-0ca8c6105f08",
		"InvoiceID": "840eecb9-8808-43f6-adcc-006260524667",
		"Item": "ae10131f-6bea-45f4-96d7-3fbafdab4f78",
		"ItemDescription": "Hydro Basics 300ml set",
		"ItemCode": "hydro-basics-300ml-set",
		"LineNumber": 1,
		"NetPrice": 22.65,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "2ddb8c2f-3df9-42fa-ba25-169bd4b97808",
		"SalesOrderLine": "f7d60c61-f6b0-482f-acbd-bd43f7bc5004",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 2423,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 28.3,
		"VATAmountFC": 4.3,
		"VATAmountDC": 4.3,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 30.08,
		"AmountFC": 30.08,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Body & Spa Shop Hydro Basics Gentle Liquid Soap - smart care",
		"Discount": 0.112160566706021,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "616dc3ce-d429-4499-845d-8e34acbfb1bc",
		"InvoiceID": "840eecb9-8808-43f6-adcc-006260524667",
		"Item": "78cbb85a-a8bb-4581-86fe-d7495f718024",
		"ItemDescription": "Hydro Basics Liquid cream soap 300ml, Smart Care",
		"ItemCode": "SMLS300-100200-HYB300SML",
		"LineNumber": 2,
		"NetPrice": 7.52,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 4,
		"SalesOrder": "2ddb8c2f-3df9-42fa-ba25-169bd4b97808",
		"SalesOrderLine": "4a7432a2-36e0-4b64-b251-7cc9d157c0da",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 2423,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.47,
		"VATAmountFC": 5.72,
		"VATAmountDC": 5.72,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 444.48,
		"AmountDiscount": 0,
		"AmountDC": 444.48,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 367.34,
		"Created": "29-05-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "b0c7099a-83eb-4a5a-a090-79dbe6f4bab5",
		"DeliverToContactPerson": "680e75c5-bedc-425b-be72-44b80f6d5e8b",
		"DeliverToContactPersonFullName": "Bema Kunststoffen B.V.",
		"DeliverToAddress": "3f8c4e24-3619-4232-bf5e-4ceab0b27390",
		"DeliverToName": "Bema Kunststoffen B.V.",
		"Description": "Serviceabonnement - Maandelijks",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "12-06-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "29-05-2022",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"InvoiceTo": "b0c7099a-83eb-4a5a-a090-79dbe6f4bab5",
		"InvoiceToContactPerson": "680e75c5-bedc-425b-be72-44b80f6d5e8b",
		"InvoiceToContactPersonFullName": "Bema Kunststoffen B.V.",
		"InvoiceToName": "Bema Kunststoffen B.V.",
		"InvoiceNumber": 22701893,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "29-05-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "29-05-2022",
		"OrderedBy": "b0c7099a-83eb-4a5a-a090-79dbe6f4bab5",
		"OrderedByContactPerson": "680e75c5-bedc-425b-be72-44b80f6d5e8b",
		"OrderedByContactPersonFullName": "Bema Kunststoffen B.V.",
		"OrderedByName": "Bema Kunststoffen B.V.",
		"OrderNumber": 2032,
		"PaymentCondition": "14",
		"PaymentConditionDescription": "doorlopend standaard incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": null,
		"SalespersonFullName": null,
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 77.14,
		"VATAmountDC": 77.14,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'577a3f3d-c00d-4a61-b6b0-0083e6426818')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'577a3f3d-c00d-4a61-b6b0-0083e6426818')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "3 x Hygienezakjeshouder, kleur Zwart Soft Touch",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "4327f55e-29c5-4bbf-b068-10db4570a18e",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 4,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "0da6f70e-7f28-42ec-8d16-48823160fb5f",
		"SubscriptionDescription": "3 x Hygienezakjeshouder, kleur Zwart Soft Touch",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "6 x Toiletborstelhouder met muurbevestiging, kleur Zwart Sof",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "7e2f5285-6b5f-440f-a1c2-13e559672f00",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 3,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "5722c1c9-c7bf-4a75-8d2a-9f7bc5b9ca2b",
		"SubscriptionDescription": "6 x Toiletborstelhouder met muurbevestiging, kleur Zwart Sof",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "3 x Airfreshner Micro Airoma MVP Dispenser Black/chrome with",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "25705b3a-2ee6-4919-b5e3-168f065466ff",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 7,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "17debe16-9f6a-4a01-81fb-771e52c90565",
		"SubscriptionDescription": "3 x Airfreshner Micro Airoma MVP Dispenser Black/chrome with",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "7 x Zeepschuimdispenser zwart manueel",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "2861653f-4dc1-4395-8ec9-1b9111735b07",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 2,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "11a226d8-ee33-46aa-9549-29da2af7c783",
		"SubscriptionDescription": "7 x Zeepschuimdispenser zwart manueel",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "3 x hygiene Slide bin wandafvalemmer 5L, kleur Zwart",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "f7a76139-f215-4355-ab19-226ad884195c",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 5,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "0c517c5b-902c-4d06-b217-817807326595",
		"SubscriptionDescription": "3 x hygiene Slide bin wandafvalemmer 5L, kleur Zwart",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "6 x Maxitoiletrolhouder Zwart Soft Touch",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "a4029164-2c9e-4dca-894e-59bae6794d7e",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 1,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "c101f9aa-c054-4e92-9259-b6f9b4e03f6c",
		"SubscriptionDescription": "6 x Maxitoiletrolhouder Zwart Soft Touch",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "7 x Afvalbak 23 ltr. Kleur Zwart inclusieve deksel",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "191648c7-31fd-42e8-93eb-742368f83e22",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 6,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "b7e68ef1-d3da-4ca0-9271-223d024165a7",
		"SubscriptionDescription": "7 x Afvalbak 23 ltr. Kleur Zwart inclusieve deksel",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "6 x Poetsrohouder wit",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "b0bfcf78-7d96-4383-aff1-9fcd9229a97a",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 8,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "5c242621-43a9-4f7b-8a69-8ea5b9574d50",
		"SubscriptionDescription": "6 x Poetsrohouder wit",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 367.34,
		"AmountFC": 367.34,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "13978af0-c0d1-4c34-a2e6-e2d4abaa32e7",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 10,
		"NetPrice": 367.34,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "7bb2bef8-70e8-455e-8853-78e7be2fbc08",
		"SubscriptionDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 367.34,
		"VATAmountFC": 77.14,
		"VATAmountDC": 77.14,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 0,
		"AmountFC": 0,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "10 x afvalbakken wit inclusieve deksel",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "29-06-2022",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "86856f7c-5e03-4b76-88be-4a1e6c1b2941",
		"GLAccountDescription": "Opbrengst Lease toiletartikelen, 21%",
		"ID": "c7f839e7-9c4b-470d-87b6-fddb793514de",
		"InvoiceID": "577a3f3d-c00d-4a61-b6b0-0083e6426818",
		"Item": "fde592b9-5389-4787-9b12-5f78eda0a81b",
		"ItemDescription": "volgens afspraak toiletartikelen inclusieve vulmateriaal bed",
		"ItemCode": "All Inn",
		"LineNumber": 9,
		"NetPrice": 0,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 0,
		"SalesOrder": null,
		"SalesOrderLine": null,
		"SalesOrderLineNumber": null,
		"SalesOrderNumber": null,
		"StartTime": "/Date(1653868800000)/",
		"Subscription": "6129e3a4-ce3f-4c2d-beab-e32aad6e28db",
		"SubscriptionDescription": "10 x afvalbakken wit inclusieve deksel",
		"UnitCode": "month",
		"UnitDescription": "Month",
		"UnitPrice": 0,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 82.26,
		"AmountDiscount": 0,
		"AmountDC": 82.26,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 68.55,
		"Created": "07-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "5f033bcc-0e89-4bf3-ae7d-93fd51cab2cc",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "3d33d166-3b64-4a4f-a517-066de8dbd983",
		"DeliverToName": "Raphaël Dolais",
		"Description": "welness-store.myshopify.com order #9739",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "28-12-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "07-12-2022",
		"InvoiceID": "e2332bf4-f443-4f28-899b-008889af0ac8",
		"InvoiceTo": "5f033bcc-0e89-4bf3-ae7d-93fd51cab2cc",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Raphaël Dolais",
		"InvoiceNumber": 22704969,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "07-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "07-12-2022",
		"OrderedBy": "5f033bcc-0e89-4bf3-ae7d-93fd51cab2cc",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Raphaël Dolais",
		"OrderNumber": 5561,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 13.71,
		"VATAmountDC": 13.71,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e2332bf4-f443-4f28-899b-008889af0ac8')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e2332bf4-f443-4f28-899b-008889af0ac8')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 17.9,
		"AmountFC": 17.9,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Hydro Basics 250ml Body Lotion in bottle Tall Boston Round",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "ae1a8022-bb0e-488d-b7fd-b44e6f422a09",
		"InvoiceID": "e2332bf4-f443-4f28-899b-008889af0ac8",
		"Item": "10717ffd-ec4c-48da-bd28-d90d93393f9a",
		"ItemDescription": "Hydro Basics 250ml Body Lotion in bottle Tall Boston Round",
		"ItemCode": "TBBL250-100200-12",
		"LineNumber": 3,
		"NetPrice": 8.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "f320a791-0c6c-4cd3-8a2a-aa72e440c04f",
		"SalesOrderLine": "6dd68d17-c05b-4171-8224-793dee3590e8",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 5561,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.95,
		"VATAmountFC": 3.58,
		"VATAmountDC": 3.58,
		"VATCode": "54 ",
		"VATCodeDescription": "VAT Frankrijk",
		"VATPercentage": 0.2,
		"CustomField": null
		},
		{
		"AmountDC": 6.75,
		"AmountFC": 6.75,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Hydro Basics 60ml Body Lotion in bottle Fidji case quantity",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "daa76bf7-c8b1-4dda-8798-b672da68ac86",
		"InvoiceID": "e2332bf4-f443-4f28-899b-008889af0ac8",
		"Item": "62f16459-da7d-467d-9e27-c808ac332356",
		"ItemDescription": "Hydro Basics 60ml Body Lotion in bottle Fidji case quantity ",
		"ItemCode": "FIBL060-100200-16",
		"LineNumber": 1,
		"NetPrice": 2.25,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 3,
		"SalesOrder": "f320a791-0c6c-4cd3-8a2a-aa72e440c04f",
		"SalesOrderLine": "1f755f93-3646-466d-975e-e3ad05277444",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 5561,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.25,
		"VATAmountFC": 1.35,
		"VATAmountDC": 1.35,
		"VATCode": "54 ",
		"VATCodeDescription": "VAT Frankrijk",
		"VATPercentage": 0.2,
		"CustomField": null
		},
		{
		"AmountDC": 43.9,
		"AmountFC": 43.9,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Hydro Basics Bodylotion 1 Ltr.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "d7f1d947-d5d3-4458-9f85-dce6fdb48fda",
		"InvoiceID": "e2332bf4-f443-4f28-899b-008889af0ac8",
		"Item": "d82c6092-ae80-4edc-85be-c6866e7a0495",
		"ItemDescription": "Hydro Basics Bodylotion 1 Ltr.",
		"ItemCode": "NBBL001-100200",
		"LineNumber": 2,
		"NetPrice": 21.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "f320a791-0c6c-4cd3-8a2a-aa72e440c04f",
		"SalesOrderLine": "6fac1199-d239-45dc-8dc5-28dc57f657e2",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 5561,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 21.95,
		"VATAmountFC": 8.78,
		"VATAmountDC": 8.78,
		"VATCode": "54 ",
		"VATCodeDescription": "VAT Frankrijk",
		"VATPercentage": 0.2,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 74.29,
		"AmountDiscount": 0,
		"AmountDC": 74.29,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 62.42,
		"Created": "13-10-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "ce12a7a2-aec6-40a4-9f89-82655f46a01b",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "8e8e5305-5413-4c32-824e-2f40bcd9f1ab",
		"DeliverToName": "Cathy Gollinger",
		"Description": "Webshop order welness-store.myshopify.com #8825",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "25-09-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "04-09-2022",
		"InvoiceID": "17c4b185-c1ca-4d1d-b5c0-009d9d757657",
		"InvoiceTo": "ce12a7a2-aec6-40a4-9f89-82655f46a01b",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Cathy Gollinger",
		"InvoiceNumber": 22703785,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "13-10-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "04-09-2022",
		"OrderedBy": "ce12a7a2-aec6-40a4-9f89-82655f46a01b",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Cathy Gollinger",
		"OrderNumber": 4252,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 11.87,
		"VATAmountDC": 11.87,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'17c4b185-c1ca-4d1d-b5c0-009d9d757657')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'17c4b185-c1ca-4d1d-b5c0-009d9d757657')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 25.14,
		"AmountFC": 25.14,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Bodylotion Gel 400ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "271b30ff-6d67-4184-a2b5-1384f35dd012",
		"InvoiceID": "17c4b185-c1ca-4d1d-b5c0-009d9d757657",
		"Item": "9886bdbd-68f8-4db0-a9cb-dd36a0b0181c",
		"ItemDescription": "Olivia Thinks Bodylotion Gel 400ml",
		"ItemCode": "7342712",
		"LineNumber": 4,
		"NetPrice": 12.57,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "5d6ce3a1-acbf-4bb6-bd6b-24ac50980189",
		"SalesOrderLine": "40417ba5-2c44-4c98-adb1-9ba3a0af2361",
		"SalesOrderLineNumber": 4,
		"SalesOrderNumber": 4252,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 12.57,
		"VATAmountFC": 4.78,
		"VATAmountDC": 4.78,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 23.46,
		"AmountFC": 23.46,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Shower Gel 400ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "a267647c-f1fa-4a01-935b-3f9420fa0509",
		"InvoiceID": "17c4b185-c1ca-4d1d-b5c0-009d9d757657",
		"Item": "95edd339-8246-425d-a481-64de20f95c51",
		"ItemDescription": "Olivia Thinks Shower Gel 400ml",
		"ItemCode": "7342612",
		"LineNumber": 2,
		"NetPrice": 11.73,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "5d6ce3a1-acbf-4bb6-bd6b-24ac50980189",
		"SalesOrderLine": "d8ddb8fe-5a42-482e-b29b-0f07fb70f407",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 4252,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 11.73,
		"VATAmountFC": 4.46,
		"VATAmountDC": 4.46,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 11.72,
		"AmountFC": 11.72,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Shampoo 400ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "ed524d7f-017f-4e98-8a76-bfb82e704cd6",
		"InvoiceID": "17c4b185-c1ca-4d1d-b5c0-009d9d757657",
		"Item": "ed211e3e-21f7-4369-a9ad-c813d53143f1",
		"ItemDescription": "Olivia Thinks Shampoo 400ml",
		"ItemCode": "7342512",
		"LineNumber": 1,
		"NetPrice": 11.72,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "5d6ce3a1-acbf-4bb6-bd6b-24ac50980189",
		"SalesOrderLine": "f69f514a-db7c-4606-a087-e1a9bc6784de",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 4252,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 11.72,
		"VATAmountFC": 2.23,
		"VATAmountDC": 2.23,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 2.1,
		"AmountFC": 2.1,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Navidium Shipping Protection Default",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "477291e9-7a10-4202-b8a8-fa77e6459a36",
		"InvoiceID": "17c4b185-c1ca-4d1d-b5c0-009d9d757657",
		"Item": "2f76e532-8ac6-40db-aab7-83ae9e683f2e",
		"ItemDescription": "Navidium Shipping Protection Default",
		"ItemCode": "NVDPROTECTION0",
		"LineNumber": 3,
		"NetPrice": 2.1,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "5d6ce3a1-acbf-4bb6-bd6b-24ac50980189",
		"SalesOrderLine": "c5e28065-c650-4060-a3ad-91c28cd59fd6",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 4252,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.1,
		"VATAmountFC": 0.4,
		"VATAmountDC": 0.4,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 63.77,
		"AmountDiscount": 0,
		"AmountDC": 63.77,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 54.51,
		"Created": "05-02-2023",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "2fd61a63-6b26-49d7-aa01-b88304db7f5b",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "4bf41f74-e89b-4576-b6e9-e91cfebb03c5",
		"DeliverToName": "Manon Bertoldo",
		"Description": "welness-store.myshopify.com order #10125",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "26-02-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "05-02-2023",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"InvoiceTo": "2fd61a63-6b26-49d7-aa01-b88304db7f5b",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Manon Bertoldo",
		"InvoiceNumber": 23700368,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-02-2023",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "05-02-2023",
		"OrderedBy": "2fd61a63-6b26-49d7-aa01-b88304db7f5b",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Manon Bertoldo",
		"OrderNumber": 6418,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 9.26,
		"VATAmountDC": 9.26,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'15c19ed5-46bf-4cd7-b20a-00ac25adc5ec')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'15c19ed5-46bf-4cd7-b20a-00ac25adc5ec')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 1.85,
		"AmountFC": 1.85,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Hand Cleansing Gel met 70% alcohol prijs per flacon 35 ml. (",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "bc6f28e1-b446-497a-b3c2-1113a93a00ef",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"Item": "8df3cfd5-e588-42f3-a491-cf7a4239d0ec",
		"ItemDescription": "Hand Cleansing Gel met 70% alcohol prijs per flacon 35 ml. (",
		"ItemCode": "SOT035SHHCG",
		"LineNumber": 5,
		"NetPrice": 1.85,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4673ea52-328e-4821-bd68-b9aabac7cc34",
		"SalesOrderLine": "56046f83-0989-469d-996e-404d71a53a06",
		"SalesOrderLineNumber": 5,
		"SalesOrderNumber": 6418,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 1.85,
		"VATAmountFC": 0.31,
		"VATAmountDC": 0.31,
		"VATCode": "60 ",
		"VATCodeDescription": "VAT Luxemburg",
		"VATPercentage": 0.17,
		"CustomField": null
		},
		{
		"AmountDC": 6.88,
		"AmountFC": 6.88,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Navidium Shipping Protection 0.75",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "f14db3d9-1263-4c48-adea-898464a801f6",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"Item": "0b51a57f-6e18-480d-9b00-f31e854e64f0",
		"ItemDescription": "Navidium Shipping Protection 0.75",
		"ItemCode": "NVDPROTECTION1",
		"LineNumber": 1,
		"NetPrice": 3.44,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "4673ea52-328e-4821-bd68-b9aabac7cc34",
		"SalesOrderLine": "97a48e3c-feee-49a3-8868-22b73bf433d9",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 6418,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 3.44,
		"VATAmountFC": 1.17,
		"VATAmountDC": 1.17,
		"VATCode": "60 ",
		"VATCodeDescription": "VAT Luxemburg",
		"VATPercentage": 0.17,
		"CustomField": null
		},
		{
		"AmountDC": 16.64,
		"AmountFC": 16.64,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Handlotion met  Panthenol & Allantein, milde formule, Pompfl",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "40882060-568d-4689-bd90-9ae3dc7c3aed",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"Item": "f0fb2560-f9cd-44c1-a51e-41b110aa360a",
		"ItemDescription": "Handlotion met  Panthenol & Allantein, milde formule, Pompfl",
		"ItemCode": "SOT300CRHLO",
		"LineNumber": 2,
		"NetPrice": 8.32,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "4673ea52-328e-4821-bd68-b9aabac7cc34",
		"SalesOrderLine": "73231712-99af-4923-b31e-3ce37d9a598c",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 6418,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.32,
		"VATAmountFC": 2.83,
		"VATAmountDC": 2.83,
		"VATCode": "60 ",
		"VATCodeDescription": "VAT Luxemburg",
		"VATPercentage": 0.17,
		"CustomField": null
		},
		{
		"AmountDC": 9.44,
		"AmountFC": 9.44,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Pure Herbs Liquid Soap 300ml in bottle Pisa with pump prijs",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "18d2f585-c935-497d-8e6a-ce4a6eae11ad",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"Item": "c83e8e2b-f2a6-4217-8e74-547ba00bcb48",
		"ItemDescription": "Pure Herbs Liquid Soap 300ml in bottle Pisa with pump prijs ",
		"ItemCode": "PSLS300-100480-19",
		"LineNumber": 4,
		"NetPrice": 9.44,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4673ea52-328e-4821-bd68-b9aabac7cc34",
		"SalesOrderLine": "c4178295-6966-4668-a9bc-435f62784c03",
		"SalesOrderLineNumber": 4,
		"SalesOrderNumber": 6418,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 9.44,
		"VATAmountFC": 1.6,
		"VATAmountDC": 1.6,
		"VATCode": "60 ",
		"VATCodeDescription": "VAT Luxemburg",
		"VATPercentage": 0.17,
		"CustomField": null
		},
		{
		"AmountDC": 2.54,
		"AmountFC": 2.54,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Hand Cleansing  met 70% alcohol Spray 30 ml. (200 stuks per",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "38508ed0-5f5c-4657-9291-d03152e5e99a",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"Item": "facce87f-f95f-4e28-90e0-e08d7b9c8cae",
		"ItemDescription": "Hand Cleansing  met 70% alcohol Spray 30 ml. (200 stuks per ",
		"ItemCode": "SOT030BSHCS",
		"LineNumber": 6,
		"NetPrice": 2.54,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4673ea52-328e-4821-bd68-b9aabac7cc34",
		"SalesOrderLine": "968eeaf3-69f2-4798-99e7-9641eb56ccfb",
		"SalesOrderLineNumber": 6,
		"SalesOrderNumber": 6418,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.54,
		"VATAmountFC": 0.43,
		"VATAmountDC": 0.43,
		"VATCode": "60 ",
		"VATCodeDescription": "VAT Luxemburg",
		"VATPercentage": 0.17,
		"CustomField": null
		},
		{
		"AmountDC": 17.16,
		"AmountFC": 17.16,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Body & Spa Hand Cleansing Gel met 70% alcohol prijs per flac",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "1a31a1c2-866e-4a23-a3eb-d872b6891f4f",
		"InvoiceID": "15c19ed5-46bf-4cd7-b20a-00ac25adc5ec",
		"Item": "4c721d2f-4155-4294-955e-a0eccb33fadc",
		"ItemDescription": "Body & Spa Hand Cleansing Gel met 70% alcohol prijs per flac",
		"ItemCode": "SOT300CRHCG",
		"LineNumber": 3,
		"NetPrice": 8.58,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "4673ea52-328e-4821-bd68-b9aabac7cc34",
		"SalesOrderLine": "0189d827-c5fc-4849-8208-6de191489c23",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 6418,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.58,
		"VATAmountFC": 2.92,
		"VATAmountDC": 2.92,
		"VATCode": "60 ",
		"VATCodeDescription": "VAT Luxemburg",
		"VATPercentage": 0.17,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 47.8,
		"AmountDiscount": 0,
		"AmountDC": 47.8,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 40.16,
		"Created": "13-09-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "0e4cdf7a-5026-41b5-859d-1b1f8da06fae",
		"DeliverToContactPerson": "3f0a24ba-aeae-4c86-9571-448e9c3f0446",
		"DeliverToContactPersonFullName": "Danny Raap",
		"DeliverToAddress": "a73157b7-fe72-48e6-a205-b6c0583f9708",
		"DeliverToName": "Danny  Raap",
		"Description": "Webshop order welness-store.myshopify.com #8380",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "04-10-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "13-09-2022",
		"InvoiceID": "32409ec8-0815-472b-8a58-00ac3629dba3",
		"InvoiceTo": "0e4cdf7a-5026-41b5-859d-1b1f8da06fae",
		"InvoiceToContactPerson": "3f0a24ba-aeae-4c86-9571-448e9c3f0446",
		"InvoiceToContactPersonFullName": "Danny Raap",
		"InvoiceToName": "Danny  Raap",
		"InvoiceNumber": null,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "13-09-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "22-07-2022",
		"OrderedBy": "0e4cdf7a-5026-41b5-859d-1b1f8da06fae",
		"OrderedByContactPerson": "3f0a24ba-aeae-4c86-9571-448e9c3f0446",
		"OrderedByContactPersonFullName": "Danny Raap",
		"OrderedByName": "Danny  Raap",
		"OrderNumber": 3704,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "open",
		"StatusDescription": "Open",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 7.64,
		"VATAmountDC": 7.64,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'32409ec8-0815-472b-8a58-00ac3629dba3')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'32409ec8-0815-472b-8a58-00ac3629dba3')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 0,
		"StarterSalesInvoiceStatusDescription": "Concept",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 11.72,
		"AmountFC": 11.72,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Bodylotion Gel 400ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "bfb659eb-d1ef-40be-a135-054c60df50ea",
		"InvoiceID": "32409ec8-0815-472b-8a58-00ac3629dba3",
		"Item": "9886bdbd-68f8-4db0-a9cb-dd36a0b0181c",
		"ItemDescription": "Olivia Thinks Bodylotion Gel 400ml",
		"ItemCode": "7342712",
		"LineNumber": 3,
		"NetPrice": 11.72,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4ac9b477-014c-496f-ba1c-1a3355f81d4e",
		"SalesOrderLine": "0bffa050-d182-4a6b-af5b-5f921c660056",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 3704,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 11.72,
		"VATAmountFC": 2.23,
		"VATAmountDC": 2.23,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 6.68,
		"AmountFC": 6.68,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipping",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "c8bae63a-f9db-4d5a-8eb2-13cc65182cc6",
		"InvoiceID": "32409ec8-0815-472b-8a58-00ac3629dba3",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 4,
		"NetPrice": 6.68,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4ac9b477-014c-496f-ba1c-1a3355f81d4e",
		"SalesOrderLine": "3d1caac9-9cc0-47eb-9331-12862ddc17f5",
		"SalesOrderLineNumber": 4,
		"SalesOrderNumber": 3704,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 6.68,
		"VATAmountFC": 1.27,
		"VATAmountDC": 1.27,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 10.88,
		"AmountFC": 10.88,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Shampoo 400ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "addfaf6c-648e-43de-9dbb-2b9c4bfe6db7",
		"InvoiceID": "32409ec8-0815-472b-8a58-00ac3629dba3",
		"Item": "ed211e3e-21f7-4369-a9ad-c813d53143f1",
		"ItemDescription": "Olivia Thinks Shampoo 400ml",
		"ItemCode": "7342512",
		"LineNumber": 1,
		"NetPrice": 10.88,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4ac9b477-014c-496f-ba1c-1a3355f81d4e",
		"SalesOrderLine": "f9d7c31b-c04c-44e1-8c49-30788f52a15c",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 3704,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 10.88,
		"VATAmountFC": 2.07,
		"VATAmountDC": 2.07,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 10.88,
		"AmountFC": 10.88,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Shower Gel 400ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "29aadb27-0e42-44ef-8869-9cbe6cf430cd",
		"InvoiceID": "32409ec8-0815-472b-8a58-00ac3629dba3",
		"Item": "95edd339-8246-425d-a481-64de20f95c51",
		"ItemDescription": "Olivia Thinks Shower Gel 400ml",
		"ItemCode": "7342612",
		"LineNumber": 2,
		"NetPrice": 10.88,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "4ac9b477-014c-496f-ba1c-1a3355f81d4e",
		"SalesOrderLine": "5828b3fb-c0d9-4419-9a37-573ff2e8c1a3",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 3704,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 10.88,
		"VATAmountFC": 2.07,
		"VATAmountDC": 2.07,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 62.8,
		"AmountDiscount": 0,
		"AmountDC": 62.8,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 51.9,
		"Created": "21-01-2023",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "12ac1584-4ce6-43c6-adfd-96ed360b8550",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "f6334bd6-a8b1-46c8-aafe-6f21af64af89",
		"DeliverToName": "Lizette Geertruida Deul-van Drongelen",
		"Description": "tafelaankleding.myshopify.com order #3542",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "11-02-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "21-01-2023",
		"InvoiceID": "682031d8-36b9-4942-8b2b-00b643169b60",
		"InvoiceTo": "12ac1584-4ce6-43c6-adfd-96ed360b8550",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Lizette Geertruida Deul-van Drongelen",
		"InvoiceNumber": 23700181,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "21-01-2023",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "21-01-2023",
		"OrderedBy": "12ac1584-4ce6-43c6-adfd-96ed360b8550",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Lizette Geertruida Deul-van Drongelen",
		"OrderNumber": 6222,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.9,
		"VATAmountDC": 10.9,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'682031d8-36b9-4942-8b2b-00b643169b60')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'682031d8-36b9-4942-8b2b-00b643169b60')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 44.95,
		"AmountFC": 44.95,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Q-Lights, Special, brandduur 8 uur, doos a 400 stuks.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "2fce6206-5adf-4a89-b226-fa8b9d33929c",
		"GLAccountDescription": "Opbrengst kaarsen 21%",
		"ID": "80276fc0-1651-46a9-ae71-2208d1d8d716",
		"InvoiceID": "682031d8-36b9-4942-8b2b-00b643169b60",
		"Item": "221c812b-3d32-4fc0-947b-53c1e29497aa",
		"ItemDescription": "Q-Lights, Special, brandduur 8 uur, doos a 400 stuks.",
		"ItemCode": "103-50-00",
		"LineNumber": 1,
		"NetPrice": 44.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "a09c73fd-bfb8-4d94-ae5a-273b00079fd3",
		"SalesOrderLine": "0cdb513d-65b6-4bd7-a9a3-1c6a834570e3",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 6222,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "doos",
		"UnitDescription": "Karton",
		"UnitPrice": 44.95,
		"VATAmountFC": 9.44,
		"VATAmountDC": 9.44,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 6.95,
		"AmountFC": 6.95,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipping",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "521226bc-18e3-439f-ac4b-d830fd6dab62",
		"InvoiceID": "682031d8-36b9-4942-8b2b-00b643169b60",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 2,
		"NetPrice": 6.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "a09c73fd-bfb8-4d94-ae5a-273b00079fd3",
		"SalesOrderLine": "7dee43cd-cae2-4898-915d-b860a564facd",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 6222,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 6.95,
		"VATAmountFC": 1.46,
		"VATAmountDC": 1.46,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 68.2,
		"AmountDiscount": 0,
		"AmountDC": 68.2,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 68.2,
		"Created": "26-10-2022",
		"Creator": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"CreatorFullName": "WEA Exact Online",
		"Currency": "EUR",
		"DeliverTo": "a966cd4a-ac57-40d3-87bc-86a19eaddfe9",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "a003e92f-bab3-4bab-8440-78cf235e3dd2",
		"DeliverToName": "Rupert Spice",
		"Description": "#9062",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "27-09-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "27-09-2022",
		"InvoiceID": "13cce0d4-9b92-4f7e-aab3-00d0b05623bf",
		"InvoiceTo": "a966cd4a-ac57-40d3-87bc-86a19eaddfe9",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Rupert Spice",
		"InvoiceNumber": 22704040,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "26-10-2022",
		"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"ModifierFullName": "WEA Exact Online",
		"OrderDate": "27-09-2022",
		"OrderedBy": "a966cd4a-ac57-40d3-87bc-86a19eaddfe9",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Rupert Spice",
		"OrderNumber": 4585,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"SalespersonFullName": "WEA Exact Online",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'13cce0d4-9b92-4f7e-aab3-00d0b05623bf')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'13cce0d4-9b92-4f7e-aab3-00d0b05623bf')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 7.9,
		"AmountFC": 7.9,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Bodylotion 70ml",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "38472a81-2f90-4b39-a636-1bb4c9a2b9b8",
		"InvoiceID": "13cce0d4-9b92-4f7e-aab3-00d0b05623bf",
		"Item": "bbc643d7-02d5-4cd9-b39d-241139c59a1d",
		"ItemDescription": "Olivia Thinks Bodylotion 70ml",
		"ItemCode": "6301330",
		"LineNumber": 1,
		"NetPrice": 3.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "9722595e-7bfb-47b6-b9b4-1e73f30ba6ff",
		"SalesOrderLine": "658cb8be-4eea-4157-884e-9d86549465e7",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 4585,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 3.95,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "6  ",
		"VATCodeDescription": "Verkopen buiten de EU",
		"VATPercentage": 0,
		"CustomField": null
		},
		{
		"AmountDC": 29.9,
		"AmountFC": 29.9,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Bodylotion Gel 400ml",
		"Discount": -0.0716845878,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "7c8c9917-a1e8-4407-9838-46e864848702",
		"InvoiceID": "13cce0d4-9b92-4f7e-aab3-00d0b05623bf",
		"Item": "9886bdbd-68f8-4db0-a9cb-dd36a0b0181c",
		"ItemDescription": "Olivia Thinks Bodylotion Gel 400ml",
		"ItemCode": "7342712",
		"LineNumber": 3,
		"NetPrice": 14.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "9722595e-7bfb-47b6-b9b4-1e73f30ba6ff",
		"SalesOrderLine": "d1eb3a9f-5b1a-41db-9c59-99ced07c9c45",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 4585,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 13.95,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "6  ",
		"VATCodeDescription": "Verkopen buiten de EU",
		"VATPercentage": 0,
		"CustomField": null
		},
		{
		"AmountDC": 2.5,
		"AmountFC": 2.5,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Navidium Shipping Protection Default",
		"Discount": -0.213592233,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "fee326a1-c05e-44e0-bad1-949b4e5acc9e",
		"InvoiceID": "13cce0d4-9b92-4f7e-aab3-00d0b05623bf",
		"Item": "2f76e532-8ac6-40db-aab7-83ae9e683f2e",
		"ItemDescription": "Navidium Shipping Protection Default",
		"ItemCode": "NVDPROTECTION0",
		"LineNumber": 4,
		"NetPrice": 2.5,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "9722595e-7bfb-47b6-b9b4-1e73f30ba6ff",
		"SalesOrderLine": "20331337-4fa0-4972-8a74-52db02d917cf",
		"SalesOrderLineNumber": 4,
		"SalesOrderNumber": 4585,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 2.06,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "6  ",
		"VATCodeDescription": "Verkopen buiten de EU",
		"VATPercentage": 0,
		"CustomField": null
		},
		{
		"AmountDC": 27.9,
		"AmountFC": 27.9,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Olivia Thinks Shower Gel 400ml",
		"Discount": -0.0772200772,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "2580af82-87a4-4ffe-9122-fd38a58d3eee",
		"InvoiceID": "13cce0d4-9b92-4f7e-aab3-00d0b05623bf",
		"Item": "95edd339-8246-425d-a481-64de20f95c51",
		"ItemDescription": "Olivia Thinks Shower Gel 400ml",
		"ItemCode": "7342612",
		"LineNumber": 2,
		"NetPrice": 13.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "9722595e-7bfb-47b6-b9b4-1e73f30ba6ff",
		"SalesOrderLine": "8b038e9d-42e5-4aa2-9524-abb2207aa335",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 4585,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 12.95,
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"VATCode": "6  ",
		"VATCodeDescription": "Verkopen buiten de EU",
		"VATPercentage": 0,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 29.91,
		"AmountDiscount": 0,
		"AmountDC": 29.91,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 25.13,
		"Created": "13-06-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "e948a6b9-35d6-47df-8c6c-c9ad8742c5b4",
		"DeliverToContactPerson": "1b851e7e-fe0a-473c-90eb-d45439ca1201",
		"DeliverToContactPersonFullName": "Hertha Zimmermann",
		"DeliverToAddress": "36706a05-a749-4fb7-9514-39ebc21aa96d",
		"DeliverToName": "Hertha Zimmermann",
		"Description": "#7933",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "13-06-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "13-06-2022",
		"InvoiceID": "31330da2-f0b6-4c50-afd3-00f1dc9adf9f",
		"InvoiceTo": "e948a6b9-35d6-47df-8c6c-c9ad8742c5b4",
		"InvoiceToContactPerson": "1b851e7e-fe0a-473c-90eb-d45439ca1201",
		"InvoiceToContactPersonFullName": "Hertha Zimmermann",
		"InvoiceToName": "Hertha Zimmermann",
		"InvoiceNumber": 22702137,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "13-06-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "13-06-2022",
		"OrderedBy": "e948a6b9-35d6-47df-8c6c-c9ad8742c5b4",
		"OrderedByContactPerson": "1b851e7e-fe0a-473c-90eb-d45439ca1201",
		"OrderedByContactPersonFullName": "Hertha Zimmermann",
		"OrderedByName": "Hertha Zimmermann",
		"OrderNumber": 2298,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7933",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 4.78,
		"VATAmountDC": 4.78,
		"YourRef": "#7933",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'31330da2-f0b6-4c50-afd3-00f1dc9adf9f')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'31330da2-f0b6-4c50-afd3-00f1dc9adf9f')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 6.68,
		"AmountFC": 6.68,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipment",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "8b81e6dc-0767-497f-907d-6150c054443b",
		"InvoiceID": "31330da2-f0b6-4c50-afd3-00f1dc9adf9f",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 2,
		"NetPrice": 6.68,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "5e1f013d-045d-40b7-ba57-cc7b3f7f2c25",
		"SalesOrderLine": "bd973265-b7a2-4301-88da-6a5547ed8e17",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 2298,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 0,
		"VATAmountFC": 1.27,
		"VATAmountDC": 1.27,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		},
		{
		"AmountDC": 18.45,
		"AmountFC": 18.45,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Body & Spa Shop Pure Herbs Hand & Bodylotion 1 Ltr.",
		"Discount": 0.0314960629921261,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "664f749e-e8cc-44a7-b636-ad44b5ee73b8",
		"GLAccountDescription": "Opbrengst Wellness & Amenities Producten 21%",
		"ID": "3541da41-d671-46dd-9951-c812071c14dc",
		"InvoiceID": "31330da2-f0b6-4c50-afd3-00f1dc9adf9f",
		"Item": "a87cdd88-bb9e-4c68-81d5-93788f9ec400",
		"ItemDescription": "Pure Herbs Hand & Body Lotion 1 Ltr.",
		"ItemCode": "NBHB001-100480",
		"LineNumber": 1,
		"NetPrice": 18.45,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "5e1f013d-045d-40b7-ba57-cc7b3f7f2c25",
		"SalesOrderLine": "a847beb7-7994-4a9a-8bac-69d27cbc2573",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 2298,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 19.05,
		"VATAmountFC": 3.51,
		"VATAmountDC": 3.51,
		"VATCode": "52 ",
		"VATCodeDescription": "VAT Duitsland",
		"VATPercentage": 0.19,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 229.42,
		"AmountDiscount": 0,
		"AmountDC": 229.42,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 189.6,
		"Created": "22-12-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "e2861242-d5e7-43b2-a50e-4abc1d222a7b",
		"DeliverToContactPerson": "bce81cdc-fc74-4bca-b1da-49ef314c47fb",
		"DeliverToContactPersonFullName": "Bram-Pieter Poppe",
		"DeliverToAddress": "b18b0de8-f937-488a-9ab0-1a092768fd0b",
		"DeliverToName": "t Waepen van Veere",
		"Description": "Digitale Afleverbon",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "29-12-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "22-12-2022",
		"InvoiceID": "8be62fb7-3010-4a45-80b9-00f87e75d8d2",
		"InvoiceTo": "e2861242-d5e7-43b2-a50e-4abc1d222a7b",
		"InvoiceToContactPerson": "bce81cdc-fc74-4bca-b1da-49ef314c47fb",
		"InvoiceToContactPersonFullName": "Bram-Pieter Poppe",
		"InvoiceToName": "t Waepen van Veere",
		"InvoiceNumber": 22705145,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "22-12-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "22-12-2022",
		"OrderedBy": "e2861242-d5e7-43b2-a50e-4abc1d222a7b",
		"OrderedByContactPerson": "bce81cdc-fc74-4bca-b1da-49ef314c47fb",
		"OrderedByContactPersonFullName": "Bram-Pieter Poppe",
		"OrderedByName": "t Waepen van Veere",
		"OrderNumber": 5750,
		"PaymentCondition": "11",
		"PaymentConditionDescription": "7 Dagen Incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 39.82,
		"VATAmountDC": 39.82,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'8be62fb7-3010-4a45-80b9-00f87e75d8d2')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'8be62fb7-3010-4a45-80b9-00f87e75d8d2')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 14.64,
		"AmountFC": 14.64,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Luchtverfrisservulling 100 ML, geur Cool Prijs per stuk",
		"Discount": 0.3,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "b78cf4cf-0abf-4b01-bbd6-4b031bda4f9a",
		"GLAccountDescription": "Opbrengst Luchtverfrisser, 21%",
		"ID": "9ba7516a-b615-49fb-9b53-1b99d239ac59",
		"InvoiceID": "8be62fb7-3010-4a45-80b9-00f87e75d8d2",
		"Item": "eeb7bd83-ffe0-4420-a1ae-e495dd0bddb8",
		"ItemDescription": "Luchtverfrisservulling 100 ML, geur Cool Prijs per stuk",
		"ItemCode": "BAERO-01",
		"LineNumber": 3,
		"NetPrice": 7.32,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "e6ed9924-c59c-4084-af84-da631188dde2",
		"SalesOrderLine": "338c41d5-6887-4da3-b4b6-e3729d37ec2b",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 5750,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 10.45,
		"VATAmountFC": 3.07,
		"VATAmountDC": 3.07,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 138.6,
		"AmountFC": 138.6,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Maxi Jumbo Eco rol 380 , recycled wit, verpakt per 6 stuks",
		"Discount": 0.3,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "39ac2cb6-5137-4490-b867-6c5ecc629b49",
		"GLAccountDescription": "Opbrengst Maxi-, Mini Toiletrollen 21%",
		"ID": "1739bc94-cb55-4bef-a400-88e65597a5c6",
		"InvoiceID": "8be62fb7-3010-4a45-80b9-00f87e75d8d2",
		"Item": "d1edd5d9-e40e-4900-bb67-69124c77fc6d",
		"ItemDescription": "Maxi Jumbo Eco rol 380 , recycled wit, verpakt per 6 stuks",
		"ItemCode": "LA380-6",
		"LineNumber": 2,
		"NetPrice": 34.65,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 4,
		"SalesOrder": "e6ed9924-c59c-4084-af84-da631188dde2",
		"SalesOrderLine": "42d3cef5-a74d-46f6-be27-a6779ec063de",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 5750,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 49.5,
		"VATAmountFC": 29.11,
		"VATAmountDC": 29.11,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 36.36,
		"AmountFC": 36.36,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Zeepschuim voor cos 1000ml. per fles (6 in doos)",
		"Discount": 0.3,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "b91ee64e-079d-4bc9-a8e1-35364a110ac2",
		"GLAccountDescription": "Opbrengst Zeep 21%",
		"ID": "e5ffa22d-7329-4cbc-8a58-c8c7cb5c5f07",
		"InvoiceID": "8be62fb7-3010-4a45-80b9-00f87e75d8d2",
		"Item": "b915b2ef-78c3-4ccc-9499-534a4ac9b557",
		"ItemDescription": "Zeepschuim voor cos 1000ml. per fles (6 in doos)",
		"ItemCode": "FS1000",
		"LineNumber": 1,
		"NetPrice": 6.06,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 6,
		"SalesOrder": "e6ed9924-c59c-4084-af84-da631188dde2",
		"SalesOrderLine": "e4933bcc-19a0-4063-9841-51a801200c60",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 5750,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 8.66,
		"VATAmountFC": 7.64,
		"VATAmountDC": 7.64,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 1236.4,
		"AmountDiscount": 0,
		"AmountDC": 1236.4,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 1021.82,
		"Created": "15-02-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "82f56be8-41ea-4342-9922-feee3a5da4a7",
		"DeliverToContactPerson": "8d754565-ac4c-4d78-8a33-b4f119082129",
		"DeliverToContactPersonFullName": "Floris Kleinleugemors",
		"DeliverToAddress": "c8b84fab-5c75-417b-9e0b-6d2feaf5e568",
		"DeliverToName": "Verwater SJR Tank Construction B.V.",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "01-03-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "15-02-2022",
		"InvoiceID": "9e706921-548f-4de8-bed8-00f8fda7f28b",
		"InvoiceTo": "82f56be8-41ea-4342-9922-feee3a5da4a7",
		"InvoiceToContactPerson": "8d754565-ac4c-4d78-8a33-b4f119082129",
		"InvoiceToContactPersonFullName": "Floris Kleinleugemors",
		"InvoiceToName": "Verwater SJR Tank Construction B.V.",
		"InvoiceNumber": 22700419,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "15-02-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "14-02-2022",
		"OrderedBy": "82f56be8-41ea-4342-9922-feee3a5da4a7",
		"OrderedByContactPerson": "8d754565-ac4c-4d78-8a33-b4f119082129",
		"OrderedByContactPersonFullName": "Floris Kleinleugemors",
		"OrderedByName": "Verwater SJR Tank Construction B.V.",
		"OrderNumber": 440,
		"PaymentCondition": "14",
		"PaymentConditionDescription": "doorlopend standaard incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 214.58,
		"VATAmountDC": 214.58,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'9e706921-548f-4de8-bed8-00f8fda7f28b')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'9e706921-548f-4de8-bed8-00f8fda7f28b')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 285.76,
		"AmountFC": 285.76,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Handdoekrollen, 2 lgs, cellulose, biologisch afbreekbaar, ve",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "a8ab015a-9958-4aea-a545-0af4e7dccd0f",
		"GLAccountDescription": "Opbrengst Handdoekrollen 21%",
		"ID": "f006091d-f591-4fef-a8be-2708a7bcc0d0",
		"InvoiceID": "9e706921-548f-4de8-bed8-00f8fda7f28b",
		"Item": "6529feb8-cfec-4886-8b77-5cfe8df3d85f",
		"ItemDescription": "Handdoekrollen, 2 lgs, cellulose, biologisch afbreekbaar, ve",
		"ItemCode": "Lanza140",
		"LineNumber": 2,
		"NetPrice": 71.44,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 4,
		"SalesOrder": "3f5dcf1e-c8a9-4af3-9e16-eb3fab2829c3",
		"SalesOrderLine": "48478d5c-708f-492d-bc1e-4146dcee2128",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 440,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 71.44,
		"VATAmountFC": 60.01,
		"VATAmountDC": 60.01,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 540,
		"AmountFC": 540,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Poetsrollen (groot) 26cm hoog, 100% biologisch afbreekbaar,",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "1c21da86-5dc3-43bb-b384-14d1f33096e2",
		"GLAccountDescription": "Opbrengst Poetsrollen 21%",
		"ID": "e34b281d-7f97-4eb8-8ea8-779aef3da745",
		"InvoiceID": "9e706921-548f-4de8-bed8-00f8fda7f28b",
		"Item": "31656de6-37e9-4792-a687-c5a1ce838d9c",
		"ItemDescription": "Poetsrollen (groot) 26cm hoog, 100% biologisch afbreekbaar, ",
		"ItemCode": "LA26-2",
		"LineNumber": 1,
		"NetPrice": 45,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 12,
		"SalesOrder": "3f5dcf1e-c8a9-4af3-9e16-eb3fab2829c3",
		"SalesOrderLine": "43aa3127-9c6a-4454-b635-a59eb66cb46d",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 440,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 45,
		"VATAmountFC": 113.4,
		"VATAmountDC": 113.4,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 196.06,
		"AmountFC": 196.06,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Toiletpapier Lanza 9,8 x 140,cellulose 2 laags , doos a 32 r",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "39ac2cb6-5137-4490-b867-6c5ecc629b49",
		"GLAccountDescription": "Opbrengst Maxi-, Mini Toiletrollen 21%",
		"ID": "0f2f19f2-fa1c-4882-939d-afff56a913e3",
		"InvoiceID": "9e706921-548f-4de8-bed8-00f8fda7f28b",
		"Item": "84aa63aa-9183-4366-b4e6-08de86aa8584",
		"ItemDescription": "Toiletpapier Lanza 9,8 x 140,cellulose 2 laags , doos a 32 r",
		"ItemCode": "TP140",
		"LineNumber": 3,
		"NetPrice": 98.03,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "3f5dcf1e-c8a9-4af3-9e16-eb3fab2829c3",
		"SalesOrderLine": "c8d9d6c2-9b98-4fff-8d13-b3d1c2e41725",
		"SalesOrderLineNumber": 3,
		"SalesOrderNumber": 440,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 98.03,
		"VATAmountFC": 41.17,
		"VATAmountDC": 41.17,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 39.14,
		"AmountDiscount": 0,
		"AmountDC": 39.14,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 32.35,
		"Created": "19-01-2023",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "20707e17-ddaf-4574-a3e8-7d0768ff0bd0",
		"DeliverToContactPerson": "0c35f9c1-f5a3-4de9-89ef-ff0409261c79",
		"DeliverToContactPersonFullName": "thomas Velcicky",
		"DeliverToAddress": "266c8364-8c3e-475b-bf64-c3951d3cd3fe",
		"DeliverToName": "Meneer",
		"Description": "Webshop order tafelaankleding.myshopify.com #3202",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "20-10-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "29-09-2022",
		"InvoiceID": "cae4d2cd-c976-4903-86a0-0107531b86f3",
		"InvoiceTo": "20707e17-ddaf-4574-a3e8-7d0768ff0bd0",
		"InvoiceToContactPerson": "0c35f9c1-f5a3-4de9-89ef-ff0409261c79",
		"InvoiceToContactPersonFullName": "thomas Velcicky",
		"InvoiceToName": "Meneer",
		"InvoiceNumber": 22705408,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "19-01-2023",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "29-09-2022",
		"OrderedBy": "20707e17-ddaf-4574-a3e8-7d0768ff0bd0",
		"OrderedByContactPerson": "0c35f9c1-f5a3-4de9-89ef-ff0409261c79",
		"OrderedByContactPersonFullName": "thomas Velcicky",
		"OrderedByName": "Meneer",
		"OrderNumber": 6193,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 6.79,
		"VATAmountDC": 6.79,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'cae4d2cd-c976-4903-86a0-0107531b86f3')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'cae4d2cd-c976-4903-86a0-0107531b86f3')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 6.95,
		"AmountFC": 6.95,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Shipping",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "3587913b-6bfd-4ef9-b4dd-ade62c30d499",
		"GLAccountDescription": "Verzendkosten binnen Ned, 21% Btw",
		"ID": "3783e245-67f7-4584-ae97-ac35cb447d39",
		"InvoiceID": "cae4d2cd-c976-4903-86a0-0107531b86f3",
		"Item": "7cbc971c-19df-426e-af48-70fb859af74a",
		"ItemDescription": "Shipment",
		"ItemCode": "Shipment",
		"LineNumber": 2,
		"NetPrice": 6.95,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "27fe802a-02e7-48e8-8229-0186ac57d253",
		"SalesOrderLine": "cd7ea2d3-2ab8-454c-aede-2fbd0aa5e4cc",
		"SalesOrderLineNumber": 2,
		"SalesOrderNumber": 6193,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "pc",
		"UnitDescription": "Piece",
		"UnitPrice": 6.95,
		"VATAmountFC": 1.46,
		"VATAmountDC": 1.46,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		},
		{
		"AmountDC": 25.4,
		"AmountFC": 25.4,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Q-Lights, Maxi, brandduur 10 uur,40 stuks in doos.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "2fce6206-5adf-4a89-b226-fa8b9d33929c",
		"GLAccountDescription": "Opbrengst kaarsen 21%",
		"ID": "c041a1bd-5e9c-4d2b-af30-cd4071866d46",
		"InvoiceID": "cae4d2cd-c976-4903-86a0-0107531b86f3",
		"Item": "5d072ef2-9d7a-43a9-8dc1-7e7f796160d8",
		"ItemDescription": "Q-Lights, Maxi, brandduur 10 uur,40 stuks in doos.",
		"ItemCode": "900-10-01",
		"LineNumber": 1,
		"NetPrice": 12.7,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 2,
		"SalesOrder": "27fe802a-02e7-48e8-8229-0186ac57d253",
		"SalesOrderLine": "dbaedd8b-979a-41f3-90f9-f5de7f8aed17",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 6193,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "doos",
		"UnitDescription": "Karton",
		"UnitPrice": 12.7,
		"VATAmountFC": 5.33,
		"VATAmountDC": 5.33,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 64.95,
		"AmountDiscount": 0,
		"AmountDC": 64.95,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 53.68,
		"Created": "07-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "ddee7a71-9841-46a0-af69-a4c211f5cb73",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "7179ba82-e795-43ba-b70f-57e0a7cbbc56",
		"DeliverToName": "Joy Jolliffe",
		"Description": "tafelaankleding.myshopify.com order BOL-1532113965",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "28-12-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "07-12-2022",
		"InvoiceID": "67d68d91-9bb3-4cc1-a93a-0114c8932064",
		"InvoiceTo": "ddee7a71-9841-46a0-af69-a4c211f5cb73",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Joy Jolliffe",
		"InvoiceNumber": 22704970,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "07-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "07-12-2022",
		"OrderedBy": "ddee7a71-9841-46a0-af69-a4c211f5cb73",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Joy Jolliffe",
		"OrderNumber": 5562,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 11.27,
		"VATAmountDC": 11.27,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'67d68d91-9bb3-4cc1-a93a-0114c8932064')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'67d68d91-9bb3-4cc1-a93a-0114c8932064')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": [
		{
		"AmountDC": 53.68,
		"AmountFC": 53.68,
		"CostCenter": null,
		"CostCenterDescription": null,
		"CostUnit": null,
		"CostUnitDescription": null,
		"CustomerItemCode": null,
		"DeliveryDate": "",
		"Description": "Q-Lights, Special, brandduur 8 uur, doos a 400 stuks.",
		"Discount": 0,
		"Division": 3120592,
		"Employee": null,
		"EmployeeFullName": null,
		"EndTime": "",
		"ExtraDutyAmountFC": null,
		"ExtraDutyPercentage": null,
		"GLAccount": "2fce6206-5adf-4a89-b226-fa8b9d33929c",
		"GLAccountDescription": "Opbrengst kaarsen 21%",
		"ID": "40d0ba77-38aa-49a8-8b56-b530cbe0b583",
		"InvoiceID": "67d68d91-9bb3-4cc1-a93a-0114c8932064",
		"Item": "221c812b-3d32-4fc0-947b-53c1e29497aa",
		"ItemDescription": "Q-Lights, Special, brandduur 8 uur, doos a 400 stuks.",
		"ItemCode": "103-50-00",
		"LineNumber": 1,
		"NetPrice": 53.68,
		"Notes": null,
		"Pricelist": null,
		"PricelistDescription": null,
		"Project": null,
		"ProjectDescription": null,
		"ProjectWBS": null,
		"ProjectWBSDescription": null,
		"Quantity": 1,
		"SalesOrder": "3a893890-0683-4ca4-9137-82d3d45fa201",
		"SalesOrderLine": "cbcd1dfc-8894-4df5-9017-d09549f07533",
		"SalesOrderLineNumber": 1,
		"SalesOrderNumber": 5562,
		"StartTime": null,
		"Subscription": null,
		"SubscriptionDescription": null,
		"UnitCode": "doos",
		"UnitDescription": "Karton",
		"UnitPrice": 53.68,
		"VATAmountFC": 11.27,
		"VATAmountDC": 11.27,
		"VATCode": "2  ",
		"VATCodeDescription": "21% BTW",
		"VATPercentage": 0.21,
		"CustomField": null
		}
	]
	},
	{
	"sales_invoice": {
		"AmountFC": 27.89,
		"AmountDiscount": 0,
		"AmountDC": 27.89,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 22.31,
		"Created": "19-07-2023",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "a9392b43-8f68-47c8-8e53-75bd86f119c2",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "d4d6fc7b-72fc-495d-bfad-b2a2e2bfbbb1",
		"DeliverToName": "Kjell Ellingsen",
		"Description": "welness-store.myshopify.com order #9917",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "24-01-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "03-01-2023",
		"InvoiceID": "e4b76272-7ff9-4cab-8de2-012713ddd097",
		"InvoiceTo": "a9392b43-8f68-47c8-8e53-75bd86f119c2",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Kjell Ellingsen",
		"InvoiceNumber": 23700005,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "03-01-2023",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "03-01-2023",
		"OrderedBy": "a9392b43-8f68-47c8-8e53-75bd86f119c2",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Kjell Ellingsen",
		"OrderNumber": 5870,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 5.58,
		"VATAmountDC": 5.58,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e4b76272-7ff9-4cab-8de2-012713ddd097')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e4b76272-7ff9-4cab-8de2-012713ddd097')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 81.65,
		"AmountDiscount": 0,
		"AmountDC": 81.65,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 81.65,
		"Created": "31-05-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "640b1d20-4dbc-4638-a32e-734558192cfc",
		"DeliverToContactPerson": "ee8fc745-dc59-47b4-ba0b-ca611c56b78d",
		"DeliverToContactPersonFullName": "ANN JEFFRIES",
		"DeliverToAddress": "876b583b-a2b0-43f2-9e24-a342df259953",
		"DeliverToName": "ANN JEFFRIES",
		"Description": "#7791",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "31-05-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "31-05-2022",
		"InvoiceID": "2cfee04e-6736-4ede-8508-0129e2b3618e",
		"InvoiceTo": "640b1d20-4dbc-4638-a32e-734558192cfc",
		"InvoiceToContactPerson": "ee8fc745-dc59-47b4-ba0b-ca611c56b78d",
		"InvoiceToContactPersonFullName": "ANN JEFFRIES",
		"InvoiceToName": "ANN JEFFRIES",
		"InvoiceNumber": 22701928,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "31-05-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "31-05-2022",
		"OrderedBy": "640b1d20-4dbc-4638-a32e-734558192cfc",
		"OrderedByContactPerson": "ee8fc745-dc59-47b4-ba0b-ca611c56b78d",
		"OrderedByContactPersonFullName": "ANN JEFFRIES",
		"OrderedByName": "ANN JEFFRIES",
		"OrderNumber": 2077,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7791",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"YourRef": "#7791",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2cfee04e-6736-4ede-8508-0129e2b3618e')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2cfee04e-6736-4ede-8508-0129e2b3618e')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 89.49,
		"AmountDiscount": 0,
		"AmountDC": 89.49,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 75.2,
		"Created": "28-05-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "0a2da6ed-78b9-4639-a629-110112f846b6",
		"DeliverToContactPerson": "22b3d75b-040e-4653-b896-817a025772c7",
		"DeliverToContactPersonFullName": "Galina Hecht",
		"DeliverToAddress": "c4221ad6-d6a7-4270-9b96-5c4e2dd00af6",
		"DeliverToName": "Galina Hecht",
		"Description": "#7743",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "28-05-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "28-05-2022",
		"InvoiceID": "6e5fe484-f795-47b2-af43-01377d8520cb",
		"InvoiceTo": "0a2da6ed-78b9-4639-a629-110112f846b6",
		"InvoiceToContactPerson": "22b3d75b-040e-4653-b896-817a025772c7",
		"InvoiceToContactPersonFullName": "Galina Hecht",
		"InvoiceToName": "Galina Hecht",
		"InvoiceNumber": 22701868,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "28-05-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "28-05-2022",
		"OrderedBy": "0a2da6ed-78b9-4639-a629-110112f846b6",
		"OrderedByContactPerson": "22b3d75b-040e-4653-b896-817a025772c7",
		"OrderedByContactPersonFullName": "Galina Hecht",
		"OrderedByName": "Galina Hecht",
		"OrderNumber": 2006,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7743",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 14.29,
		"VATAmountDC": 14.29,
		"YourRef": "#7743",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'6e5fe484-f795-47b2-af43-01377d8520cb')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'6e5fe484-f795-47b2-af43-01377d8520cb')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 55.89,
		"AmountDiscount": 0,
		"AmountDC": 55.89,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 46.19,
		"Created": "05-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "3516abdc-3527-4b34-bb1c-2005feaa2fec",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "fc9c32c3-39bb-4164-b978-f49619d266cd",
		"DeliverToName": "Maria Lokhorst",
		"Description": "Webshop order welness-store.myshopify.com #9139",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "24-10-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "03-10-2022",
		"InvoiceID": "ebc5683e-3098-4cb4-b42a-013cfa260388",
		"InvoiceTo": "3516abdc-3527-4b34-bb1c-2005feaa2fec",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Maria Lokhorst",
		"InvoiceNumber": 22704678,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "03-10-2022",
		"OrderedBy": "3516abdc-3527-4b34-bb1c-2005feaa2fec",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Maria Lokhorst",
		"OrderNumber": 5267,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 9.7,
		"VATAmountDC": 9.7,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'ebc5683e-3098-4cb4-b42a-013cfa260388')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'ebc5683e-3098-4cb4-b42a-013cfa260388')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 40.88,
		"AmountDiscount": 0,
		"AmountDC": 40.88,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 34.35,
		"Created": "14-11-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "d68c64ac-077a-4b74-866b-fccdf9114b6d",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "96b72322-0b01-4aa5-99f1-76bfb61fded3",
		"DeliverToName": "Jörn Renzmann",
		"Description": "welness-store.myshopify.com order #9533",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "05-12-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "14-11-2022",
		"InvoiceID": "24c772da-996f-4347-9319-013d522bdfcc",
		"InvoiceTo": "d68c64ac-077a-4b74-866b-fccdf9114b6d",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Jörn Renzmann",
		"InvoiceNumber": 22704288,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "14-11-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "14-11-2022",
		"OrderedBy": "d68c64ac-077a-4b74-866b-fccdf9114b6d",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Jörn Renzmann",
		"OrderNumber": 4843,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 6.53,
		"VATAmountDC": 6.53,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'24c772da-996f-4347-9319-013d522bdfcc')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'24c772da-996f-4347-9319-013d522bdfcc')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 41.16,
		"AmountDiscount": 0,
		"AmountDC": 41.16,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 34.02,
		"Created": "08-06-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "30ebde1a-e225-47e2-8ff5-abc3eae3f288",
		"DeliverToContactPerson": "56909331-c9b8-4af0-8312-9c26b71a8171",
		"DeliverToContactPersonFullName": "Martijn Loomans",
		"DeliverToAddress": "d3922586-afd4-40bb-932c-12791cd792ba",
		"DeliverToName": "Grand caf restaurant Loos BV",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "22-06-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "08-06-2022",
		"InvoiceID": "ac5781fe-5bb0-401e-91d4-014781333304",
		"InvoiceTo": "30ebde1a-e225-47e2-8ff5-abc3eae3f288",
		"InvoiceToContactPerson": "56909331-c9b8-4af0-8312-9c26b71a8171",
		"InvoiceToContactPersonFullName": "Martijn Loomans",
		"InvoiceToName": "Grand caf restaurant Loos BV",
		"InvoiceNumber": 22702070,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "08-06-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "08-06-2022",
		"OrderedBy": "30ebde1a-e225-47e2-8ff5-abc3eae3f288",
		"OrderedByContactPerson": "56909331-c9b8-4af0-8312-9c26b71a8171",
		"OrderedByContactPersonFullName": "Martijn Loomans",
		"OrderedByName": "Grand caf restaurant Loos BV",
		"OrderNumber": 2227,
		"PaymentCondition": "14",
		"PaymentConditionDescription": "doorlopend standaard incasso",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 7.14,
		"VATAmountDC": 7.14,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'ac5781fe-5bb0-401e-91d4-014781333304')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'ac5781fe-5bb0-401e-91d4-014781333304')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 59.85,
		"AmountDiscount": 0,
		"AmountDC": 59.85,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 59.85,
		"Created": "26-10-2022",
		"Creator": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"CreatorFullName": "WEA Exact Online",
		"Currency": "EUR",
		"DeliverTo": "862259d6-36a3-430a-954f-434a73acf0e4",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "158a2284-7ffe-4a10-8780-5e81a11dce83",
		"DeliverToName": "Marc Wenger",
		"Description": "#8872",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "08-09-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "08-09-2022",
		"InvoiceID": "5a3d9b44-6fdc-4ddd-8813-0149948f2f8a",
		"InvoiceTo": "862259d6-36a3-430a-954f-434a73acf0e4",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Marc Wenger",
		"InvoiceNumber": 22704022,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "26-10-2022",
		"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"ModifierFullName": "WEA Exact Online",
		"OrderDate": "08-09-2022",
		"OrderedBy": "862259d6-36a3-430a-954f-434a73acf0e4",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Marc Wenger",
		"OrderNumber": 4565,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"SalespersonFullName": "WEA Exact Online",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'5a3d9b44-6fdc-4ddd-8813-0149948f2f8a')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'5a3d9b44-6fdc-4ddd-8813-0149948f2f8a')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 19.95,
		"AmountDiscount": 0,
		"AmountDC": 19.95,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 16.49,
		"Created": "05-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "a72ac07d-42b7-4b48-8c37-d9fe40b9c11f",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "810c2372-4498-4817-a797-e39690196f6f",
		"DeliverToName": "Johan Kienhorst",
		"Description": "Webshop order welness-store.myshopify.com 1510699898",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "08-11-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "18-10-2022",
		"InvoiceID": "19d73f36-6d80-4aee-b3ba-015a5ebfe1b3",
		"InvoiceTo": "a72ac07d-42b7-4b48-8c37-d9fe40b9c11f",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Johan Kienhorst",
		"InvoiceNumber": 22704789,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "18-10-2022",
		"OrderedBy": "a72ac07d-42b7-4b48-8c37-d9fe40b9c11f",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Johan Kienhorst",
		"OrderNumber": 5395,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 3.46,
		"VATAmountDC": 3.46,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'19d73f36-6d80-4aee-b3ba-015a5ebfe1b3')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'19d73f36-6d80-4aee-b3ba-015a5ebfe1b3')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 466.31,
		"AmountDiscount": 0,
		"AmountDC": 466.31,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 388.59,
		"Created": "28-04-2023",
		"Creator": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"CreatorFullName": "WEA Exact Online",
		"Currency": "EUR",
		"DeliverTo": "fc44f11e-fab7-4a5d-8a7a-1d2c7313f0e9",
		"DeliverToContactPerson": "e470e7a1-fa26-45d7-bec1-048e11e152b7",
		"DeliverToContactPersonFullName": "Gertraud Brugger",
		"DeliverToAddress": "4fece511-a276-43e0-a495-591e8d53a064",
		"DeliverToName": "Kesslerstadel",
		"Description": "welness-store.myshopify.com order #10652",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "19-05-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "28-04-2023",
		"InvoiceID": "2ca66832-92e6-429e-901e-015d08550618",
		"InvoiceTo": "fc44f11e-fab7-4a5d-8a7a-1d2c7313f0e9",
		"InvoiceToContactPerson": "e470e7a1-fa26-45d7-bec1-048e11e152b7",
		"InvoiceToContactPersonFullName": "Gertraud Brugger",
		"InvoiceToName": "Kesslerstadel",
		"InvoiceNumber": 23701426,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "28-04-2023",
		"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"ModifierFullName": "WEA Exact Online",
		"OrderDate": "14-04-2023",
		"OrderedBy": "fc44f11e-fab7-4a5d-8a7a-1d2c7313f0e9",
		"OrderedByContactPerson": "e470e7a1-fa26-45d7-bec1-048e11e152b7",
		"OrderedByContactPersonFullName": "Gertraud Brugger",
		"OrderedByName": "Kesslerstadel",
		"OrderNumber": 7367,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 77.72,
		"VATAmountDC": 77.72,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2ca66832-92e6-429e-901e-015d08550618')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'2ca66832-92e6-429e-901e-015d08550618')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 1,
		"StarterSalesInvoiceStatusDescription": "Verzonden",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 22.49,
		"AmountDiscount": 0,
		"AmountDC": 22.49,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 18.9,
		"Created": "06-05-2023",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "5f42124f-28db-435e-a5d9-0acc7a9cc5e5",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "7b1ec16a-e0a7-4ed7-8867-0d7b40659f06",
		"DeliverToName": "Anja Plagemann",
		"Description": "welness-store.myshopify.com order AMZ-DE-304-8739038-1203559",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "27-05-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "06-05-2023",
		"InvoiceID": "37c85c7b-f524-42e0-bcbe-01611b1a07ae",
		"InvoiceTo": "5f42124f-28db-435e-a5d9-0acc7a9cc5e5",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Anja Plagemann",
		"InvoiceNumber": 23701522,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "06-05-2023",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "06-05-2023",
		"OrderedBy": "5f42124f-28db-435e-a5d9-0acc7a9cc5e5",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Anja Plagemann",
		"OrderNumber": 7677,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 3.59,
		"VATAmountDC": 3.59,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'37c85c7b-f524-42e0-bcbe-01611b1a07ae')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'37c85c7b-f524-42e0-bcbe-01611b1a07ae')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 25.85,
		"AmountDiscount": 0,
		"AmountDC": 25.85,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 21.72,
		"Created": "01-09-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "0033474c-323c-4d7d-a1e8-6ecad4b3b2eb",
		"DeliverToContactPerson": "73f36082-fe90-462d-911a-e4642efcedcd",
		"DeliverToContactPersonFullName": "Jörg BRÄTSCH",
		"DeliverToAddress": "bbba787b-6ce4-466d-a7d9-3da814468333",
		"DeliverToName": "Jörg BRÄTSCH",
		"Description": "#8786",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "30-08-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "30-08-2022",
		"InvoiceID": "43e2b675-461f-4283-a7b6-0165ad426095",
		"InvoiceTo": "0033474c-323c-4d7d-a1e8-6ecad4b3b2eb",
		"InvoiceToContactPerson": "73f36082-fe90-462d-911a-e4642efcedcd",
		"InvoiceToContactPersonFullName": "Jörg BRÄTSCH",
		"InvoiceToName": "Jörg BRÄTSCH",
		"InvoiceNumber": 22703256,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "01-09-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "29-08-2022",
		"OrderedBy": "0033474c-323c-4d7d-a1e8-6ecad4b3b2eb",
		"OrderedByContactPerson": "73f36082-fe90-462d-911a-e4642efcedcd",
		"OrderedByContactPersonFullName": "Jörg BRÄTSCH",
		"OrderedByName": "Jörg BRÄTSCH",
		"OrderNumber": 3525,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#8786",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 4.13,
		"VATAmountDC": 4.13,
		"YourRef": "#8786",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'43e2b675-461f-4283-a7b6-0165ad426095')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'43e2b675-461f-4283-a7b6-0165ad426095')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 62.32,
		"AmountDiscount": 0,
		"AmountDC": 62.32,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 51.51,
		"Created": "05-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "94bf7ae6-0ace-4378-9cc7-f4d6129b2e5b",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "1646e9c0-1629-40fe-b0cc-8c5326891ac3",
		"DeliverToName": "Michel De nijs",
		"Description": "Webshop order welness-store.myshopify.com #9210",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "02-11-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "12-10-2022",
		"InvoiceID": "4ccb042c-e4d2-4db5-852e-016d87e353fd",
		"InvoiceTo": "94bf7ae6-0ace-4378-9cc7-f4d6129b2e5b",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Michel De nijs",
		"InvoiceNumber": 22704725,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "12-10-2022",
		"OrderedBy": "94bf7ae6-0ace-4378-9cc7-f4d6129b2e5b",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Michel De nijs",
		"OrderNumber": 5322,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.81,
		"VATAmountDC": 10.81,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'4ccb042c-e4d2-4db5-852e-016d87e353fd')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'4ccb042c-e4d2-4db5-852e-016d87e353fd')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 64.9,
		"AmountDiscount": 0,
		"AmountDC": 64.9,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 54.08,
		"Created": "13-10-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "26b750c8-5795-4d71-9055-afdae961cca6",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "8bd62a04-20c5-4234-8723-6b2ec3c57dba",
		"DeliverToName": "Martin Müller",
		"Description": "Webshop order welness-store.myshopify.com #9134",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "24-10-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "03-10-2022",
		"InvoiceID": "3f930e13-3cc9-430e-86a9-017f65011c85",
		"InvoiceTo": "26b750c8-5795-4d71-9055-afdae961cca6",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Martin Müller",
		"InvoiceNumber": 22703772,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "13-10-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "03-10-2022",
		"OrderedBy": "26b750c8-5795-4d71-9055-afdae961cca6",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Martin Müller",
		"OrderNumber": 4236,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.82,
		"VATAmountDC": 10.82,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'3f930e13-3cc9-430e-86a9-017f65011c85')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'3f930e13-3cc9-430e-86a9-017f65011c85')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 60.13,
		"AmountDiscount": 0,
		"AmountDC": 60.13,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 49.29,
		"Created": "15-03-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "d1e253c9-1c25-46e8-a784-0b754fcb0f21",
		"DeliverToContactPerson": "d7178690-6274-4ee0-a406-adac8de62a93",
		"DeliverToContactPersonFullName": "Marco Sajeva",
		"DeliverToAddress": "b3f8dbb6-c98f-4970-b367-2f2115352f5d",
		"DeliverToName": "Marco Sajeva",
		"Description": "#7070",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "15-03-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "15-03-2022",
		"InvoiceID": "b277e877-edaf-4dd4-9f21-019ebf5d1a6d",
		"InvoiceTo": "d1e253c9-1c25-46e8-a784-0b754fcb0f21",
		"InvoiceToContactPerson": "d7178690-6274-4ee0-a406-adac8de62a93",
		"InvoiceToContactPersonFullName": "Marco Sajeva",
		"InvoiceToName": "Marco Sajeva",
		"InvoiceNumber": 22700754,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "15-03-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "15-03-2022",
		"OrderedBy": "d1e253c9-1c25-46e8-a784-0b754fcb0f21",
		"OrderedByContactPerson": "d7178690-6274-4ee0-a406-adac8de62a93",
		"OrderedByContactPersonFullName": "Marco Sajeva",
		"OrderedByName": "Marco Sajeva",
		"OrderNumber": 829,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7070",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.84,
		"VATAmountDC": 10.84,
		"YourRef": "#7070",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'b277e877-edaf-4dd4-9f21-019ebf5d1a6d')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'b277e877-edaf-4dd4-9f21-019ebf5d1a6d')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 66.35,
		"AmountDiscount": 0,
		"AmountDC": 66.35,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 55.76,
		"Created": "20-03-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "ce3defcc-c1b1-4348-b979-5ac334917368",
		"DeliverToContactPerson": "00b32b88-84f0-4d23-8f00-9a99c23eeca9",
		"DeliverToContactPersonFullName": "Antje Hensel",
		"DeliverToAddress": "25ad9fe9-5c15-43b7-9cae-7621ef20b7e0",
		"DeliverToName": "Antje Hensel",
		"Description": "#7105",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "20-03-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "20-03-2022",
		"InvoiceID": "312fcd5d-52e0-41b7-956f-01b1032c7aea",
		"InvoiceTo": "ce3defcc-c1b1-4348-b979-5ac334917368",
		"InvoiceToContactPerson": "00b32b88-84f0-4d23-8f00-9a99c23eeca9",
		"InvoiceToContactPersonFullName": "Antje Hensel",
		"InvoiceToName": "Antje Hensel",
		"InvoiceNumber": 22700804,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "20-03-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "20-03-2022",
		"OrderedBy": "ce3defcc-c1b1-4348-b979-5ac334917368",
		"OrderedByContactPerson": "00b32b88-84f0-4d23-8f00-9a99c23eeca9",
		"OrderedByContactPersonFullName": "Antje Hensel",
		"OrderedByName": "Antje Hensel",
		"OrderNumber": 883,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#7105",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 10.59,
		"VATAmountDC": 10.59,
		"YourRef": "#7105",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'312fcd5d-52e0-41b7-956f-01b1032c7aea')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'312fcd5d-52e0-41b7-956f-01b1032c7aea')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 143.57,
		"AmountDiscount": 0,
		"AmountDC": 143.57,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 118.65,
		"Created": "14-11-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "34565138-600c-44d7-a89d-a3e7c78e3573",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "faf0f3b2-485c-4954-8144-97ec9a6dae72",
		"DeliverToName": "Marja Veen",
		"Description": "tafelaankleding.myshopify.com order #3324",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "05-12-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "14-11-2022",
		"InvoiceID": "9fb4619a-d43c-4a2e-8215-01b312f64f95",
		"InvoiceTo": "34565138-600c-44d7-a89d-a3e7c78e3573",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Marja Veen",
		"InvoiceNumber": 22704290,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "14-11-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "14-11-2022",
		"OrderedBy": "34565138-600c-44d7-a89d-a3e7c78e3573",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Marja Veen",
		"OrderNumber": 4845,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 24.92,
		"VATAmountDC": 24.92,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'9fb4619a-d43c-4a2e-8215-01b312f64f95')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'9fb4619a-d43c-4a2e-8215-01b312f64f95')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 104.75,
		"AmountDiscount": 0,
		"AmountDC": 104.75,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 86.58,
		"Created": "11-05-2023",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "268c506c-3496-488c-a390-519d5296a99c",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "8ebca31e-ca87-4c31-9b15-e7b4e9d9c148",
		"DeliverToName": "An Janssens",
		"Description": "Webshop order welness-store.myshopify.com #10818",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "24-05-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "03-05-2023",
		"InvoiceID": "89f498de-818d-48be-9d3a-01c79581484a",
		"InvoiceTo": "268c506c-3496-488c-a390-519d5296a99c",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "An Janssens",
		"InvoiceNumber": 23701593,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "11-05-2023",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "03-05-2023",
		"OrderedBy": "268c506c-3496-488c-a390-519d5296a99c",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "An Janssens",
		"OrderNumber": 7765,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 18.17,
		"VATAmountDC": 18.17,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'89f498de-818d-48be-9d3a-01c79581484a')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'89f498de-818d-48be-9d3a-01c79581484a')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 39.29,
		"AmountDiscount": 0,
		"AmountDC": 39.29,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 32.47,
		"Created": "05-12-2022",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "d766318b-be81-448b-b5dd-0b37dc71a4a2",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "af0201e7-5fa4-4140-b8e6-8f387a6c7054",
		"DeliverToName": "Laura Milatz",
		"Description": "Webshop order welness-store.myshopify.com #9117",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "23-10-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "02-10-2022",
		"InvoiceID": "1239db63-b05f-4546-a0e3-01d1a9a196b6",
		"InvoiceTo": "d766318b-be81-448b-b5dd-0b37dc71a4a2",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Laura Milatz",
		"InvoiceNumber": 22704669,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-12-2022",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "02-10-2022",
		"OrderedBy": "d766318b-be81-448b-b5dd-0b37dc71a4a2",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Laura Milatz",
		"OrderNumber": 5256,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 6.82,
		"VATAmountDC": 6.82,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'1239db63-b05f-4546-a0e3-01d1a9a196b6')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'1239db63-b05f-4546-a0e3-01d1a9a196b6')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 19.44,
		"AmountDiscount": 3.43,
		"AmountDC": 19.44,
		"AmountDiscountExclVat": 2.83,
		"AmountFCExclVat": 16.07,
		"Created": "19-08-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "ea274ee5-e586-4191-a591-0b3f92626230",
		"DeliverToContactPerson": "bfd48806-82c7-4077-897a-6cf2fe446b62",
		"DeliverToContactPersonFullName": "van Beek",
		"DeliverToAddress": "8bafad06-7c22-4ee9-aa57-48823cdf90cb",
		"DeliverToName": "Tummers Plaatbewerking B.V.",
		"Description": null,
		"Discount": 0.15,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "09-09-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "19-08-2022",
		"InvoiceID": "8548eb12-0dd9-420f-bcb6-01e583ceb006",
		"InvoiceTo": "ea274ee5-e586-4191-a591-0b3f92626230",
		"InvoiceToContactPerson": "bfd48806-82c7-4077-897a-6cf2fe446b62",
		"InvoiceToContactPersonFullName": "van Beek",
		"InvoiceToName": "Tummers Plaatbewerking B.V.",
		"InvoiceNumber": 22703058,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "19-08-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "19-08-2022",
		"OrderedBy": "ea274ee5-e586-4191-a591-0b3f92626230",
		"OrderedByContactPerson": "bfd48806-82c7-4077-897a-6cf2fe446b62",
		"OrderedByContactPersonFullName": "van Beek",
		"OrderedByName": "Tummers Plaatbewerking B.V.",
		"OrderNumber": 3316,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 3.37,
		"VATAmountDC": 3.37,
		"YourRef": "PO:442201304",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'8548eb12-0dd9-420f-bcb6-01e583ceb006')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'8548eb12-0dd9-420f-bcb6-01e583ceb006')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 2,
		"StarterSalesInvoiceStatusDescription": "Achterstallig",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 88.46,
		"AmountDiscount": 0,
		"AmountDC": 88.46,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 73.11,
		"Created": "21-04-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "af207223-5f1e-427a-85d6-77b10966cf0a",
		"DeliverToContactPerson": "067b1759-a33a-4644-a701-1815f5352fe1",
		"DeliverToContactPersonFullName": "leo zandvliet",
		"DeliverToAddress": "7d97d970-c7f9-4def-91d8-1b44cadf67f6",
		"DeliverToName": "Temming Tabaksspeciaalzaak I",
		"Description": "#2887",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "09-01-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "09-01-2022",
		"InvoiceID": "3526d86e-4319-4633-b5d8-01ef75caae37",
		"InvoiceTo": "6b37a977-0a45-416e-8b3d-9572c0f42120",
		"InvoiceToContactPerson": "a0bff7e6-df0d-4a46-a6fa-de4b28be29a9",
		"InvoiceToContactPersonFullName": "leo zandvliet",
		"InvoiceToName": "leo zandvliet",
		"InvoiceNumber": null,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "21-04-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "09-01-2022",
		"OrderedBy": "6b37a977-0a45-416e-8b3d-9572c0f42120",
		"OrderedByContactPerson": "a0bff7e6-df0d-4a46-a6fa-de4b28be29a9",
		"OrderedByContactPersonFullName": "leo zandvliet",
		"OrderedByName": "leo zandvliet",
		"OrderNumber": 82,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#2887",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "open",
		"StatusDescription": "Open",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 15.35,
		"VATAmountDC": 15.35,
		"YourRef": "#2887",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'3526d86e-4319-4633-b5d8-01ef75caae37')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'3526d86e-4319-4633-b5d8-01ef75caae37')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 0,
		"StarterSalesInvoiceStatusDescription": "Concept",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 42.4,
		"AmountDiscount": 0,
		"AmountDC": 42.4,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 42.4,
		"Created": "26-10-2022",
		"Creator": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"CreatorFullName": "WEA Exact Online",
		"Currency": "EUR",
		"DeliverTo": "9544110a-f75f-444d-8493-7617d5fcb651",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "912a445c-dc88-48db-8d38-1918202df8d7",
		"DeliverToName": "Lauara Eagle",
		"Description": "#8875",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "09-09-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "09-09-2022",
		"InvoiceID": "070f998f-6394-4876-8bea-01f5ea85e7dd",
		"InvoiceTo": "9544110a-f75f-444d-8493-7617d5fcb651",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Lauara Eagle",
		"InvoiceNumber": 22704044,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "26-10-2022",
		"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"ModifierFullName": "WEA Exact Online",
		"OrderDate": "09-09-2022",
		"OrderedBy": "9544110a-f75f-444d-8493-7617d5fcb651",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Lauara Eagle",
		"OrderNumber": 4589,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"SalespersonFullName": "WEA Exact Online",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'070f998f-6394-4876-8bea-01f5ea85e7dd')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'070f998f-6394-4876-8bea-01f5ea85e7dd')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 38.75,
		"AmountDiscount": 0,
		"AmountDC": 38.75,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 37.24,
		"Created": "20-03-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "a643a77e-1895-4227-a5de-3ea000d81e86",
		"DeliverToContactPerson": "f8cdb692-9488-4583-8aaa-ccedab12e065",
		"DeliverToContactPersonFullName": "Edwin Frank",
		"DeliverToAddress": "d7368916-7bc6-4165-831f-b481986a3cc6",
		"DeliverToName": "Edwin Frank",
		"Description": "AMZ-DE-028-7699768-5689161",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "20-03-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "20-03-2022",
		"InvoiceID": "a9015ffd-6911-471b-986a-01fd91a96dbc",
		"InvoiceTo": "a643a77e-1895-4227-a5de-3ea000d81e86",
		"InvoiceToContactPerson": "f8cdb692-9488-4583-8aaa-ccedab12e065",
		"InvoiceToContactPersonFullName": "Edwin Frank",
		"InvoiceToName": "Edwin Frank",
		"InvoiceNumber": 22700811,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "20-03-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "20-03-2022",
		"OrderedBy": "a643a77e-1895-4227-a5de-3ea000d81e86",
		"OrderedByContactPerson": "f8cdb692-9488-4583-8aaa-ccedab12e065",
		"OrderedByContactPersonFullName": "Edwin Frank",
		"OrderedByName": "Edwin Frank",
		"OrderNumber": 890,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "AMZ-DE-028-7699768-5689161",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 1.51,
		"VATAmountDC": 1.51,
		"YourRef": "AMZ-DE-028-7699768-5689161",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'a9015ffd-6911-471b-986a-01fd91a96dbc')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'a9015ffd-6911-471b-986a-01fd91a96dbc')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 20.45,
		"AmountDiscount": 0,
		"AmountDC": 20.45,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 16.9,
		"Created": "09-06-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "f362d027-16d2-4695-a532-8faa1bdd86d9",
		"DeliverToContactPerson": "cfa4a60e-6144-462b-b50a-b4743c2bf5b7",
		"DeliverToContactPersonFullName": "Marga Kuipers",
		"DeliverToAddress": "010b0056-ef86-4cd4-85b4-1a69ae3cf19a",
		"DeliverToName": "Marga  Kuipers",
		"Description": "#3050",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "09-06-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "09-06-2022",
		"InvoiceID": "04e8eaa0-7c9d-4877-9ec6-021627373a44",
		"InvoiceTo": "f362d027-16d2-4695-a532-8faa1bdd86d9",
		"InvoiceToContactPerson": "cfa4a60e-6144-462b-b50a-b4743c2bf5b7",
		"InvoiceToContactPersonFullName": "Marga Kuipers",
		"InvoiceToName": "Marga  Kuipers",
		"InvoiceNumber": 22702079,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "09-06-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "09-06-2022",
		"OrderedBy": "f362d027-16d2-4695-a532-8faa1bdd86d9",
		"OrderedByContactPerson": "cfa4a60e-6144-462b-b50a-b4743c2bf5b7",
		"OrderedByContactPersonFullName": "Marga Kuipers",
		"OrderedByName": "Marga  Kuipers",
		"OrderNumber": 2235,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#3050",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 3.55,
		"VATAmountDC": 3.55,
		"YourRef": "#3050",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'04e8eaa0-7c9d-4877-9ec6-021627373a44')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'04e8eaa0-7c9d-4877-9ec6-021627373a44')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 48.94,
		"AmountDiscount": 0,
		"AmountDC": 48.94,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 40.45,
		"Created": "12-05-2023",
		"Creator": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"CreatorFullName": "Cris Lang",
		"Currency": "EUR",
		"DeliverTo": "e52d6ee8-5716-4c22-bec0-ec6c9e585a1d",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "81ddcf16-9145-4bdb-b1b0-0c8ccefbbf44",
		"DeliverToName": "Josephine Van der Waals",
		"Description": "tafelaankleding.myshopify.com order BOL-1588576871",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "02-06-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "12-05-2023",
		"InvoiceID": "904c54f8-0cde-4a31-9c01-021b151d5905",
		"InvoiceTo": "e52d6ee8-5716-4c22-bec0-ec6c9e585a1d",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Josephine Van der Waals",
		"InvoiceNumber": 23701636,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "12-05-2023",
		"Modifier": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"ModifierFullName": "Cris Lang",
		"OrderDate": "12-05-2023",
		"OrderedBy": "e52d6ee8-5716-4c22-bec0-ec6c9e585a1d",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Josephine Van der Waals",
		"OrderNumber": 7808,
		"PaymentCondition": "21",
		"PaymentConditionDescription": "21 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 8.49,
		"VATAmountDC": 8.49,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'904c54f8-0cde-4a31-9c01-021b151d5905')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'904c54f8-0cde-4a31-9c01-021b151d5905')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 1,
		"StarterSalesInvoiceStatusDescription": "Verzonden",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 552.16,
		"AmountDiscount": 0,
		"AmountDC": 552.16,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 456.33,
		"Created": "25-03-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "334b5a15-05b7-4ff9-9269-b0198968109d",
		"DeliverToContactPerson": "51a9779d-c47f-488e-9153-b6132f19b014",
		"DeliverToContactPersonFullName": "T.a.v. crediteuren administratie",
		"DeliverToAddress": "d1187747-6013-44e7-b502-7b3b47dbb77e",
		"DeliverToName": "Boutique Hotel Jersey",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "24-04-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "25-03-2022",
		"InvoiceID": "eb260015-5584-42e2-88df-02220b360742",
		"InvoiceTo": "334b5a15-05b7-4ff9-9269-b0198968109d",
		"InvoiceToContactPerson": "51a9779d-c47f-488e-9153-b6132f19b014",
		"InvoiceToContactPersonFullName": "T.a.v. crediteuren administratie",
		"InvoiceToName": "Boutique Hotel Jersey",
		"InvoiceNumber": 22700867,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "25-03-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "25-03-2022",
		"OrderedBy": "334b5a15-05b7-4ff9-9269-b0198968109d",
		"OrderedByContactPerson": "51a9779d-c47f-488e-9153-b6132f19b014",
		"OrderedByContactPersonFullName": "T.a.v. crediteuren administratie",
		"OrderedByName": "Boutique Hotel Jersey",
		"OrderNumber": 952,
		"PaymentCondition": "30",
		"PaymentConditionDescription": "30 Dagen op rekening",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 95.83,
		"VATAmountDC": 95.83,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'eb260015-5584-42e2-88df-02220b360742')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'eb260015-5584-42e2-88df-02220b360742')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 314.78,
		"AmountDiscount": 0,
		"AmountDC": 314.78,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 314.78,
		"Created": "13-05-2022",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "98bd7812-6cbf-4768-b184-2f2ef36bd564",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "6fcd5063-b058-406a-9d42-023327c1b114",
		"DeliverToName": "Fürstenbahnhof",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "13-05-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "13-05-2022",
		"InvoiceID": "d239a157-6e16-4bf4-8ccc-0237985f5da0",
		"InvoiceTo": "98bd7812-6cbf-4768-b184-2f2ef36bd564",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Fürstenbahnhof",
		"InvoiceNumber": 22701608,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "13-05-2022",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "13-05-2022",
		"OrderedBy": "98bd7812-6cbf-4768-b184-2f2ef36bd564",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Fürstenbahnhof",
		"OrderNumber": 1734,
		"PaymentCondition": "27",
		"PaymentConditionDescription": "27 Dagen",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"SalespersonFullName": "Lanza Accounting",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 0,
		"VATAmountDC": 0,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'd239a157-6e16-4bf4-8ccc-0237985f5da0')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'd239a157-6e16-4bf4-8ccc-0237985f5da0')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 84.34,
		"AmountDiscount": 0,
		"AmountDC": 84.34,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 69.7,
		"Created": "05-09-2022",
		"Creator": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"CreatorFullName": "Thomas Lang",
		"Currency": "EUR",
		"DeliverTo": "5a40f2f8-8f6e-4562-a6b6-6515d47d90a5",
		"DeliverToContactPerson": "2590dc60-3e9a-4c0a-92da-1b9b1647a0d0",
		"DeliverToContactPersonFullName": "Bente De Clerck",
		"DeliverToAddress": "e8b4aeb8-78a5-441f-996b-f82353c04cfd",
		"DeliverToName": "Bente De Clerck",
		"Description": "#3124",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "05-09-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": 0,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "05-09-2022",
		"InvoiceID": "cdba7136-e009-4f0a-983f-023b42b10937",
		"InvoiceTo": "5a40f2f8-8f6e-4562-a6b6-6515d47d90a5",
		"InvoiceToContactPerson": "2590dc60-3e9a-4c0a-92da-1b9b1647a0d0",
		"InvoiceToContactPersonFullName": "Bente De Clerck",
		"InvoiceToName": "Bente De Clerck",
		"InvoiceNumber": 22703348,
		"IsExtraDuty": false,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "05-09-2022",
		"Modifier": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"ModifierFullName": "Thomas Lang",
		"OrderDate": "05-09-2022",
		"OrderedBy": "5a40f2f8-8f6e-4562-a6b6-6515d47d90a5",
		"OrderedByContactPerson": "2590dc60-3e9a-4c0a-92da-1b9b1647a0d0",
		"OrderedByContactPersonFullName": "Bente De Clerck",
		"OrderedByName": "Bente De Clerck",
		"OrderNumber": 3624,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": "#3124",
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "e5463560-4226-403c-853d-cb8bdcbf91b2",
		"SalespersonFullName": "Thomas Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 14.64,
		"VATAmountDC": 14.64,
		"YourRef": "#3124",
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'cdba7136-e009-4f0a-983f-023b42b10937')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'cdba7136-e009-4f0a-983f-023b42b10937')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 128.26,
		"AmountDiscount": 0,
		"AmountDC": 128.26,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 106,
		"Created": "20-03-2023",
		"Creator": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"CreatorFullName": "Lanza Accounting",
		"Currency": "EUR",
		"DeliverTo": "800deb1c-2e3b-46ea-ac05-043595316557",
		"DeliverToContactPerson": "6f8c79e4-1220-44de-9c73-d3e94b78bd24",
		"DeliverToContactPersonFullName": "Annelies van Keulen",
		"DeliverToAddress": "8e739917-4454-4cfe-a9ab-6e8f59b89e01",
		"DeliverToName": "in de keulse pot",
		"Description": null,
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "30-03-2023",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "20-03-2023",
		"InvoiceID": "e9eaf8bb-d4e8-4754-9308-02409fd32212",
		"InvoiceTo": "800deb1c-2e3b-46ea-ac05-043595316557",
		"InvoiceToContactPerson": "6f8c79e4-1220-44de-9c73-d3e94b78bd24",
		"InvoiceToContactPersonFullName": "Annelies van Keulen",
		"InvoiceToName": "in de keulse pot",
		"InvoiceNumber": 23700918,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "20-03-2023",
		"Modifier": "ce0346a5-2069-49a3-a625-7a329a1bb396",
		"ModifierFullName": "Lanza Accounting",
		"OrderDate": "17-03-2023",
		"OrderedBy": "800deb1c-2e3b-46ea-ac05-043595316557",
		"OrderedByContactPerson": "6f8c79e4-1220-44de-9c73-d3e94b78bd24",
		"OrderedByContactPersonFullName": "Annelies van Keulen",
		"OrderedByName": "in de keulse pot",
		"OrderNumber": 6974,
		"PaymentCondition": "10",
		"PaymentConditionDescription": "10 Dagen op rekening",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "46ccbdc3-7af2-42f9-a72e-5282c814f05d",
		"SalespersonFullName": "Cris Lang",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 22.26,
		"VATAmountDC": 22.26,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e9eaf8bb-d4e8-4754-9308-02409fd32212')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e9eaf8bb-d4e8-4754-9308-02409fd32212')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	},
	{
	"sales_invoice": {
		"AmountFC": 82.92,
		"AmountDiscount": 0,
		"AmountDC": 82.92,
		"AmountDiscountExclVat": 0,
		"AmountFCExclVat": 68.53,
		"Created": "11-01-2023",
		"Creator": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"CreatorFullName": "WEA Exact Online",
		"Currency": "EUR",
		"DeliverTo": "5a625c24-6808-4f3e-9a65-a5a954cf5685",
		"DeliverToContactPerson": null,
		"DeliverToContactPersonFullName": null,
		"DeliverToAddress": "f525da14-547e-4bdb-852a-3efd3e1a2746",
		"DeliverToName": "Pieter Mendelts",
		"Description": "#3442",
		"Discount": 0,
		"DiscountType": 1,
		"Division": 3120592,
		"Document": null,
		"DocumentNumber": null,
		"DocumentSubject": null,
		"DueDate": "08-12-2022",
		"ExtraDutyAmountFC": null,
		"GAccountAmountFC": null,
		"IncotermAddress": null,
		"IncotermCode": null,
		"IncotermVersion": null,
		"InvoiceDate": "08-12-2022",
		"InvoiceID": "e19b4351-b7c7-40f1-b95f-025434538c7f",
		"InvoiceTo": "5a625c24-6808-4f3e-9a65-a5a954cf5685",
		"InvoiceToContactPerson": null,
		"InvoiceToContactPersonFullName": null,
		"InvoiceToName": "Pieter Mendelts",
		"InvoiceNumber": 22705254,
		"IsExtraDuty": null,
		"Journal": "70",
		"JournalDescription": "Verkoopboek",
		"Modified": "11-01-2023",
		"Modifier": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"ModifierFullName": "WEA Exact Online",
		"OrderDate": "08-12-2022",
		"OrderedBy": "5a625c24-6808-4f3e-9a65-a5a954cf5685",
		"OrderedByContactPerson": null,
		"OrderedByContactPersonFullName": null,
		"OrderedByName": "Pieter Mendelts",
		"OrderNumber": 5911,
		"PaymentCondition": "WS",
		"PaymentConditionDescription": "Betaald - Online Shop",
		"PaymentReference": null,
		"Remarks": null,
		"SalesChannel": null,
		"SalesChannelCode": null,
		"SalesChannelDescription": null,
		"Salesperson": "6c1fb819-140c-4475-baa4-238b4801e6af",
		"SalespersonFullName": "WEA Exact Online",
		"SelectionCode": null,
		"SelectionCodeCode": null,
		"SelectionCodeDescription": null,
		"ShippingMethod": null,
		"ShippingMethodCode": null,
		"ShippingMethodDescription": null,
		"Status": "processed",
		"StatusDescription": "Verwerkt",
		"Type": 8020,
		"TypeDescription": "Factuur",
		"VATAmountFC": 14.39,
		"VATAmountDC": 14.39,
		"YourRef": null,
		"SalesInvoiceLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e19b4351-b7c7-40f1-b95f-025434538c7f')/SalesInvoiceLines"
		}
		},
		"SalesInvoiceOrderChargeLines": {
		"__deferred": {
			"uri": "https://start.exactonline.nl/api/v1/3120592/salesinvoice/SalesInvoices(guid'e19b4351-b7c7-40f1-b95f-025434538c7f')/SalesInvoiceOrderChargeLines"
		}
		},
		"StarterSalesInvoiceStatus": 3,
		"StarterSalesInvoiceStatusDescription": "Betaald",
		"WithholdingTaxAmountFC": null,
		"WithholdingTaxBaseAmount": null,
		"WithholdingTaxPercentage": null,
		"Warehouse": null
	},
	"lines": []
	}
],
"success": true
}




export {customers, customerTopProducts, customerProducts, mockInventory, selectedProducts, mockInvoices, mockCustomer, mockCustomerSearch, mockCustomerOrders, mockSupplierSearch, mockPurchaseOrders, mockSupplier, mockSearchProducts};