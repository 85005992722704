import { useFormik } from "formik";
import * as Yup from 'yup';
import {Input, Button, Card, CardHeader, VStack, Box, Heading, Center, Flex, Text, HStack, Link} from "@chakra-ui/react"
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useLogin } from "../hooks/useSubmit";
  import {useAlertContext} from "../context/alertContext";
  import React, { useEffect } from "react";
  
  //import { login } from "../auth/authService";
  import { redirect } from 'react-router-dom';
  import { useNavigate } from "react-router-dom";

function LoginForm() {
  const navigate = useNavigate();
   const { isLoading, response, login } = useLogin();
    const { onOpen } = useAlertContext();


  const formik = useFormik({
    initialValues: { email: "", password: ""},
    onSubmit: (values) => {
      login(values.email, values.password).then((resp) => {
        console.log('postLogin response', resp);
        if(resp?.data?.two_factor) {
          navigate('/login/confirm');
          return;
        }
        
        else if(resp?.type === 'success') {
            onOpen('success', "You've been successfully logged in!");
            formik.resetForm();
            // then let's log in new user
            navigate("/quick-orders");
          }
          else 
            onOpen('error', resp.message);
        })
      },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(8, 'Must be at least 8 characters').required('Required'),
    }),
  });

  const isEmailInvalid = formik.touched.email && formik.errors.email;
  const isPasswordInvalid = formik.touched.password && formik.errors.password;
 
  return (
    <Center>
     <Card m={4}>
      <VStack w="600px" p={16} alignItems="center">
          <Heading as="h5" ali size="lg" id="login-form">exacter.online</Heading>
          <Text>Save time with quick & easy exact online workflows</Text>

        <Box p={6} rounded="md" w="100%">
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={isEmailInvalid}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps("email")}
                />
                { isEmailInvalid && (<FormErrorMessage>{formik.errors.email}</FormErrorMessage>)}
              </FormControl>

              <FormControl isInvalid={isPasswordInvalid}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  {...formik.getFieldProps("password")}
                />
                { isPasswordInvalid && ( <FormErrorMessage>{formik.errors.password}</FormErrorMessage> )}
              </FormControl>

              <Button type="submit" colorScheme="teal" width="full">
                Login
                {/* { isLoading ? (<>Loading...</>) : (<>Login</>) } */}
              </Button>
                <HStack>
                    <Text><Link href={"/register"}>signup</Link></Text>
                </HStack>
            </VStack>
          </form>
        </Box>
      </VStack>
      </Card>
    </Center>
  );
};



    // return (
    //     <Card p={6} m={2}>
    //     <CardHeader>Login</CardHeader>
    //   <form>
    //     <FormControl isRequired isInvalid={emailInvalid}>
    //       <FormLabel>Email address</FormLabel>
    //       <Input type="email" />
    //       <FormHelperText>Your login (email).</FormHelperText>
    //     </FormControl>
    //     <FormControl isRequired inInvalid={passwordInvalid}>
    //       <FormLabel>Password</FormLabel>
    //       <Input type="password" />
    //       <FormHelperText>Your password.</FormHelperText>
    //     </FormControl>
    //   </form>
    //   </Card>
    // );


export default LoginForm;