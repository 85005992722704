import axios from 'axios';


// endpoints
// /customer/search

const dataApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.exacter.online/api",
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    "Accept": "application/json",
    'Access-Control-Allow-Origin': '*'
  },
});

dataApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 422) {
        localStorage.removeItem("user");
        window.location.href = "/login";
      }

      if (error.response.status == 403) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            user = {...user, subscribed: false, free_trial: false};
            localStorage.setItem("user", JSON.stringify(user));
            window.location.reload();
        }
      }
      
    }
    return Promise.reject(error);
  }
);

export default dataApi;