import React from "react";
import { Box, Center } from "@chakra-ui/react";
import Spinner from "../Spinner";
import PurchaseReportsFilter from "./PurchaseReportsFilter";
import PurchaseReportTables from "./PurchaseReportsTable";
import { usePurchaseReport } from "../../context/purchaseReportContext";

export const Report = () => {
  // Context
  const { isLoading, purchaseOrders } = usePurchaseReport();

  return (
    <>
      {/* filter bar */}
      <Box bg={"#D9D9D9"} px={4} pt={3} mb={5} h={16} alignItems={"center"}>
        <PurchaseReportsFilter />
      </Box>

      {isLoading && (
        <Center>
          <Box>
            <br />
            <Spinner />
          </Box>
        </Center>
      )}

      {/* Main Data Table */}
      {!isLoading && purchaseOrders.length > 0 && <PurchaseReportTables />}
    </>
  );
};

export default Report;
