import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../auth/UserContext";
import ResizeTextarea from "react-textarea-autosize";
import {
  Grid,
  GridItem,
  Card,
  VStack,
  Heading,
  Textarea,
  FormControl,
  Button,
  Input,
  useToast,
  FormLabel,
  Form,
  Switch,
  Icon
} from "@chakra-ui/react";
import dataApi from "../../data/dataApi";
import { ReactSVG } from 'react-svg';

const SecuritySettings = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const toast = useToast();
  const [svg, setSvg] = useState("");

  useEffect(() => {
console.log(svg);
  }, [svg])
  
  useEffect(() => {
    dataApi.defaults.headers.common["Authorization"] = "Bearer " + currentUser?.token;
  }, []);

  const showQr = async () => {
      const response = await dataApi.get("/user/two-factor-qr-code");
      setSvg(response.data.svg);
  };

  const toggleTwoFa = () => {
      if (currentUser.two_factor) {
          dataApi.delete('/user/two-factor-authentication')
          .then(async (res) => {          
            setCurrentUser({...currentUser, two_factor: false});
            toast({
              title: "2FA Disabled",
              position: "top",
              type: "success",
              duration: 3000,
              isClosable: true,
              colorScheme: "red"
            });
          })
          .catch((err) => {
            toast({
              title: "An error occured updating 2FA settings",
              position: "top",
              type: "error",
              duration: 3000,
              isClosable: true,
            })
          })
      }
      else {
        dataApi.post('/user/two-factor-authentication')
        .then(async (res) => {
            await showQr();
            setCurrentUser({...currentUser, two_factor: true});
            toast({
              title: "2FA Enabled",
              position: "top",
              type: "success",
              duration: 3000,
              isClosable: true,
              colorScheme: "green"
            });
        })
        .catch((err) => {
          toast({
            title: "An error occured updating 2FA settings",
            position: "top",
            type: "error",
            duration: 3000,
            isClosable: true,
          })
        })
      }
  }

  return (
    <>
      <Card>
        <VStack w="600px" pl={16} pt={5} pb={5} alignItems="flex-start">
          <Heading
            mb={3}
            as="h5"
            id="login-form"
            size={"large"}
          >
            Security
          </Heading>
          <FormControl display="flex">
            <FormLabel htmlFor='2fa' mb='0'>
              Enable 2FA?
            </FormLabel>
            <Switch id='2fa' isChecked={currentUser?.two_factor} onChange={toggleTwoFa}/>
          </FormControl>
          <div dangerouslySetInnerHTML={{ __html: svg }} />
        </VStack>
      </Card>
    </>
  );
};

export default SecuritySettings;
