import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { usePurchaseReport } from "../../context/purchaseReportContext";

export function ExportFile({ isOpen, onClose }) {
  // Context
  const toast = useToast();
  const { purchaseOrders, setShowExportCsvModal, csvData } =
    usePurchaseReport();

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export your report</ModalHeader>
        <ModalCloseButton onClick={() => setShowExportCsvModal(false)} />
        <ModalBody>
          <Text>Report will be exported in CSV file.</Text>
          {purchaseOrders.length === 0 && (
            <Text color={"red.600"}>
              No data available, select data to export
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              setShowExportCsvModal(false);
              onClose();
            }}
          >
            Cancle
          </Button>
          <Box
            bg="teal.500"
            color="whiteAlpha.900"
            px={3}
            py={1}
            borderRadius={"md"}
            fontWeight={"bold"}
            onClick={() => {
              toast({
                position: "top",
                title: "Report exported successful",
                status: "success",
                isClosable: true,
              });
              setShowExportCsvModal(false);
            }}
          >
            <CSVLink data={csvData}>Export</CSVLink>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ExportFile;
