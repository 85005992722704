import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

const SupplierProductsContext = createContext();

export const SupplierProductsProvider = ({ children }) => {
    // all Products loaded from API
	const [ supplierProducts, setSupplierProducts] = useState([]);
    // Products left in a draggable list
    const [ sourceProducts, setSourceProducts] = useState([]);
	// Products Selected in a draggable list
    const [ selectedProducts, setSelectedProducts] = useState([]);

    const navigate = useNavigate();

	useEffect(() => {

	}, [navigate]);


	return (
		<SupplierProductsContext.Provider value={[ supplierProducts, setSupplierProducts, sourceProducts, setSourceProducts, selectedProducts, setSelectedProducts]}>
            {children}
		</SupplierProductsContext.Provider>
	);
};


export default SupplierProductsContext;