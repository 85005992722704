import React from "react";
import { Box, Center } from "@chakra-ui/react";
import Spinner from "../Spinner";
import { useCustomerReport } from "../../context/customerReportContext";
import CustomerReportsFilter from "./CustomerReportsFilter";
import CustomerReportsTable from "./CustomerReportsTable";

export const CustomerReports = () => {
  // Context
  const { isLoading, customerOrders } = useCustomerReport();

  return (
    <>
      {/* filter bar */}
      <Box bg={"#D9D9D9"} px={4} pt={3} mb={5} h={16} alignItems={"center"}>
        <CustomerReportsFilter />
      </Box>

      {isLoading && (
        <Center>
          <Box>
            <br />
            <Spinner />
          </Box>
        </Center>
      )}

      {/* Main Data Table */}
      {!isLoading && customerOrders.length > 0 && <CustomerReportsTable />}
    </>
  );
};

export default CustomerReports;
