import React from 'react';

import Layout from './Layout';
import CSettings from '../components/Settings/Index';

const Settings = () => {
	return (
		<>
			<Layout>
				<CSettings />
			</Layout>
		</>
	);
};

export default Settings;