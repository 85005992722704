import { useState } from "react";
import dataApi from "../data/dataApi";

import axios from "axios";
import authApi from "../auth/authApi";
import { login } from "../auth/authService";
import { useNavigate } from "react-router-dom";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
export const usePostOrder = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    type: '',
    message: ``,
  });

  const postOrder = async (data, currentCustomer, currentUser) => {
    console.log('postOrder data', data);
    setLoading(true);
    var newResponse = {};

    if (currentCustomer?.id) {
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;

      const response = await dataApi.post(`/order/create`, data);
      console.log("postOrder API response", response);

      newResponse = {
        type: "info",
        message: response.data,
      };
    }

    setResponse(newResponse);
    setLoading(false);
    return newResponse;
  };

  return { isLoading, response, postOrder};
}

export const usePostSupplierOrder = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    type: '',
    message: ``,
  });

  const postOrder = async (data, currentSupplier, currentUser) => {
    console.log('postOrder data', data);
    setLoading(true);
    var newResponse = {};

    if (currentSupplier?.id) {
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;

      const response = await dataApi.post(`/purchase_orders/create`, data);
      console.log("postOrder API response", response);

      newResponse = {
        type: "info",
        message: response.data,
      };
    }

    setResponse(newResponse);
    setLoading(false);
    return newResponse;
  };

  return { isLoading, response, postOrder};
}

export const useLogin = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    type: '',
    message: ``,
  });
  const navigate = useNavigate();

  const login = async (email, password) => {
    setLoading(true);
    var newResponse = {};

    try {
      const response = await authApi.post("/login", {
        email: email,
        password: password,
      });

      if (response.data.two_factor == true) {
          localStorage.setItem("key", response.data.data.key);
          return {...response, type: 'success'};
      }
      

      const token = response.data.data.token;
      if (token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }

      console.log("auth API /login response", response.data.data);
      newResponse = {
        type: "success",
        message: `Logged in successfully!`,
      };
    } catch (error) {
      console.log(error);
      newResponse = {
        type: "error",
        message: error.response.data.message,
      };
    } finally {
      setLoading(false);
    }
    setResponse(newResponse);
    return newResponse;
  };

  return { isLoading, response, login };
}

export const useRegister = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    type: '',
    message: ``,
  });

  const register = async (data) => {
    setLoading(true);
    var newResponse = {};

    try {
    const response = await authApi.post(`/register`, data);
    console.log("auth API /register response", response);

    newResponse = {
      type: "info",
      message: response.data,
    };
  }
  catch (error) {
    newResponse = {
      type: "info",
      message: error,
    };

  }
    setResponse(newResponse);
    setLoading(false);
    return newResponse;
  };

  return { isLoading, response, register };
}
