import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, HStack, useDisclosure } from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import dataApi from "../../data/dataApi";
import UserContext from "../../auth/UserContext";
import { useCustomerReport } from "../../context/customerReportContext";
import { mockCustomerOrders, mockCustomerSearch } from "../../data/mock";
import ExportFile from "./ExportFile";
import { DownloadIcon } from "@chakra-ui/icons";
import DateRangePicker from "../DateRangePicker";

export function CustomerReportsFilter() {
  // States
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerSearchString, setCustomerSearchString] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);

  // Context
  const { currentUser } = useContext(UserContext);
  const { getCustomerOrderData, setCustomerOrders, setCustomerOrdersWithLines, showExportCsvModal, setShowExportCsvModal, onExportCsv, startDate, endDate, setStartDate, setEndDate, showDateError, setShowDateError, customerOrdersGroupped, setCustomerOrdersGroupped, calculateTotalQuantities } = useCustomerReport();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSearchLoading, setIsSearchLoading] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV == "local") {
      let data = mockCustomerOrders.data;
      setCustomerOrders(
        data.map((list) => {
          return list.sales_order;
        })
      );
      setCustomerOrdersWithLines(data);      
      setCustomerOrdersGroupped(calculateTotalQuantities(data));
    }
  }, []);

  // Customer Search onChange
  useEffect(() => {
    if (customerSearchString?.length > 0) {
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const customerSearchRequest = `/customer/search/${encodeURI(
        customerSearchString
      )}`;

      setIsSearchLoading(true);
      let req = setTimeout(() => {
        dataApi
          .get(customerSearchRequest)
          .then((response) => {
            console.log("onCustomerSearch response", response);
            if (response.status === 200) {
              setCustomerOptions(response.data.customers);
              setIsSearchLoading(false);
            }
          })
          .catch((error) => {
            setIsSearchLoading(false);
            console.log("onCustomerSearch error", error);
          });
      }, 1000);

      return () => clearTimeout(req);
    }
  }, [currentUser, customerSearchString]);

  return (
    <>
      {/* Filter Bar */}
      <Flex justifyContent={"space-between"} alignItems={"center"} pb={3}>
        <HStack spacing={3}>
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            showDateError={showDateError}
            setShowDateError={setShowDateError}
          />
          {/* Customer Search */}
          <Box>
          <Multiselect
              options={customerOptions}
              selectedValues={selectedCustomer}
              onSelect={(value) => setSelectedCustomer(value)}
              onRemove={(value) => setSelectedCustomer(value)}
              onSearch={(value) => setCustomerSearchString(value)}
              loading={isSearchLoading}
              loadingMessage="Loading..."
              displayValue="name"
              placeholder="Search Customer"
              style={{
                searchBox: {
                  fontSize: "13px",
                  backgroundColor: "white",
                  border: "none",
                },
                option: {
                  fontSize: "13px",
                },
                chips: {
                  fontSize: "10px",
                  background: "grey",
                  color: "white",
                  borderRadius: "0px",
                  padding: "0px 2px",
                },
              }}
            />
          </Box>
          <Button
            type="submit"
            variant={"outline"}
            colorScheme={"teal"}
            size={"sm"}
            onClick={() => getCustomerOrderData(selectedCustomer[0].id, startDate, endDate)}
          >
            Generate Report
          </Button>

          {/* Export Datatable to CSV */}
          <Button
            variant={"solid"}
            colorScheme={"teal"}
            size={"sm"}
            mr={4}
            leftIcon={<DownloadIcon />}
            onClick={() => {
              onExportCsv();
              setShowExportCsvModal(true);
              onOpen();
            }}
          >
            Export
          </Button>
        </HStack>
      </Flex>

      {/* Export File Modal*/}
      {showExportCsvModal && isOpen && (
        <ExportFile onClose={onClose} isOpen={isOpen} />
      )}
    </>
  );
}

export default CustomerReportsFilter;
