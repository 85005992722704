import React, { useEffect } from "react";
import { Box, Card, CardBody, CardHeader, Center, Flex, Heading, Stack, StackDivider, Text } from "@chakra-ui/react";
import Spinner from "../Spinner";
import { useCustomerReport } from "../../context/customerReportContext";
import { usePurchaseReport } from "../../context/purchaseReportContext";
import { CustomersFilter } from "./CustomersFilter";
import { CustomerReportsTable } from "../CustomerReports/CustomerReportsTable";
import { PurchaseReportsTable } from "../purchaseOrderReports/PurchaseReportsTable";
import { useCustomers } from "../../context/customersContext";
import { mockCustomer, mockCustomerSearch } from "../../data/mock";
import CustomerOrdersChart from "../CustomerReports/CustomerOrdersChart";
import InvoiceReportContext from "../../context/invoiceReportContext";
import InvoiceReportsTable from "../InvoiceReports/InvoiceReportsTable";

export const Customers = () => {
  // Context
  const { isLoading: customersLoading, customerOrders } = useCustomerReport();
  const { isLoading: purchasesLoading, purchaseOrders } = usePurchaseReport();
  const { isLoading } = useCustomers();
  const {customer, setCustomer} = useCustomers();

  // useEffect(() => {
  //   setCustomer(mockCustomerSearch.customers[0]);
  // }, []);

  return (
    <>
      {/* filter bar */}
      <Box bg={"#D9D9D9"} px={4} pt={3} mb={5} h={16} alignItems={"center"}>
        <CustomersFilter />
      </Box>


      { !customer ? ( <>Select a customer
      
        
      </>) : (
        <>
      {(isLoading) && (
        <Center>
          <Box>
            <br />
            <Spinner />
          </Box>
        </Center>
      )}


      <Flex gap={4} flex={"wrap"} alignItems="start">
      {/* Main Data Table */}

      
      <div style={{width:"50%"}}>

        {!isLoading && (
          
          <Card textAlign="left" w="100%" mb={4}>
            <CardHeader mb={0} pb={2}>
              <Heading size='md'>Customer Details</Heading>
            </CardHeader>
          
            <CardBody>
              <Stack divider={<StackDivider />} spacing='3'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Code
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {customer.code}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Name
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {customer.name}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Email
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {customer.email}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Phone Number
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {customer.phone}
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        )}

            {!isLoading &&(
            <Card title="Customer Orders" w="100%" textAlign={"left"} mb={4}>
                    <Heading size={"md"} m={4}>Customer Orders</Heading>
                    <CustomerReportsTable small={true} />
                  </Card>
            )}
</div>
        

          {!isLoading && (
            <>

              <div style={{width:"60%"}}>
                
                <Card title="Orders Per Week" w="100%" textAlign={"left"} p={4} mb={4}>
                  <Heading size={"md"} mb={4} >Graph</Heading>
                  <div style={{height:"278px"}}>
                    <CustomerOrdersChart />
                  </div>
                </Card>

                <Flex gap={3}>
                  <Card title="Sales Invoices" w="100%" textAlign={"left"}>
                    <Heading size={"md"} m={4}>Sales Invoices</Heading>
                    <InvoiceReportsTable small={true} />

                    
                  </Card>
                </Flex>
              </div>
              
            </>
          )}

      </Flex>
      </>
      )}
    </>
  );
};

export default Customers;
