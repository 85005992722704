import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useInvoiceReport } from "../../context/invoiceReportContext";

export function LinesDataTable({ selectedLineIndex }) {
  // Context
  const { invoiceOrdersWithLines, selectedColumn } = useInvoiceReport();

  const allLines = invoiceOrdersWithLines.map((item) => {
    return item.lines;
  });

  return (
    <Tr>
      <Td></Td>
      <Td colSpan={selectedColumn.mainColumn.length}>
        <Table bg={"#d9d9d9"}>
          <Thead position={"sticky"}>
            <Tr>
              {selectedColumn.lineColumn.map((item, index) => {
                return (
                  <Th key={index} textTransform={"none"}>
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody maxH={"20px"}>
            {allLines[selectedLineIndex].map((line, index) => {
              return (
                <Tr key={index}>
                  {selectedColumn.lineColumn.map((item) => {
                    return <Td key={item}>{line[item]}</Td>;
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Td>
    </Tr>
  );
}

export default LinesDataTable;
