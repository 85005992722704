import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { TbColumns3 } from "react-icons/tb";
import MultiSelect from "../MultiSelect";
import { useCustomerReport } from "../../context/customerReportContext";
import { GrClose } from "react-icons/gr";
import { DownloadIcon, InfoIcon } from "@chakra-ui/icons";
import LinesDataTable from "./LinesDataTable";

export function CustomerReportsTable({small=false}) {
  // State
  const [showLineDataTable, setShowLineDataTable] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState();
  const [selectedLineIndex, setSelectedLineIndex] = useState();
  const [openChildTable, setOpenChildTable] = useState(false);

  // Context
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    customerOrders,
    mainFields,
    lineFields,
    showAllLines,
    setShowAllLines,
    defaultFields,
    selectedColumn,
    setSelectedColumn,
    showGroupped,
    setShowGroupped,
    customerOrdersGroupped,
    setSmall
  } = useCustomerReport();

  useEffect(() => {
    setSmall(small);

  }, []);

  useEffect(() => {
    console.log(customerOrders);
  }, [customerOrders]);

  const menuItem = [
    {
      title: "Main Columns",
      itemList: mainFields,
      checkedItems: selectedColumn.mainColumn,
    },
    {
      title: "Line Columns",
      itemList: lineFields,
      checkedItems: selectedColumn.lineColumn,
    },
  ];

  return (
    <Box>
      
      {!small && (
        <Flex px={4} gap={5}>
          {/* Column Filter */}
          <Box>
            <MultiSelect
              label="Columns"
              icon={<TbColumns3 />}
              items={menuItem}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              defaultFields={defaultFields}
              selectDefaultOption
            />
          </Box>

          {/* Expand Lines */}
          <Checkbox
            isChecked={showAllLines}
            onChange={(e) => setShowAllLines(!showAllLines)}
          >
            Expand Lines
          </Checkbox>
          <Checkbox
            isChecked={showGroupped}
            onChange={(e) => setShowGroupped(!showGroupped)}
          >
            Group by product
          </Checkbox>
        </Flex>
      )}

      {/* Main Table */}
      <TableContainer>
        <Table variant="striped" cellPadding={0} size={"sm"}>
          <Thead>
            <Tr>
              { !small && <Th textTransform={"none"}>Actions</Th> }
              {selectedColumn.mainColumn.map((item, index) => {
                return (
                  <Th key={index} textTransform={"none"}>
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          { !showGroupped ? (
          <Tbody>
            {customerOrders.map((list, listIndex) => {
              return (
                <Fragment key={listIndex}>
                  <Tr>
                    {!small && (
                    <Td>
                      <HStack>
                        <Button
                          variant={"outline"}
                          colorScheme={"teal"}
                          size="sm"
                          onClick={onOpen}
                        >
                          <DownloadIcon />
                        </Button>
                        <Button
                          variant={"outline"}
                          colorScheme={"teal"}
                          size={"sm"}
                          onClick={() => {
                            setShowLineDataTable(!showLineDataTable);
                            setSelectedPurchaseOrderId(list.PurchaseOrderID);
                            setSelectedLineIndex(listIndex);
                            setOpenChildTable(!openChildTable);
                          }}
                        >
                          {showLineDataTable &&
                          selectedPurchaseOrderId === list.PurchaseOrderID ? (
                            <GrClose />
                          ) : (
                            <InfoIcon />
                          )}
                        </Button>
                      </HStack>
                    </Td>
                    )}
                    {selectedColumn.mainColumn.map((item) => {
                      return <Td key={item}>{list[item]}</Td>;
                    })}
                  </Tr>

                  {/* Line Table */}
                  {(showAllLines ||
                    (showLineDataTable &&
                      selectedPurchaseOrderId === list.PurchaseOrderID)) && (
                    <LinesDataTable
                      selectedLineIndex={
                        showAllLines ? listIndex : selectedLineIndex
                      }
                    />
                  )}
                </Fragment>
              );
            })}
          </Tbody>
          ) :
          (
            <Tbody>
                {customerOrdersGroupped?.map((list, listIndex) => {
              return (
                <Fragment key={listIndex}>
                  
                  {!small && (
                    <Tr>
                      <Td>
                        <HStack>
                          <Button
                            variant={"outline"}
                            colorScheme={"teal"}
                            size="sm"
                            onClick={onOpen}  
                          >
                            <DownloadIcon />
                          </Button>
                        </HStack>
                      </Td>
                      {selectedColumn.mainColumn.map((item) => {
                        return <Td key={item}>{list[item]}</Td>;
                      })}
                    </Tr>
                  )}

                  {/* Line Table */}
                  {(showAllLines ||
                    (showLineDataTable &&
                      selectedPurchaseOrderId === list.PurchaseOrderID)) && (
                    <LinesDataTable
                      selectedLineIndex={
                        showAllLines ? listIndex : selectedLineIndex
                      }
                    />
                  )}
                </Fragment>
              )})}
            </Tbody>
            
          )
          
          
          }
          {small && (
              <Tfoot>
                <Tr>
                  <Th>
                    winst:&nbsp;
                    &euro; {customerOrders.length > 0 ? (
                      (customerOrders.reduce((accumulator, order) => accumulator + order.AmountFCExclVat, 0) -
                          customerOrders.reduce((accumulator, order) => accumulator + order.TotalCost, 0)
                      )).toFixed(2) : '0.00'}
                  </Th>
                  <Th>marge:&nbsp;
                    {customerOrders.length > 0 ? (
                        (((customerOrders.reduce((accumulator, order) => accumulator + order.AmountFCExclVat, 0) -
                                customerOrders.reduce((accumulator, order) => accumulator + order.TotalCost, 0)) /
                            customerOrders.reduce((accumulator, order) => accumulator + order.AmountFCExclVat, 0)) * 100
                    ).toFixed(2) + ' %') : '0.00 %'}
                  </Th>
                  <Th>&euro; {customerOrders.length > 0 ? (customerOrders.reduce((accumulator, order) => accumulator + order.AmountFCExclVat, 0)).toFixed(2) : '0.00'}</Th>
                  <Th>&euro; {customerOrders.length > 0 ? (customerOrders.reduce((accumulator, order) => accumulator + order.TotalCost, 0)).toFixed(2) : '0.00'}</Th>
                </Tr>
              </Tfoot>
          )}
            
        </Table>
      </TableContainer>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customer Report Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Customer report message</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CustomerReportsTable;
