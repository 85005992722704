import React, {useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../auth/UserContext';
import { isAuthenticated } from '../auth/authService';

// components
import Navbar from '../components/Navbar';
import Sidebar from "../components/Sidebar"
import SidebarWithHeader from "../components/SidebarWithHeader"


const Layout = ({ children }) => {
    const navigate = useNavigate();
    const { currentUser, setCurrentUser } = useContext(UserContext);

    // useEffect(() => {
    //     console.log('layout-useEffect-currentUser', currentUser);
    //     if (!currentUser?.token) {
    //       navigate("/");
    //     }
    //   }, [currentUser, navigate]);

	return (
		<>
			{/* <Navbar /> */}
            {/* <Sidebar>
			{ children }
            </Sidebar> */}

            <SidebarWithHeader>
            { children } 
            </SidebarWithHeader>
		</>
	);
};

export default Layout;