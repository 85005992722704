/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "@chakra-ui/react";
import dataApi from "../data/dataApi";
import UserContext from "../auth/UserContext";
import { mockInventory } from "../data/mock";

export const InventoryContext = createContext({});

export function InventoryProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [mainFields, setMainFields] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);
  const [sortedFields, setSortedFields] = useState(["current_stock"]);
  const [sortBy, setSortBy] = useState("");

  const defaultFields = {
    mainColumn: [
      "checkbox",
      "code",
      "description",
      "sold",
      "current_stock",
      "supplier_name",
      "supplier_guid",
      "product_guid",
    ],
  };
  const [selectedColumn, setSelectedColumn] = useState({
    mainColumn: defaultFields.mainColumn,
  });

  // Context
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  // Functions
  const getInventoryData = async (days=14) => {
    try {
      if (process.env.REACT_APP_ENV === "local") {
        const data = mockInventory.products;
        setInventory(data);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.get(`/products/inventory/${days}`);
      setIsLoading(false);

      setInventory(res.data.products);
    } catch (error) {
      setIsLoading(false);
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };


  const onExportCsv = () => {

    if (inventory.length > 0) {
      // get table heading
      const tableHeading = selectedColumn.mainColumn;
      // get main table values
      const tableBody = inventory.map((list) => {
        return selectedColumn.mainColumn.map((item) => {
          return String(list[item]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody]);
    }
  };

  function sortInventory(orderBy, asc) {
    // Sort the items array based on the specified orderBy value
    const sortedItems = inventory.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return asc ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  
    setInventory(sortedItems);
  }

  // Wrapping the Stuff in a useMemo for Performance Reason
  const contextPayload = useMemo(
    () => ({
      defaultFields,
      isLoading,
      setIsLoading,
      inventory,
      setInventory,
      mainFields,
      setMainFields,
      selectedColumn,
      setSelectedColumn,
      csvData, 
      setCsvData,
      showExportCsvModal,
      setShowExportCsvModal,
      onExportCsv,
      getInventoryData,
      sortedFields,
      sortInventory
    }),
    [
      defaultFields,
      isLoading,
      setIsLoading,
      inventory,
      setInventory,
      mainFields,
      setMainFields,
      selectedColumn,
      setSelectedColumn,
      csvData, 
      setCsvData,
      showExportCsvModal,
      setShowExportCsvModal,
      onExportCsv,
      getInventoryData,
      sortedFields,
      sortInventory
    ]
  );

  return (
    <InventoryContext.Provider value={contextPayload}>
      {children}
    </InventoryContext.Provider>
  );
}

export const useInventory = () => useContext(InventoryContext);

export default InventoryContext;
