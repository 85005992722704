import React, { useState, useEffect, createContext } from "react";
import { isAuthenticated } from "./authService";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
	// Initialize the currentUser state with the stored user data
	const [currentUser, setCurrentUser] = useState(() => {
		const storedUser = localStorage.getItem("user");
		// Check if storedUser is not an empty string, and then parse the JSON
		return storedUser && storedUser !== "" ? JSON.parse(storedUser) : {};
	});

	const navigate = useNavigate();


	useEffect(() => {
		const checkLoggedIn = async () => {
			let cUser = isAuthenticated();
			if (cUser === null) {
				// Set localStorage "user" to a valid JSON value, like "null"
				localStorage.setItem("user", JSON.stringify(null));
				cUser = {};
			}
			setCurrentUser(cUser);
		};

		checkLoggedIn();
	}, [navigate]);

	// Add this useEffect hook to watch for changes in currentUser
	useEffect(() => {
		// Save the updated user object to local storage
		localStorage.setItem("user", JSON.stringify(currentUser));
	}, [currentUser]);

	console.log("usercontext", currentUser);

	return (
		<UserContext.Provider value={{ currentUser, setCurrentUser }}>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;