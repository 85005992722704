import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { usePurchaseReport } from "../../context/purchaseReportContext";

export function DeleteFilter({ isOpen, onClose }) {
  // Context
  const { onDeleteFilter, selectedFilter, setShowDeleteFilterModal } =
    usePurchaseReport();

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Filter</ModalHeader>
        <ModalCloseButton onClick={() => setShowDeleteFilterModal(false)} />
        <ModalBody pb={6}>
          <Text>
            Are you sure want to delete <b>{selectedFilter.name} filter</b>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setShowDeleteFilterModal(false);
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant={"outline"}
            colorScheme={"red"}
            size={"sm"}
            onClick={() => onDeleteFilter()}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteFilter;
