import { useContext } from "react";
import { Box, Button, Alert, VStack, Center } from "@chakra-ui/react";
import { AlertIcon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import UserContext from "../auth/UserContext";
import SubscriberLayout from "./SubscriberLayout";
import { InvoiceReports } from "../components/InvoiceReports/InvoiceReports";

export const Invoices = () => {
  // Context
  // const { currentUser } = useContext(UserContext);
  const currentUser = {token:"1234"};

  return (
    <>
      <SubscriberLayout>
        <Box alignContent={"baseline"}>
          {currentUser?.token ? (
            <InvoiceReports />
          ) : (
            <>
              <Center>
                <VStack spacing={6}>
                  <Alert status="warning">
                    <AlertIcon />
                    Seems your're not logged in, to see this page please log in.
                  </Alert>
                  <Box>
                    <Link to="/">
                      <Button variant="solid" colorScheme="teal">
                        Log in
                      </Button>
                    </Link>
                  </Box>
                </VStack>
              </Center>
            </>
          )}
        </Box>
      </SubscriberLayout>
    </>
  );
};

export default Invoices;
