import React from 'react';
import { Heading } from '@chakra-ui/react';

import Layout from './Layout';

const Terms = () => {
	return (
		<>
			<Layout>
            <Heading mt={10}>Terms&Conditions</Heading>
             {/* HTML with T&C markup */}
				<div className="terms-conditions">
					<h3>Terms and Conditions</h3>
					<ol>
						<li>
							<strong>Use of the App</strong>
							<p>Exacter.online is a web application that allows users to connect to their accounting
								software and create orders. By using Exacter.online, you acknowledge that you have read,
								understood, and agreed to these terms and conditions.</p>
						</li>
						<li>
							<strong>No Warranties</strong>
							<p>Exacter.online is provided "as is" and without warranty of any kind, whether express or
								implied. We do not guarantee the accuracy, timeliness, or completeness of the
								information provided by our app. We make no warranty that the app will meet your
								requirements or that it will be error-free, uninterrupted, or secure. You agree that
								your use of the app is at your own risk.</p>
						</li>
						<li>
							<strong>Verification of Orders</strong>
							<p>We expect our users to verify that orders generated by Exacter.online are correct before
								submitting them to their accounting software. We are not responsible for any errors or
								omissions in orders created using our app. You agree to indemnify and hold us harmless
								from any claims arising out of your use of the app or any orders created using the
								app.</p>
						</li>
						<li>
							<strong>Limitation of Liability</strong>
							<p>In no event shall we be liable for any direct, indirect, incidental, special, or
								consequential damages arising out of or in connection with your use of Exacter.online.
								You agree to indemnify and hold us harmless from any claims arising out of your use of
								the app or any orders created using the app.</p>
						</li>
						<li>
							<strong>Modification of Terms</strong>
							<p>We reserve the right to modify these terms and conditions at any time. Your continued use
								of Exacter.online after such modifications will constitute your agreement to the
								modified terms and conditions.</p>
						</li>
					</ol>
				</div>
			</Layout>
		</>
	);
};

export default Terms;