import React from 'react';

import Layout from './Layout';
import LoginForm from '../components/LoginForm';

const Login = () => {
	return (
		<>
			<Layout>
                <LoginForm />
			</Layout>
		</>
	);
};

export default Login;