import React, { useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useCustomerReport } from "../../context/customerReportContext";

export function LinesDataTable({ selectedLineIndex }) {
  // Context
  const { customerOrdersWithLines, selectedColumn, customerOrdersGroupped, showGroupped } = useCustomerReport();
  const [allLines, setAllLines] = useState(customerOrdersWithLines.map((item) => {
    return item.lines;
  }));

  useEffect(() => {
    if (showGroupped) {
      const allLines = customerOrdersGroupped.map((item) => {
        return item.lines;
      });
      setAllLines(allLines);
    }
    else {
      const allLines = customerOrdersWithLines.map((item) => {
        return item.lines;
      });
      setAllLines(allLines);
    }
  }, [showGroupped]);

  return (
    <Tr>
      <Td></Td>
      <Td colSpan={selectedColumn.mainColumn.length}>
        <Table bg={"#d9d9d9"}>
          <Thead position={"sticky"}>
            <Tr>
              {selectedColumn.lineColumn.map((item, index) => {
                return (
                  <Th key={index} textTransform={"none"}>
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody maxH={"20px"}>
            {allLines[selectedLineIndex].map((line, index) => {
              return (
                <Tr key={index}>
                  {selectedColumn.lineColumn.map((item) => {
                    return <Td key={item}>{line[item]}</Td>;
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Td>
    </Tr>
  );
}

export default LinesDataTable;
