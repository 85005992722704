import React from "react";
import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export default function MultiSelect({
  label,
  icon,
  items,
  selectedColumn,
  setSelectedColumn,
  defaultFields,
  searchBar,
  selectDefaultOption,
}) {
  return (
    <Menu closeOnSelect={false}>
      <HStack spacing={0}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          variant="ghost"
          _hover={{ bg: "none" }}
          _expanded={{ bg: "none" }}
          _focus={{ boxShadow: "none" }}
        >
          <HStack>
            {icon}
            <Text fontWeight={"light"}>{label}</Text>
          </HStack>
        </MenuButton>
      </HStack>
      <MenuList zIndex="dropdown" maxHeight={"40vh"} overflowY={"scroll"}>
        {/* show no data when nothing available */}
        {items.length === 0 && (
          <Text textAlign="start" pl={9} fontWeight={"semibold"}>
            No Data
          </Text>
        )}

        {/* show search bar */}
        {searchBar && searchBar()}

        {/* show reset button when data available */}
        {items.length > 0 && selectDefaultOption && (
          <HStack ml={3} my={2}>
            <CheckIcon fontSize={"sm"} />
            <Text
              fontWeight={"semibold"}
              fontSize={"sm"}
              onClick={() => setSelectedColumn(defaultFields)}
            >
              Select Default
            </Text>
          </HStack>
        )}

        {items.map((item, index) => {
          return (
            <MenuOptionGroup
              type="checkbox"
              value={item.checkedItems}
              onChange={(e) => {
                const operationalTable = Object.keys(selectedColumn)[index];
                setSelectedColumn({
                  ...selectedColumn,
                  ...{ [operationalTable]: e },
                });
              }}
              key={index}
            >
              <Text
                textAlign={"left"}
                fontSize={"sm"}
                textDecoration={"underline"}
                fontWeight={"semibold"}
                ml={3}
              >
                {item.title}
              </Text>
              {item.itemList.map((value) => {
                return (
                  <MenuItemOption value={value} key={value}>
                    {value}
                  </MenuItemOption>
                );
              })}
              <MenuDivider />
            </MenuOptionGroup>
          );
        })}
      </MenuList>
    </Menu>
  );
}
