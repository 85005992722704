import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Input, useDisclosure } from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import dataApi from "../../data/dataApi";
import UserContext from "../../auth/UserContext";
import InvoiceReportContext from "../../context/invoiceReportContext";
import { mockCustomerSearch, mockInvoices } from "../../data/mock";
import { GrEmptyCircle } from "react-icons/gr";
import DateRangePicker from "../DateRangePicker";
import { DownloadIcon } from "@chakra-ui/icons";
import { ExportFile} from "./ExportFile";

export function InvoiceReportsFilter() {
  // States
  const [invoiceSearchString, setInvoiceSearchString] = useState("");
  const [customerSearchString, setCustomerSearchString] = useState("");
  const [ selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  // Context
  const { currentUser } = useContext(UserContext);
  const { setInvoiceOrders, setInvoiceOrdersWithLines, setIsLoading, invoiceOrders, showExportCsvModal, setShowExportCsvModal, onExportCsv, startDate, endDate, setStartDate, setEndDate, showDateError, setShowDateError } = useContext(InvoiceReportContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(()=>{
    const loadInvoices = async () =>{
      if (invoiceOrders.length > 0) {
        return;
      }

      setIsLoading(true);

      dataApi.defaults.headers.common["Authorization"] =
          "Bearer " + currentUser?.token;

      let response;
      if (process.env.REACT_APP_ENV === "local") {
        response = {
          status: 200,
          data: mockInvoices,
        }
      } else {
        response = await dataApi.get(`/invoices`);
      }


      setIsLoading(false);
      if (response.status === 200) {
        setInvoiceOrders(
          response.data.data.map((list) => {
            return list.sales_invoice;
          })
        );
        setInvoiceOrdersWithLines(response.data.data);
      }
    }

    loadInvoices();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    dataApi.defaults.headers.common["Authorization"] =
      "Bearer " + currentUser?.token;
    let invoiceSearchRequest = `/invoices/search/${encodeURI(
      invoiceSearchString
    )}`;

    const params = {};

    if (selectedCustomer !== null && selectedCustomer.length > 0) {
      params.invoice_to = encodeURI(selectedCustomer[0].id);
    }

    if (startDate) {
      params.start_date = startDate;
    }

    if (endDate) {
      params.end_date = endDate;
    }

    dataApi
      .get(invoiceSearchRequest, {params})
      .then((response) => {
        if (response.status === 200) {
          setInvoiceOrders(
            response.data.data.map((list) => {
              return list.sales_invoice;
            })
          );
          setInvoiceOrdersWithLines(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("onInvoiceSearch error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (customerSearchString?.length > 0) {
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const customerSearchRequest = `/customer/search/${encodeURI(
        customerSearchString
      )}`;

      setIsSearchLoading(true);
      let req = setTimeout(() => {
        dataApi
          .get(customerSearchRequest)
          .then((response) => {
            console.log("onCustomerSearch response", response);
            if (response.status === 200) {
              setCustomerOptions(response.data.customers);
              setIsSearchLoading(false);
            }
          })
          .catch((error) => {
            setIsSearchLoading(false);
            setCustomerOptions(mockCustomerSearch.customers);
            console.log("onCustomerSearch error", error);
          });
      }, 1000);

      return () => clearTimeout(req);
    }
  }, [currentUser, customerSearchString]);

  return (
    <>
      {/* Filter Bar */}
      <form onSubmit={handleSubmit}>
      <Flex justifyContent={"space-between"} gap={3} w={"100%"} alignItems={"center"}>
          {/* Invoice Search */}
            <DateRangePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              showDateError={showDateError}
              setShowDateError={setShowDateError}
              showLabel={false}
            />

            <Input 
              w="100%"
              placeholder="Search Invoice"
              style={{
                fontSize: "13px",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
              size={"sm"}
              onChange={(ev) => setInvoiceSearchString(ev.target.value)}
              
            />

          <div style={{width:"100%"}}>
          <Multiselect
              options={customerOptions}
              selectedValues={selectedCustomer}
              onSelect={(value) => setSelectedCustomer(value)}
              onRemove={(value) => setSelectedCustomer(value)}
              onSearch={(value) => setCustomerSearchString(value)}
              loading={isSearchLoading}
              loadingMessage="Loading..."
              displayValue="name"
              placeholder="Search Customer"
              w={"100%"}
              style={{
                width: "100%",
                searchBox: {
                  fontSize: "13px",
                  backgroundColor: "white",
                  border: "none",
                },
                option: {
                  fontSize: "13px",
                },
                chips: {
                  fontSize: "10px",
                  background: "grey",
                  color: "white",
                  borderRadius: "0px",
                  padding: "0px 2px",
                },
              }}
            />  
            </div>    

            <Button
              type="submit"
              variant={"outline"}
              colorScheme={"teal"}
              size={"sm"}
              px={24}
            >
              Search
            </Button>      

            <Button
            variant={"solid"}
            colorScheme={"teal"}
            size={"sm"}
            px={24}
            leftIcon={<DownloadIcon />}
            onClick={() => {
              onExportCsv();
              setShowExportCsvModal(true);
              onOpen();
            }}
          >
            Export
          </Button>

      </Flex>
      </form>

      {/* Export File Modal*/}
      {showExportCsvModal && isOpen && (
        <ExportFile onClose={onClose} isOpen={isOpen} />
      )}

    </>
  );
}

export default InvoiceReportsFilter;
