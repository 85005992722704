import "./App.css";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import ConfirmLogin from "./pages/ConfirmLogin";
import Register from "./pages/Register";
import QuickOrders from "./pages/QuickOrders";
import Settings from "./pages/Settings";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
// Components
import Loading from "./components/Loading";
import PurchaseOrderReports from "./pages/PurchaseOrderReports";
import Invoices from "./pages/Invoices";
import Inventory from "./pages/Inventory";
import Customers from "./pages/Customers";

import CustomerOrderReports from "./pages/CustomerOrderReports";
import SubscriptionPayment from "./pages/SubscriptionPayment";
import QuickPurchases from "./pages/QuickPurchases";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/login/confirm" exact element={<ConfirmLogin />} />
        <Route path="/subscription/payment" exact element={<SubscriptionPayment />} />
        <Route path="/register" element={<Register />} />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/quick-orders"
          element={
            <Suspense fallback={<Loading />}>
              <QuickOrders />
            </Suspense>
          }
        />
        <Route
          path="/quick-purchases"
          element={
            <Suspense fallback={<Loading />}>
              <QuickPurchases />
            </Suspense>
          }
        />
        <Route
          path="/customers"
          element={
            <Suspense fallback={<Loading />}>
              <Customers />
            </Suspense>
          }
        />
        <Route
          path="/purchase-order-reports"
          element={
            <Suspense fallback={<Loading />}>
              <PurchaseOrderReports />
            </Suspense>
          }
        />
        <Route
          path="/customer-order-reports"
          element={
            <Suspense fallback={<Loading />}>
              <CustomerOrderReports />
            </Suspense>
          }
        />
        <Route
          path="/invoices"
          element={
            <Suspense fallback={<Loading />}>
              <Invoices />
            </Suspense>
          }
        />

        <Route
          path="/inventory"
          element={
            <Suspense fallback={<Loading />}>
              <Inventory />
            </Suspense>
          }
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </div>
  );
}
export default App;
