import { Center, VStack, Heading, Spinner } from "@chakra-ui/react";


const Loading = () => {
    return (
      <Center>
        <VStack spacing={2} p={5}>
          <Heading as="h6" size="md">Loading...</Heading>
          <Spinner size="md" />
        </VStack>
      </Center>
    );
}

export default Loading;