import React, { useEffect } from "react";
import { Box, Center } from "@chakra-ui/react";
import Spinner from "../Spinner";
import { useInvoiceReport } from "../../context/invoiceReportContext";
import InvoiceReportsFilter from "./InvoiceReportsFilter";
import InvoiceReportsTable from "./InvoiceReportsTable";

export const InvoiceReports = () => {

  // Context
  const { isLoading, invoiceOrders } = useInvoiceReport();
  return (
    <>
      {/* filter bar */}
      <Box bg={"#D9D9D9"} px={4} pt={3} mb={5} h={16} alignItems={"center"}>
        <InvoiceReportsFilter />
      </Box>

      {isLoading && (
        <Center>
          <Box>
            <br />
            <Spinner />
          </Box>
        </Center>
      )}



      {/* Main Data Table */}
      {!isLoading && invoiceOrders.length > 0 && <InvoiceReportsTable />}
    </>
  );
};

export default InvoiceReports;
