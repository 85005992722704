import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Input, useDisclosure } from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import dataApi from "../../data/dataApi";
import UserContext from "../../auth/UserContext";
import InvoiceReportContext, { useInvoiceReport } from "../../context/invoiceReportContext";
import { mockCustomerSearch, mockInvoices } from "../../data/mock";
import { GrEmptyCircle } from "react-icons/gr";
import DateRangePicker from "../DateRangePicker";
import { DownloadIcon } from "@chakra-ui/icons";
import { useCustomerReport } from "../../context/customerReportContext";
import CustomersContext, { useCustomers } from "../../context/customersContext";

export function CustomersFilter() {
  // States
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [customerSearchString, setCustomerSearchString] = useState("");
    const { currentUser } = useContext(UserContext);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);
    const { getCustomerOrderData, setCustomerOrders, setCustomerOrdersWithLines, showExportCsvModal, setShowExportCsvModal, onExportCsv, startDate, endDate, setStartDate, setEndDate, showDateError, setShowDateError, customerOrdersGroupped, setCustomerOrdersGroupped, calculateTotalQuantities } = useCustomerReport();
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const {setCustomer, customer} = useCustomers();
    const { setInvoiceOrders, setInvoiceOrdersWithLines } = useContext(InvoiceReportContext);
    const { setIsLoading } = useContext(CustomersContext);

  useEffect(() => {
    if (customerSearchString?.length > 0) {
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const customerSearchRequest = `/customer/search/${encodeURI(
        customerSearchString
      )}`;

      setIsSearchLoading(true);
      let req = setTimeout(() => {
        dataApi
          .get(customerSearchRequest)
          .then((response) => {
            console.log("onCustomerSearch response", response);
            if (response.status === 200) {
              setCustomerOptions(response.data.customers);
              setIsSearchLoading(false);
            }
          })
          .catch((error) => {
            setIsSearchLoading(false);
            setCustomerOptions(mockCustomerSearch.customers);
            console.log("onCustomerSearch error", error);
          });
      }, 1000);

      return () => clearTimeout(req);
    }
  }, [currentUser, customerSearchString]);
  
  useEffect(() => {
    if (selectedCustomer.length > 0) {
      setCustomer(selectedCustomer[0]);
    }
  }, [selectedCustomer]);

  const loadInvoices = async () => {
    dataApi.defaults.headers.common["Authorization"] =
      "Bearer " + currentUser?.token;
    let invoiceSearchRequest = `/invoices/search/`;

    const params = {};
    params.invoice_to = customer.id;
    if (startDate) {
      params.start_date = startDate;
    }

    if (endDate) {
      params.end_date = endDate;
    }

    try {
      const response = await dataApi.get(invoiceSearchRequest, {params});
      if (response.status === 200) {
        setInvoiceOrders(
          response.data.data.map((list) => {
            return list.sales_invoice;
          })
        );
        setInvoiceOrdersWithLines(response.data.data);
      }
    }
    catch (error) {
      setInvoiceOrders(
        mockInvoices.data.map((list) => {
          return list.sales_invoice;
        })
      );
    }
  };

  useEffect(() => {
    handleSubmit();
    if (!startDate) {
      setStartDate((new Date(new Date - (86400000 * 30))).toISOString().split('T')[0]);
    }

    if (!endDate) {
      setEndDate(new Date().toISOString().split('T')[0]);
    }
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [customer]);

  const loadCustomerOrders = async () => {
      await getCustomerOrderData(customer.id, startDate, endDate)
  };

  const handleSubmit = async (e) => {
      if (customer != null) {
        setIsLoading(true);
        await loadInvoices();
        await loadCustomerOrders();
        setIsLoading(false);
      }
      setIsLoading(false);
  }

  return (
    <>
      {/* Filter Bar */}
      <form onSubmit={(e) => {e.preventDefault();handleSubmit();}}>
      <Flex justifyContent={"space-between"} gap={3} w={"100%"} alignItems={"center"}>
          {/* Invoice Search */}
            <DateRangePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              showDateError={showDateError}
              setShowDateError={setShowDateError}
              showLabel={false}
            />

          <div style={{width:"100%"}}>
          <Multiselect
              options={customerOptions}
              selectedValues={selectedCustomer}
              onSelect={(value) => setSelectedCustomer(value)}
              onRemove={(value) => setSelectedCustomer(value)}
              onSearch={(value) => setCustomerSearchString(value)}
              loading={isSearchLoading}
              loadingMessage="Loading..."
              displayValue="name"
              placeholder="Search Customer"
              w={"100%"}
              style={{
                width: "100%",
                searchBox: {
                  fontSize: "13px",
                  backgroundColor: "white",
                  border: "none",
                },
                option: {
                  fontSize: "13px",
                },
                chips: {
                  fontSize: "10px",
                  background: "grey",
                  color: "white",
                  borderRadius: "0px",
                  padding: "0px 2px",
                },
              }}
            />  
            </div>    

            <Button
              type="submit"
              variant={"outline"}
              colorScheme={"teal"}
              size={"sm"}
              px={24}
            >
              Search
            </Button>      


      </Flex>
      </form>

    </>
  );
}

export default CustomersFilter;
