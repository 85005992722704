import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import {
  AttachmentIcon,
  ChevronDownIcon,
  DeleteIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import DateRangePicker from "../DateRangePicker";
import { usePurchaseReport } from "../../context/purchaseReportContext";
import ExportFile from "./ExportFile";
import SaveFilter from "./SaveFilter";
import dataApi from "../../data/dataApi";
import UserContext from "../../auth/UserContext";
import DeleteFilter from "./DeleteFilter";
import { mockPurchaseOrders } from "../../data/mock";



export function PurchaseReportsFilter() {

  // State
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [supplierSearchString, setSupplierSearchString] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  // Context
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useContext(UserContext);
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    showDateError,
    setShowDateError,
    getPurchaseOrderData,
    onExportCsv,
    selectedFilter,
    onSaveFilter,
    onLoadFilter,
    availableFilter,
    getAvailableFilter,
    showSaveFilterModal,
    setShowSaveFilterModal,
    showDeleteFilterModal,
    setShowDeleteFilterModal,
    showExportCsvModal,
    setShowExportCsvModal,
    savedSuppliers,
    customerOrdersGroupped, 
    setCustomerOrdersGroupped,
    calculateTotalQuantities,
    setPurchaseOrdersWithLines,
    setPurchaseOrdersGroupped,
    setPurchaseOrders
  } = usePurchaseReport();

  // Set suppliers on loading filter
  useEffect(() => {
    console.log("Inside Efffect: ", savedSuppliers);
    setSelectedSuppliers(savedSuppliers);
  }, [savedSuppliers]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV == "local") {
      let data = mockPurchaseOrders.data;
      setPurchaseOrders(
        data.map((list) => {
          return list.purchase_order;
        })
      );
      setPurchaseOrdersWithLines(data);      
      setPurchaseOrdersGroupped(calculateTotalQuantities(data));
    }
  }, []);

  // Supplier Search onChange
  useEffect(() => {
    if (supplierSearchString?.length > 0) {
      setIsSearchLoading(true);
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const customerSearchRequest = `/supplier/search/${encodeURI(
        supplierSearchString
      )}`;

      let req = setTimeout(() => {
        dataApi
          .get(customerSearchRequest)
          .then((response) => {
            console.log("onSupplierSearch response", response);
            if (response.status === 200)
              setSupplierOptions(response.data.suppliers);
              setIsSearchLoading(false);
          })
          .catch((error) => {
            console.log("onCustomerSearch error", error);
            setIsSearchLoading(false);
          });
      }, 1000);

      return () => clearTimeout(req);
    }
  }, [currentUser, supplierSearchString]);

  // Hooks
  useEffect(() => {
    getAvailableFilter("purchase_order");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <>
      {/* Filter Bar */}
      <Flex justifyContent={"space-between"} alignItems={"center"} pb={3}>
        <HStack spacing={3}>
          {/* Date Range Picker */}
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            showDateError={showDateError}
            setShowDateError={setShowDateError}
          />

          {/* Supplier Search */}
          <Box>
            <Multiselect
              options={supplierOptions}
              selectedValues={selectedSuppliers}
              onSelect={(value) => setSelectedSuppliers(value)}
              onRemove={(value) => setSelectedSuppliers(value)}
              onSearch={(value) => setSupplierSearchString(value)}
              displayValue="name"
              placeholder="Search Supplier"
              loading={isSearchLoading}
              loadingMessage="Loading..."
              style={{
                searchBox: {
                  fontSize: "13px",
                  backgroundColor: "white",
                  border: "none",
                },
                option: {
                  fontSize: "13px",
                },
                chips: {
                  fontSize: "10px",
                  background: "grey",
                  color: "white",
                  borderRadius: "0px",
                  padding: "0px 2px",
                },
              }}
            />
          </Box>

          {/* Generate Report */}
          <Button
            type="submit"
            variant={"outline"}
            colorScheme={"teal"}
            size={"sm"}
            onClick={() => {
              getPurchaseOrderData(
                startDate,
                endDate,
                selectedSuppliers.map((item) => item.id)
              );
            }}
          >
            Generate Report
          </Button>

          {/* Load Filter */}
            <Menu>
              <MenuButton
                as={Button}
                size={"sm"}
                variant={"outline"}
                colorScheme="teal"
                _hover={{ bg: "none" }}
                _expanded={{ bg: "none" }}
                _focus={{ boxShadow: "none" }}
                rightIcon={<ChevronDownIcon />}
              >
                Select Filter
              </MenuButton>
              <MenuList
                zIndex="dropdown"
                maxHeight={"40vh"}
                overflowY={"scroll"}
              >
                {availableFilter.length > 0 &&
                  availableFilter.map((filter, index) => {
                    return (
                      <MenuItem
                        minH="40px"
                        key={index}
                        onClick={() => onLoadFilter(filter.id)}
                      >
                        <HStack>
                          <span>{filter.name}</span>
                          {selectedFilter.id === filter.id && (
                            <>
                              <Spacer />
                              <DeleteIcon
                                color={"red.500"}
                                onClick={() => {
                                  setShowDeleteFilterModal(true);
                                  onOpen();
                                }}
                              />
                            </>
                          )}
                        </HStack>
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </Menu>
         
        </HStack>

        <HStack spacing={2}>
          {/* Save Filter */}

          {Object.keys(selectedFilter).length > 0 ? (
            <>
              <Button
                variant={"solid"}
                colorScheme={"teal"}
                size={"sm"}
                leftIcon={<AttachmentIcon />}
                onClick={() => {
                  onSaveFilter({
                    values: {
                      filterId: selectedFilter.id,
                      filterName: selectedFilter.name,
                      filterType: selectedFilter.type,
                    },
                    suppliers: selectedSuppliers,
                  });
                }}
              >
                Update Filter
              </Button>
            </>
          ) : (
            <Button
              variant={"solid"}
              colorScheme={"teal"}
              size={"sm"}
              leftIcon={<AttachmentIcon />}
              onClick={() => {
                setShowSaveFilterModal(true);
                onOpen();
              }}
            >
              Save Filter
            </Button>
          )}

          {/* Export Datatable to CSV */}
          
          <Button
            variant={"solid"}
            colorScheme={"teal"}
            size={"sm"}
            mr={4}
            leftIcon={<DownloadIcon />}
            onClick={() => {
              onExportCsv();
              setShowExportCsvModal(true);
              onOpen();
            }}
          >
            Export
          </Button>
        </HStack> 
      </Flex>

      {/* Export File Modal*/}
      {showExportCsvModal && isOpen && (
        <ExportFile onClose={onClose} isOpen={isOpen} />
      )}

      {/* Save Filter Modal*/}
      {showSaveFilterModal && isOpen && (
        <SaveFilter
          onClose={onClose}
          isOpen={isOpen}
          suppliers={selectedSuppliers}
        />
      )}

      {/* Delete Filter Modal*/}
      {showDeleteFilterModal && isOpen && (
        <DeleteFilter onClose={onClose} isOpen={isOpen} />
      )}
    </>
  );
}

export default PurchaseReportsFilter;
