import React, { useContext, useEffect, useState } from "react";
import { SiStripe } from 'react-icons/si'
import {
  Box,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
import dataApi from "../data/dataApi";
import { PricingCard } from "./Settings/Pricing/PricingCard";
import { ActionButton } from "./Settings/Pricing/ActionButton";
import UserContext from "../auth/UserContext";

const SubscriptionList = (props) => {
  const [ plans, setPlans ] = useState([]);
  const { currentUser } = useContext(UserContext);
  useEffect(() => {
    dataApi.get("/subscriptions/plans").then((response) => {
      setPlans(response.data);
    });
  }, []);

  const subscribe = async () => {
    const response = await dataApi.post("/subscriptions", {
      "type": "monthly"
    });
    
   window.location.href = response.data.url;
  }
  

  return (
    <>
    <Box
    as="section"
    bg="transparent"
    w={props.w || "600px"}
    pl={20}
  >
    <SimpleGrid
      columns={{ base: 1, lg: 3 }}
      spacing={{ base: '8', lg: '0' }}
      maxW="7xl"
      mx="auto"
      justifyItems="center"
      alignItems="center"
      backgroundColor="transparent"
    >
      <PricingCard
        w="max-content"
        data={{
          price: '€ ' + (plans[0?.price_monthly] ? plans[0]?.price_monthly : "29.95"),
          name: plans[0]?.description,
          features: [
            'Monthly access',
            'Use on unlimited projects',
            'Free Updates',
          ],
        }}
        icon={SiStripe}
        button={
          <ActionButton onClick={subscribe} variant="outline" borderWidth="2px">
            Buy now
          </ActionButton>
        }
      />
    </SimpleGrid>
    </Box>
    </>
  );
};

export default SubscriptionList;
