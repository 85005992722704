import {useContext, useState} from "react";
import {Card, VStack, Box, Heading, Center, Text, Input, FormLabel, FormControl, Button, useToast } from "@chakra-ui/react"
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dataApi from "../data/dataApi";
import { useAlertContext } from "../context/alertContext";

function TwoFaForm() { 
    const [code, setCode] = useState("");
    const toast = useToast();
    const navigate = useNavigate();
    const { onOpen } = useAlertContext();

    const onSubmit = async (e) => {
        e.preventDefault();

        dataApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("key");
        try {
          const response = await dataApi.post(`/user/confirmed-two-factor-authentication`, {
              code: code,
          });
            
          const token = response.data.data.token;
          if (token) {
              localStorage.removeItem("key");
              localStorage.setItem("user", JSON.stringify(response.data.data));
              onOpen('success', "You've been successfully logged in!");
              navigate("/quick-orders");
          }
        } catch (ex) {
          toast({
            title: "Invalid code",
            colorScheme: "red",
            type: "error",
            duration: 5000,
            position: "top",
          });
        }
        
    }

  return (
    <Center>
     <Card m={4}>
      <VStack w="600px" p={16} alignItems="center">
          <Heading as="h5" ali size="lg" id="login-form">exacter.online</Heading>
          <Text>Please enter your 2FA verification code</Text>

        <Box p={6} rounded="md" w="100%">
        
        <form onSubmit={onSubmit}>
            <FormControl isRequired>
                <FormLabel>Code</FormLabel>
                <Input placeholder='Code' onChange={(e) => setCode(e.target.value)} />
            </FormControl>

            <Button
                colorScheme="teal"
                rounded="md"
                w="full"
                mt={3}
                type="submit"
            >
                Submit
            </Button>
        </form>
          
        </Box>
      </VStack>
      </Card>
    </Center>
  );
};

export default TwoFaForm;