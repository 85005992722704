import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  Legend,
  Tooltip,
} from 'chart.js';
import {
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from 'react-chartjs-2';
import CustomerReportContext from '../../context/customerReportContext';
import InvoiceReportContext from '../../context/invoiceReportContext';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  Legend,
  Tooltip
);



export const options = {
  maintainAspectRatio: false,
};


export function CustomerOrdersChart() {

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const { customerOrders, startDate, endDate } = useContext(CustomerReportContext);
  const { invoiceOrders } = useContext(InvoiceReportContext);

  const [customerOrdersData, setCustomerOrdersData] = useState([]);
  const [salesInvoiceData, setSalesInvoiceData] = useState([]);

  const [labels, setLabels] = useState([]);
  
  useEffect(() => { 

    const parsedStartData = new Date(startDate);
    const parsedEndData = new Date(endDate);

    const isWithin30Days = isDatesWithin30Days(parsedStartData, parsedEndData);

    if (isWithin30Days) {
      const grouppedOrders = ordersByWeek(customerOrders);
      setCustomerOrdersData(grouppedOrders);
      const labels = Object.keys(grouppedOrders).map((key) => key);
      setLabels(labels);
    }

    else {
      const grouppedOrders = ordersByMonth(customerOrders);
      setCustomerOrdersData(grouppedOrders);
      const labels = Object.keys(grouppedOrders).map((key) => key);
      setLabels(labels);
    }
  }, [customerOrders]);


  useEffect(() => { 
    const parsedStartData = new Date(startDate);
    const parsedEndData = new Date(endDate);

    const isWithin30Days = isDatesWithin30Days(parsedStartData, parsedEndData);

    if (isWithin30Days) {
      setSalesInvoiceData(ordersByWeek(invoiceOrders));
    }

    else {
      setSalesInvoiceData(ordersByMonth(invoiceOrders));
    }
  }, [invoiceOrders]);



  function isDatesWithin30Days(startDate, endDate) {
    const timeDifference = Math.abs(endDate - startDate);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference <= 30;
  }


  const ordersByWeek = (orders) => {
    const currentDate = new Date(endDate);
    const thisWeekStart = getWeekStart(currentDate);
    const lastWeekStart = getWeekStart(subtractWeeks(currentDate, 1));
    const weekBeforeLastStart = getWeekStart(subtractWeeks(currentDate, 2));
    const weekBeforeThatStart = getWeekStart(subtractWeeks(currentDate, 3));
  
    const groupedOrders = orders.reduce((result, order) => {
      const orderDate = parseDate(order.Created);
      const weekStart = getWeekStart(orderDate);
      const weekKey = formatDate(weekStart);
  
      if (result[weekKey]) {
        result[weekKey].totalOrders += order.AmountFCExclVat;
      } else {
        result[weekKey] = {
          weekStart,
          totalOrders: order.AmountFCExclVat,
        };
      }
  
      return result;
    }, {});
  
    return {
      [formatDate(weekBeforeThatStart)]: groupedOrders[formatDate(weekBeforeThatStart)] || { weekStart: weekBeforeThatStart, totalOrders: 0 },
      [formatDate(weekBeforeLastStart)]: groupedOrders[formatDate(weekBeforeLastStart)] || { weekStart: weekBeforeLastStart, totalOrders: 0 },
      [formatDate(lastWeekStart)]: groupedOrders[formatDate(lastWeekStart)] || { weekStart: lastWeekStart, totalOrders: 0 },
      [formatDate(thisWeekStart)]: groupedOrders[formatDate(thisWeekStart)] || { weekStart: thisWeekStart, totalOrders: 0 },
    };
  };
  
  function parseDate(dateString) {
    const [day, month, year] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }
  
  function getWeekStart(date) {
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    return new Date(date.getFullYear(), date.getMonth(), diff);
  }
  
  function subtractWeeks(date, weeks) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - weeks * 7);
    return newDate;
  }
  
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }


  
  const ordersByMonth = (orders) => {
    const currentDate = new Date(endDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
  
    const groupedOrders = orders.reduce((result, order) => {
      const orderDate = parseDate(order.Created);
      const orderYear = orderDate.getFullYear();
      const orderMonth = orderDate.getMonth() + 1;
      const monthKey = formatMonth(orderYear, orderMonth);
  
      if (result[monthKey]) {
        result[monthKey].totalOrders += order.AmountFCExclVat;
      } else {
        result[monthKey] = {
          monthStart: new Date(orderYear, orderMonth - 1, 1),
          totalOrders: order.AmountFCExclVat,
        };
      }
  
      return result;
    }, {});
  
    const monthKeys = getMonthKeys(currentYear, currentMonth);
  
    const resultObject = {};
    monthKeys.forEach((key) => {
      resultObject[key] = groupedOrders[key] || { monthStart: new Date(key), totalOrders: 0 };
    });
  
    return resultObject;
  };
  
  function parseDate(dateString) {
    const [day, month, year] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }
  
  function formatMonth(year, month) {
    return `${monthNames[month - 1]} ${year}`;
  }
  function getMonthKeys(currentYear, currentMonth) {
    const monthKeys = [];
    for (let i = 0; i < 12; i++) {
      const year = currentMonth - i <= 0 ? currentYear - 1 : currentYear;
      const month = ((currentMonth - i - 1) + 12) % 12 + 1;
      monthKeys.push(formatMonth(year, month));
    }
    return monthKeys;
  }
  
  

  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Customer orders per month',
        borderColor: 'blue',
        borderWidth: 2,
        fill: false,
        data: Object.values(customerOrdersData).map((value) => value.totalOrders),
        maintainAspectRatio: false
      },
      {
          type: 'line',
          label: 'Sales invoices per month',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: Object.values(salesInvoiceData).map((value) => value.totalOrders),
          maintainAspectRatio: false
        },
    ],
  };


  const printDatasetAtEvent = (dataset) => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0].datasetIndex;

    console.log(data.datasets[datasetIndex].label);
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    console.log(data.labels[index], data.datasets[datasetIndex].data[index]);
  };

  const printElementsAtEvent = (elements) => {
    if (!elements.length) return;

    console.log(elements.length);
  };

  const chartRef = useRef(null);

  const onClick = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    printDatasetAtEvent(getDatasetAtEvent(chart, event));
    printElementAtEvent(getElementAtEvent(chart, event));
    printElementsAtEvent(getElementsAtEvent(chart, event));
  };

  return (
    <Chart
      ref={chartRef}
      type='bar'
      onClick={onClick}
      options={options}
      data={data}
    />
  );
}

export default CustomerOrdersChart;