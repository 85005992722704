import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
	const [ currentCustomer, setCurrentCustomer] = useState({});
    const navigate = useNavigate();

	useEffect(() => {
		// const checkLoggedIn = async () => {
		// 	let cUser = isAuthenticated();
		// 	if (cUser === null) {
		// 		localStorage.setItem('user', '');
		// 		cUser = '';
		// 	}
		// 	setCurrentUser(cUser);
		// };

		// checkLoggedIn();
	}, [navigate]);


	return (
		<CustomerContext.Provider value={[currentCustomer, setCurrentCustomer]}>
            {children}
		</CustomerContext.Provider>
	);
};


export default CustomerContext;