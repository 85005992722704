import React, { ReactNode } from 'react';
import {
  IconButton,
  Avatar,
  AvatarBadge,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  Tooltip
} from '@chakra-ui/react';

import {
  FiBell,
  FiGlobe,
  FiServer,
  FiZapOff,
  FiZap,
  FiChevronDown,
  FiFileText,
  FiCommand,
} from 'react-icons/fi';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiDollarSign,
    FiUser,
    FiUsers,
    FiPenTool,
    FiBox
  } from 'react-icons/fi';
import { AiOutlineFileText } from 'react-icons/ai';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Link as RouterLink, useNavigate, redirect, useLocation } from 'react-router-dom'
import { useContext } from "react";
import UserContext from "../auth/UserContext"

const LinkItems = [
    { name: 'Quick Orders', icon: FiDollarSign, to: '/quick-orders' },
    { name: 'Quick Purchases', icon: FiCommand, to: '/quick-purchases' },
    { name: 'Customers', icon: FiUsers, to:'/customers'},
    { name: 'Purchase Reports', icon: FiTrendingUp, to:'/purchase-order-reports'},
    { name: 'Customers Orders', icon: FiUsers, to:'/customer-order-reports'},
    { name: 'Invoices', icon: AiOutlineFileText, to:'/invoices'},
    { name: 'Inventory', icon: FiBox, to:'/inventory'},
    // { name: 'Explore', icon: FiCompass },
    // { name: 'Favourites', icon: FiStar },
    { name: 'Settings', icon: FiUser, to: '/settings' },
  ];
  

export default function SidebarWithHeader({children}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const topNavBarLeftMargin = currentUser?.name ? { base: 0, md: 60 } : { base: 0, md: 0 };
  
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
    { currentUser?.name && (
      <>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      </>)
    }
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />

      <Box ml={topNavBarLeftMargin} p="4">
        {children}
      </Box>
    </Box>
  );
}


const SidebarContent = ({ onClose, ...rest }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const location = useLocation();
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold">
            <Link href={"https://exacter.online"}>exacter.online</Link>
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex> 
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} to={link.to} active={location.pathname === link.to}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, to, active, ...rest }) => {
  return (
    <Link as={RouterLink} to={to} style={{ textDecoration: 'none' }} backgroundColor={active && "teal.500"} color={active && "white"} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        backgroundColor={active && "teal.500"}
        color={active && "white"}
        mb={2}
        _hover={{
          bg: 'teal.500',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};


const MobileNav = ({ onOpen, ...rest }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const topNavBarLeftMargin = currentUser?.name ? { base: 0, md: 60 } : { base: 0, md: 0 };
  const isLight = true;

  console.log("Sidebar", currentUser);

  const handleLogOut = () => {
      localStorage.removeItem("user");
      setCurrentUser({});
      navigate("/");
  };

  return (
    <>
      <Flex
        ml={topNavBarLeftMargin}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={isLight ? "white" : "gray.900"}
        borderBottomWidth="1px"
        borderBottomColor={isLight ? "gray.200" : "gray.700"}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
          <Text><Link href={"https://exacter.online"}>exacter.online</Link> |</Text>
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontWeight="bold"
        >
         exacter.online
        </Text>

        <HStack spacing={{ base: "0", md: "6" }}>

        { currentUser?.name ?
            <Tooltip label={currentUser?.connected ? "Connected to Exact online" : "Not Connected to Exact online"}>
          <IconButton
          variant="ghost"
          aria-label="open menu"
          color={currentUser?.connected ? 'green.300':'red.300'}
          icon={currentUser?.connected ? <FiZap /> : <FiZapOff />}
          onClick={() => {
              navigate("/settings");
          }}
        />
         </Tooltip> : null }
          <Flex alignItems={"center"}>
            {currentUser?.name ? (
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: "none" }}
                >
                  <HStack>
                 
                      <Avatar size={"sm"} name={currentUser.name}>      
                        <AvatarBadge boxSize="1.25em" bg={currentUser?.connected ? "green" : "red"} />
                        </Avatar>
                    <VStack
                      display={{ base: "none", md: "flex" }}
                      alignItems="flex-start"
                      spacing="1px"
                      ml="2"
                    >
                      <Text fontSize="sm">{currentUser.name}</Text>
                      <Text fontSize="xs" color="gray.600">
                        {currentUser.email}
                      </Text>
                    </VStack>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>
                {/* <MenuList
              bg={useColorModeValue('white', 'gray.900')}
               borderColor={useColorModeValue('gray.200', 'gray.700')}> */}
                <MenuList
                  bg={isLight ? "white" : "gray.900"}
                  borderColor={isLight ? "gray.200" : "gray.700"}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <>
                <Link as={RouterLink} to="/login">
                  <Button variant="ghost" colorScheme="teal">
                    Sign in
                  </Button>
                </Link>
                <Link as={RouterLink} to="/register">
                  <Button variant="outline" colorScheme="teal">
                    Sign up
                  </Button>
                </Link>
              </>
            )}
          </Flex>
        </HStack>
      </Flex>
    </>
  );
};