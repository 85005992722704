import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Stack,
  HStack,
  StackDivider,
  IconButton,
  Spacer,
  Center,
  Spinner,
  Avatar
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { customerTopProducts, customerProducts, mockCustomerSearch, mockCustomer, mockSearchProducts } from "../data/mock";

import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon, AddIcon, MinusIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";

// API Generate Order mockup
import { usePostOrder } from "../hooks/useSubmit";
// Alerts
import {useAlertContext} from "../context/alertContext";
import dataApi from "../data/dataApi";
import { useContext } from "react";
import UserContext from '../auth/UserContext';
import axios from "axios";

// dynamic selects
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Badge } from '@chakra-ui/react';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CustomerContext from '../data/CustomerContext';
import Loading from "./Loading";
import ProductsContext from "../data/ProductsContext";

// Components
import InfoBox from "./InfoBox";

function DropZonePlaceholder(props) {
  return (
    <VStack
      spacing={3}
      border="dotted"
      borderColor="gray.500"
      borderRadius="lg"
      width="full"
      height={props.h || "full"}
      padding={props.p || 10}
    >
      <AddIcon />
      <Text>Drag and drop products here</Text>
    </VStack>
  );
}

function CustomerBadge({customer}) {
  return (
    <Stack direction='row' spacing={1} align='center'>
    {customer?.name ? (<><Badge variant='outline' colorScheme='green'>{customer.code} - {customer.name}</Badge><Avatar size='xs' bg="teal.500" /></>) : (<><Badge>Not selected</Badge><Avatar size='xs'/></>)}
    </Stack>
  )
}

function CustomerSearchAutocomplete() {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [ searchString, setSearchString] = useState("");
  const [ searchItems, setSearchItems ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const [ currentCustomer, setCurrentCustomer ] = useContext(CustomerContext);

  const [ tmpSearchString, setTmpSearchString ] = useState("");
  const [ updateSearch, setUpdateSearch ] = useState(true);
  const [ showNoResults, setShowNoResults ] = useState(false);
  const [ loadingStyles, setLoadingStyles ] = useState("");

  useEffect(() => { 
    if (process.env.REACT_APP_ENV == "local") {
      setSearchItems(mockCustomerSearch.customers);
      return;
    }

    if (tmpSearchString !== "") {
        setSearchString(tmpSearchString);
        setTmpSearchString("");
        setUpdateSearch(false);
        return;
    }

    if (!updateSearch) {
      setUpdateSearch(true);
      setShowNoResults(true);
      return;
    }

    dataApi.defaults.headers.common["Authorization"] =
      "Bearer " + currentUser?.token;
    const customerSearchRequest = `/customer/search/${encodeURI(searchString)}`;
    
    if (searchString?.length > 0) {
      setIsLoading(true);

      dataApi
        .get(customerSearchRequest)
        .then((response) => {
          console.log("onCustomerSearch response", response);
          if (response.status === 200) setSearchItems(response.data.customers);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("onCustomerSearch error", error);
          setIsLoading(false);
        });

    }
  }, [searchString]);
  
  useEffect(() => {
      setTmpSearchString(searchString);
      setSearchString("");
  }, [searchItems]);

  useEffect(() => {
    setLoadingStyles(isLoading ? `
      ul {
        display: none;
      }
    ` : "");
  }, [isLoading]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results. 
    setShowNoResults(false);
    setSearchString(string);
    
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
    
  }

  const handleOnSelect = (item) => {
    // the item selected
    if (item?.code)
      setCurrentCustomer(item);
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.code} – {item.name}</span>
      </>
    )
  }

  return (
    <>
    <Box>
      <style dangerouslySetInnerHTML={{ __html: loadingStyles }} />
        <ReactSearchAutocomplete
          items={searchItems}
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          autoFocus
          formatResult={formatResult}
          fuseOptions={{ keys: ["name", "code"], threshold: 0.8, ignoreLocation: true }}
          resultStringKeyName="name"
          inputSearchString={searchString}
          showNoResults={showNoResults}
          placeholder="Search Customer"
          inputDebounce={500}
          styling={{
            height: "32px",
            fontSize: "14px",
            borderRadius: "2px",
            zIndex: "100"
          }}
        />
    </Box>
    { isLoading && <Box backgroundColor="white" padding={3}>Loading...</Box>}
    </>
  );
}

function SelectedCustomer() {
  const customerSearch = useRef(null);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [ currentCustomer, setCurrentCustomer ] = useContext(CustomerContext);

  return (
    <>
      <Box mb={5}>
        <CustomerSearchAutocomplete />
      </Box>

      <Card variant="outline" zIndex={0}>
        <CardHeader>
          <Heading size="sm">Selected Customer</Heading>
        </CardHeader>
        <CardBody>
          {currentCustomer?.code ? (
            <Badge
              variant="outline"
              ml={1}
              fontSize="1.0em"
            >{`${currentCustomer.code} - ${currentCustomer.name}`}</Badge>
          ) : (
            <InfoBox>
              <Text>Please find a Customer using the Search prompt</Text>
            </InfoBox>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export function TopProductsList({ sourceItems }) {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [ currentCustomer, setCurrentCustomer ] = useContext(CustomerContext);
  const [ customerProducts, setCustomerProducts, sourceProducts, setSourceProducts, selectedProducts ] = useContext(ProductsContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const searchPlaceholder =  "Search All Products";
  const [ loadAllProducts, setLoadAllProducts ] = useState(false);

  const [ filter, setFilter ] = useState('');

  useEffect(() => {
    console.log("useEffect for selected Customer");
    if (currentCustomer?.id) {
      // top Products API call
      if(process.env.REACT_APP_ENV == "local") {
        setCustomerProducts(mockCustomer.top_products);
        setSourceProducts(mockCustomer.top_products.map(item => ({...item, visible: true})));
        return;
      }
      console.log("topProductsSearch call for a Customer", currentCustomer?.id);
      dataApi.defaults.headers.common["Authorization"] = "Bearer " + currentUser?.token;
      const customerSearchRequest = `/customer/${currentCustomer.id}`;
      setIsLoading(true);
      dataApi.get(customerSearchRequest).then((response) => {
        console.log("topProductsSearch response", response);
        if (response.status === 200) {
          console.log(response.data.top_products);
          setCustomerProducts(response.data.top_products);
          setSourceProducts(response.data.top_products.map((item) => ({...item, visible: true})));
        }
        setIsLoading(false);
      });
    }
  }, [currentCustomer.id]);

  useEffect(() => {
      const tmp = [...sourceProducts];
      tmp.forEach((sourceProduct) => {
          const found = selectedProducts.find((selectedProduct) => selectedProduct.item_id === sourceProduct.item_id);
          sourceProduct.visible = !found;
      });
      setSourceProducts(tmp);
  }, [selectedProducts]);

  function arrayUniqueProducts(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i].code === a[j].code) a.splice(j--, 1);
      }
    }
    return a;
  }

  function concatArraysWithoutDuplicateCode(arr1, arr2) {
    const codesSet = new Set(arr1.map(item => item.code));
    const result = [...arr1];
  
    for (const item of arr2) {
      if (!codesSet.has(item.code)) {
        result.push(item);
        codesSet.add(item.code);
      }
    }
  
    return result;
  }

  useEffect(() => {
    console.log('useEffect Products Filter');
    console.log('filter', filter);
    console.log('sourceProducts:before', customerProducts);

    let newProductsList = customerProducts.filter((sourceProduct) => {
        const found = selectedProducts.find((selectedProduct) => selectedProduct.item_id === sourceProduct.item_id);
        sourceProduct.visible = !found;
        return !found;
    });
    // return back Top products if filter is cleared
    if (filter.length === 0) {
      console.log("filter is cleared, showing Top products again");
      setSourceProducts(newProductsList);
    } else {
      // filter Top products
      newProductsList = newProductsList.filter(
        (item) =>
          item.description.toLowerCase().includes(filter.toLowerCase()) ||
          item.code.toLowerCase().includes(filter.toLowerCase())
      );
      console.log("sourceProducts:after", newProductsList);
      setSourceProducts(newProductsList);

      if (loadAllProducts) {
        if (currentCustomer?.id) {
          if (process.env.REACT_APP_ENV === "local") {
            const response = mockSearchProducts;
            const products = response.products.map((product) => ({...product, visible: true}));
            const productsList = concatArraysWithoutDuplicateCode(products, newProductsList);
            setSourceProducts(productsList);
            return;
          }

          
          // all Products API call
          dataApi.defaults.headers.common["Authorization"] =
            "Bearer " + currentUser?.token;
          const productsSearchRequest = `/products/search/${filter}`;
          setIsLoading(true);
          dataApi.get(productsSearchRequest).then((response) => {
            console.log("all ProductsSearch response", response);
            if (response.status === 200) {
              const products = response.data.products.map((product) => ({...product, visible: true}));
              const productsList = concatArraysWithoutDuplicateCode(products, newProductsList);
              setSourceProducts(productsList);
            }
            setIsLoading(false);
          });
        }
      }

    }
  }, [filter, loadAllProducts]);

  function handleOnLoadProducts() {
     setLoadAllProducts(true);
  }

  return (
    <>
      <Card mt={4}>
        <CardHeader textAlign="left">
          <Flex align="baseline">
            <Heading size="sm">Products </Heading>
            <Spacer />
            <CustomerBadge customer={currentCustomer} />
          </Flex>
        </CardHeader>
        <Box padding={5}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" size="sm" />}
            />
            <Input placeholder={searchPlaceholder} value={filter} size="sm" onChange={(e) => {setFilter(e.target.value)}} />
          </InputGroup>
          { filter?.length > 0 &&
                    <Center>
                      <Button variant="ghost" size="xs" colorScheme="teal" onClick={handleOnLoadProducts} m={1}>
                        Search all products
                      </Button>
                    </Center>
          }
        </Box>
        <CardBody>
          <Stack spacing="4">
            {isLoading ? (
              <Loading />
            ) : (
              <>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {!currentCustomer?.id && (
                      <>
                        <InfoBox>
                          <Heading size="sm">Please select a Customer</Heading>
                          <Text>
                            Then you can see <em>Top products</em> of the
                            Customer to drag them to <em>Selected products</em>{" "}
                            and create a Quick order.
                          </Text>
                        </InfoBox>
                      </>
                    )}
                    { (customerProducts?.length === 0 && currentCustomer?.id) && (
                      <>
                        <InfoBox>
                          <Heading size="sm">Selected Customer doesn't have Top products</Heading>
                          <Text>
                            Please search among All products.
                          </Text>
                        </InfoBox>
                      </>
                    )}

                  {currentCustomer?.id && (
                    <Box h="400px" overflowY="scroll">
                      {sourceProducts.map((item, index) => (
                        <Draggable key={item.code} draggableId={item.code} index={index}>
                          {(provided, snapshot) => (
                            <Box
                              textAlign="left"
                              shadow="md"
                              key={item.code}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                display: item.visible ? "block" : "none",
                                ...getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}}
                            >
                              <Text fontSize="sm" fontWeight="bold">
                                {item.description}
                              </Text>
                              <Text fontSize="sm" color="gray.600">
                                SKU {item.code}
                              </Text>
                              {(item.qty > 0 && !loadAllProducts) && (
                                <Text pt="2" fontSize="sm" color="green.300" fontWeight="bold">
                                  Purchased before {item.qty} pcs
                                </Text>
                              )}
                            </Box>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                  )}

                  </div>
                )}
              </Droppable> 
              </>

            )}
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}

export function SelectedProductsList() {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [ currentCustomer, setCurrentCustomer ] = useContext(CustomerContext);
  //const [ selectedProducts, setSelectedProducts] = useState([]);
  const [ customerProducts, setCustomerProducts, sourceProducts, setSourceProducts, selectedProducts, setSelectedProducts] = useContext(ProductsContext);
  const [ quantities, setQuantities] = useState({});
  const { isLoading, serverResponse, postOrder} = usePostOrder();
  const [ response, setResponse] = useState({});
  const { onOpen } = useAlertContext();

  useEffect(() => {
    let updatedQuantities = quantities;
    selectedProducts.forEach((product) => {
      if (!(product.code in quantities)) {
        updatedQuantities[product.code] = 1;
      }
    });
    setQuantities(updatedQuantities);

    // let result = selectedItems.map((product) => ({
    //   itemId: product.itemId,
    //   code: product.code,
    //   description: product.description,
    // }));
    // setSelectedProducts(result);
  }, [selectedProducts]);

  const updateQuantity = (event, productCode) => {
    let updatedQuantities = { ...quantities };

    if (productCode in quantities) {
      if (event.target.value >= 0)
        updatedQuantities[productCode] = event.target.value;
    }
    setQuantities(updatedQuantities);
  };

  const increaseQuantity = (productCode) => {
    let updatedQuantities = { ...quantities };
    if (productCode in quantities)
      updatedQuantities[productCode] =
        parseInt(updatedQuantities[productCode]) + 1;
    setQuantities(updatedQuantities);
  };
  const decreaseQuantity = (productCode) => {
    let updatedQuantities = { ...quantities };

    if (productCode in quantities) {
      if (updatedQuantities[productCode] > 1)
        updatedQuantities[productCode] =
          parseInt(updatedQuantities[productCode]) - 1;
    }
    //else updatedProducts.splice(index, 1);

    setQuantities(updatedQuantities);
  };

  const deleteItem = (productCode) => {
    console.log('Deleteing Item from Selected products...', selectedProducts);
    console.log('Selected products quantities ', quantities);
    let updatedQuantities = { ...quantities };
    let updatedSelectedProducts = selectedProducts;

    selectedProducts.forEach((product, index) => {
      if (product.code === productCode) {
        delete updatedQuantities[product.code];
        // updatedQuantities[product.code] = 0;
        // deleting from Selected products list
        updatedSelectedProducts.splice(index, 1);
      }
    });
    console.log('Updated list of Selected products ->', updatedSelectedProducts);
    console.log('Selected products updated quantities ', updatedQuantities);
    setQuantities(updatedQuantities);
    setSelectedProducts([...updatedSelectedProducts]);
    
  };

  return (
    <Box>
      <Card>
        <CardHeader textAlign="left">
          <Flex align="baseline">
            <Heading size="sm">Selected Products</Heading>
            <Spacer />
            <CustomerBadge customer={currentCustomer} />
          </Flex>
        </CardHeader>
        <TableContainer>
          <Table variant="simple" style={{ width: "100%"}}>
            <Thead>
              <Tr key={0}>
                <Th>Product</Th>
                <Th isNumeric>Quantity</Th>
                <Th>Adjust (+/-)</Th>
              </Tr>
            </Thead>

            <Droppable
              droppableId="droppable2"
              // renderClone={(provided, snapshot, rubric) => (
              //   <div
              //     {...provided.draggableProps}
              //     {...provided.dragHandleProps}
              //     ref={provided.innerRef}
              //   >
              //     Item id: {sourceProducts[rubric.source.index].id}
              //   </div>
              // )}
            >
              {(provided, snapshot) => (
                <Tbody
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {selectedProducts.map((product, index) => (
                    <Draggable
                      key={`selected-${product.code}`}
                      index={index}
                      draggableId={`selected-${product.code}`}
                    >
                      {(provided, snapshot) => (
                        <Tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Td whiteSpace="normal">
                            <Text size="sm" style={{ wordBreak: "break-word", margin: 0, fontWeight: "bold" }}>{product.description}</Text>
                            <Text fontSize="sm" color="gray.600">
                              SKU {product.code}
                            </Text>
                          </Td>
                          <Td isNumeric whiteSpace="normal">
                            <Input
                              value={quantities[product.code] || 0}
                              onChange={(e) => updateQuantity(e, product.code)}
                            />
                          </Td>
                          <Td whiteSpace="normal">
                            <Stack spacing={2} direction="row">
                              <IconButton
                                onClick={() => {
                                  increaseQuantity(product.code);
                                }}
                                colorScheme="teal"
                                aria-label="Add (+)"
                                icon={<AddIcon />}
                              />
                              <IconButton
                                onClick={() => {
                                  decreaseQuantity(product.code);
                                }}
                                colorScheme="teal"
                                aria-label="Remove (-)"
                                icon={<MinusIcon />}
                              />
                              <IconButton
                                onClick={() => {
                                  deleteItem(product.code);
                                }}
                                colorScheme="red"
                                aria-label="Delete"
                                icon={<DeleteIcon />}
                                variant="ghost"
                              />
                            </Stack>
                          </Td>
                        </Tr>
                      )}
                    </Draggable>
                  ))}
                    <Tr>
                      <Td colSpan={3}>
                        {selectedProducts.length == 0 ? (
                          <DropZonePlaceholder />) :

                          <div style={{width: "100%", backgroundColor: "rgb(240, 240, 240)", padding: "10px", textAlign: "center", borderRadius: "4px"}}>
                            <AddIcon />
                          </div>
                        }
                      </Td>
                    </Tr>
                  {provided.placeholder}
                </Tbody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </Card>
      <Box mt={5} textAlign="right">
        <Button
          colorScheme="blue"
          variant="outline"
          onClick={() => {
            if (selectedProducts?.length === 0 || !currentCustomer?.name) {
              onOpen(
                "info",
                "Please select customer and products to create an order."
              );
              return;
            }
            let products = selectedProducts.map((product) => {
              return {
                product_id: product.item_id,
                quantity: quantities[product.code],
              };
            });
            let payload = {
              customer_id: currentCustomer.id,
              products: products,
            };
            // let data = JSON.stringify(payload);
            let data = payload;
            postOrder(data, currentCustomer, currentUser).then((resp) => {
              console.log("postOrder response", resp);
              if (resp?.message) {
                onOpen(resp.type, "Order created successfully.");
                setSelectedProducts([]);
              }
              return false;
            });
            return false;
          }}
        >

          {isLoading ? <>Loading...</> : <>Generate Order</>}
        </Button>
      </Box>
    </Box>
  );
}



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  console.log('Reordering list...', list);

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log('Reordering list -> result', result);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

/**
 * Clones an item from one list to another list.
 */
const clone = (source, destination, droppableSource, droppableDestination) => {
  console.log('cloning a product to Selected products...');
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const draggedItem = sourceClone[droppableSource.index];
  console.log('draggedItem', draggedItem);
  console.log('initial destClone -> ', destClone);

  // if this Product has not been dragged before, adding to the Selected list

  const foundIndex = destClone.find(product => product.code === draggedItem.code);
  console.log('foundIndex -> ', foundIndex);
  if (foundIndex === undefined)

    destClone.splice(droppableDestination.index, 0, draggedItem);

  console.log('updated destClone -> ', destClone);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  padding: grid,
  width: "full",
});

const DraggableLists = ({ customer }) => {
  const [ customerProducts, setCustomerProducts, sourceProducts, setSourceProducts, selectedProducts, setSelectedProducts] = useContext(ProductsContext);
  //const [ selectedItems, setSelectedItems] = useState([]);

  const getList = (id) => {
    if (id === "droppable") return sourceProducts; 
    if (id === "droppable2") return selectedProducts;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    console.log('Dropping...', result);
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {

      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable") setSourceProducts(items); //setSourceItems(items);
      if (source.droppableId === "droppable2") setSelectedProducts(items);

    } else {
      //moving Product card
      // const result = move(
      //   getList(source.droppableId),
      //   getList(destination.droppableId),
      //   source,
      //   destination
      // );
      // cloning Product card 
      const result = clone(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setSourceProducts(result.droppable); //setSourceItems(result.droppable);
      setSelectedProducts(result.droppable2);
    }
  };

  return (
    <Box ml={20} mr={20}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex mt={3}>
          <Box flex="1" display="grid" gridTemplateColumns="1fr 3fr" gap={4}>
            <Box textAlign="left">
              <SelectedCustomer />
              <TopProductsList />
            </Box>
            <Box>
              <SelectedProductsList />
            </Box>
          </Box>
        </Flex>
      </DragDropContext>
    </Box>

    
  );
};

export default DraggableLists;
