import {useContext, useState} from 'react';
import {Box, Button, Alert, VStack, Center} from "@chakra-ui/react"
import { AlertIcon } from '@chakra-ui/react';
import UserContext from '../auth/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import dataApi from "../data/dataApi";
import SubscriberLayout from './SubscriberLayout';
import { customers } from '../data/mock';
import DraggableLists from '../components/DraggableLists';
import PurchaseReports from '../components/purchaseOrderReports/PurchaseReports';
const PurchaseOrderReports = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    // const router = useRouter()
    const navigate = useNavigate()

    return (
        <>
            <SubscriberLayout>
                <Box alignContent={"baseline"}>
                {currentUser?.token ? (
                    <PurchaseReports />
                ) : (
                    <>
                        <Center>
                            <VStack spacing={6}>
                                <Alert status="warning">
                                    <AlertIcon />
                                    Seems your're not logged in, to see this page please log in.
                                </Alert>
                                <Box>
                                    <Link to="/">
                                        <Button variant="solid" colorScheme="teal">
                                            Log in
                                        </Button>
                                    </Link>
                                </Box>
                            </VStack>
                        </Center>
                    </>
                )}
                </Box>
            </SubscriberLayout>
        </>
    );
};


export default PurchaseOrderReports;