import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { usePurchaseReport } from "../../context/purchaseReportContext";

export function SaveFilter({ isOpen, onClose, suppliers }) {
  // Context
  const {
    saveFilterInitialValues,
    saveFilterValdations,
    onSaveFilter,
    setShowSaveFilterModal,
  } = usePurchaseReport();

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Filter</ModalHeader>
        <ModalCloseButton onClick={() => setShowSaveFilterModal(false)} />
        <Formik
          initialValues={saveFilterInitialValues}
          validationSchema={saveFilterValdations}
          onSubmit={(values) => onSaveFilter({ values, suppliers })}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <Input
                  type="hidden"
                  name="filterType"
                  id="filterType"
                  value={values.filterType}
                />
                <FormControl>
                  <FormLabel>Filter Name*</FormLabel>
                  <Input
                    autoFocus
                    type="text"
                    name="filterName"
                    id="filterName"
                    value={values.filterName}
                    borderColor={"#ccc"}
                    onChange={handleChange}
                  />
                  {errors.filterName && touched.filterName && (
                    <Text color="red" fontSize={"sx"} my={2}>
                      {errors.filterName}
                    </Text>
                  )}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setShowSaveFilterModal(false);
                    onClose();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant={"outline"}
                  colorScheme={"teal"}
                  size={"sm"}
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default SaveFilter;
