import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { AlertProvider } from "./context/alertContext";
import Alert from "./components/Alert";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./auth/UserContext";
import { CustomerProvider } from "./data/CustomerContext";
import { CustomersProvider } from "./context/customersContext";
import { SupplierProvider } from "./data/SupplierContext";
import { ProductsProvider } from "./data/ProductsContext";
import { PurchaseReportProvider } from "./context/purchaseReportContext";
import { CustomerReportProvider } from "./context/customerReportContext";
import { InvoiceReportProvider } from "./context/invoiceReportContext";
import { SupplierProductsProvider } from "./data/SupplierProductsContext";
import { InventoryProvider } from "./context/inventoryContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ChakraProvider>
      <UserProvider>
        <SupplierProvider>
          <SupplierProductsProvider>
            <CustomerProvider>
              <ProductsProvider>
                <AlertProvider>
                  <PurchaseReportProvider>
                    <CustomerReportProvider>
                      <InvoiceReportProvider>
                        <InventoryProvider>
                          <CustomersProvider>
                            <App />
                          </CustomersProvider>
                          <Alert />
                        </InventoryProvider>
                      </InvoiceReportProvider>
                    </CustomerReportProvider>
                  </PurchaseReportProvider>
                </AlertProvider>
              </ProductsProvider>
            </CustomerProvider>
          </SupplierProductsProvider>
        </SupplierProvider>
      </UserProvider>
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
