/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "@chakra-ui/react";
import * as Yup from "yup";
import dataApi from "../data/dataApi";
import UserContext from "../auth/UserContext";

export const PurchaseReportContext = createContext({});

export function PurchaseReportProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrdersWithLines, setPurchaseOrdersWithLines] = useState([]);
  const [showAllLines, setShowAllLines] = useState(false);
  const [mainFields, setMainFields] = useState([]);
  const [lineFields, setLineFields] = useState([]);
  const [showGroupped, setShowGroupped] = useState(false);
  const [purchaseOrdersGroupped, setPurchaseOrdersGroupped] = useState([]);

  const defaultFields = {
    mainColumn: [
      "SupplierName",
      "OrderNumber",
      "OrderDate",
      "ReceiptDate",
      "AmountDC",
    ],
    lineColumn: [
      "Created",
      "ReceiptDate",
      "ItemCode",
      "SupplierItemCode",
      "Quantity",
      "NetWeight",
      "NetWeightUnit",
      "ItemDescription",
    ],
  };
  const [selectedColumn, setSelectedColumn] = useState({
    mainColumn: defaultFields.mainColumn,
    lineColumn: defaultFields.lineColumn,
  });

  useEffect(() => {
    if (showGroupped) {
      setSelectedColumn({
        mainColumn: ["SupplierName", "DateRange"],
        lineColumn: ["ItemCode", "ItemDescription", "Quantity", "UnitDescription"],
      })
    }

    else {
      setSelectedColumn({
        mainColumn: defaultFields.mainColumn,
        lineColumn: defaultFields.lineColumn,
      });
    }
}, [showGroupped]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDateError, setShowDateError] = useState(false);
  const [availableFilter, setAvailableFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);
  const [savedSuppliers, setSavedSuppliers] = useState([]);

  // Context
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  // Functions
  // On Generate Purchase Report
  const getPurchaseOrderData = async (start_date, end_date, supplier_ref) => {
    try {
      // check if dates are selected
      if (!start_date || !end_date) {
        return false;
      }

      // prepare params
      const params = {
        start_date,
        end_date,
        supplier_ref,
      };

      setIsLoading(true);
      // request purchase report
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/purchase_orders`, params);
      setIsLoading(false);

      const { data = [], message, success = false } = res.data;
      if (data.length > 0 && success) {
        // set res data in states
        setPurchaseOrders(
          data.map((list) => {
            return list.purchase_order;
          })
        );
        setPurchaseOrdersWithLines(data);
        setPurchaseOrdersGroupped(calculateTotalQuantities(data));
        return true;
      } else {
        toast({
          position: "top",
          title: message,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };

  // Get Available Filter
  const getAvailableFilter = async (filterType) => {
    try {
      // prepare params
      const params = {
        type: filterType,
      };

      // request all saved filters
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/purchase_orders/list_user_report_filters`, params);
      const { data = [], success } = res.data;
      if (data && success) {
        // set res data in states
        setAvailableFilter(data);
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  // Load Selected Filter
  const onLoadFilter = async (filter_id) => {
    try {
      if (filter_id.length === 0) {
        toast({
          position: "top",
          title: "Select Filter",
          status: "info",
          isClosable: true,
        });
        return false;
      }

      // prepare params
      const params = {
        filter_id,
      };

      // request selected filters details
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/purchase_orders/get_user_report_filter`, params);
      const { data = {}, message, success } = res.data;
      if (data && success) {
        const {
          report_filter_values: {
            start_date,
            end_date,
            selectedMainFilter,
            selectedLineFilter,
            supplier_ref,
          },
        } = data;
        // set res data in states
        setSelectedFilter(data);
        setStartDate(start_date);
        setEndDate(end_date);
        setSelectedColumn({
          mainColumn: selectedMainFilter,
          lineColumn: selectedLineFilter,
        });
        setSavedSuppliers(supplier_ref);
        await getPurchaseOrderData(
          start_date,
          end_date,
          supplier_ref.map((item) => {
            return item.id;
          })
        );
        return true;
      } else {
        toast({
          position: "top",
          title: message,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };
  // Delete Selected Filter
  const onDeleteFilter = async () => {
    try {
      // prepare params
      const params = {
        filter_id: selectedFilter.id,
      };

      // request to delete selected filters
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/purchase_orders/delete_user_report_filter`, params);
      const { data = {}, message, success } = res.data;
      setShowDeleteFilterModal(false);
      if (data && success) {
        // set res data in states
        setSelectedFilter(data);
        toast({
          position: "top",
          title: message,
          status: "success",
          isClosable: true,
        });
        return true;
      } else {
        toast({
          position: "top",
          title: message,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setShowDeleteFilterModal(false);
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };

  // Save Filter Form Initial Values & Validations
  const saveFilterInitialValues = {
    filterName: "",
    filterType: "purchase_order",
  };
  const saveFilterValdations = Yup.object().shape({
    filterName: Yup.string()
      .required("Filter Name Required")
      .min(4, "Name is too short!")
      .max(20, "Name is too long!"),
    filterType: Yup.string(),
  });
  // Save Filter Form On Submit
  const onSaveFilter = async ({
    values: { filterId = "", filterName, filterType },
    suppliers,
  }) => {
    try {
      if (!startDate || !endDate) {
        toast({
          position: "top",
          title: "Invalid Date",
          status: "error",
          isClosable: true,
        });
        setShowSaveFilterModal(false);
        return false;
      }

      // prepare params
      const params = {
        filter_id: filterId,
        name: filterName,
        type: filterType,
        report_filter_values: {
          start_date: startDate,
          end_date: endDate,
          selectedMainFilter: selectedColumn.mainColumn,
          selectedLineFilter: selectedColumn.lineColumn,
          supplier_ref: suppliers,
        },
      };

      // request to save filter
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/purchase_orders/save_user_report_filter`, params);
      const { data = {}, message, success } = res.data;
      if (data && success) {
        getAvailableFilter();
        setShowSaveFilterModal(false);
        toast({
          position: "top",
          title: message,
          status: "success",
          isClosable: true,
        });
        return true;
      } else {
        setShowSaveFilterModal(false);
        toast({
          position: "top",
          title: message,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setShowSaveFilterModal(false);
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };

  function calculateTotalQuantities(data) {
    const totals = [];
    let orders = JSON.parse(JSON.stringify(data));
  
    orders.forEach((order) => {
      const supplierName = order.purchase_order.SupplierName;
      let dateRange = `${startDate || "*"} - ${endDate || new Date().toISOString().slice(0, 10)}`;

      const lines = order.lines;
  
      let orderTotal = totals.find((total) => total.SupplierName === supplierName);
      if (!orderTotal) {
        orderTotal = { SupplierName: supplierName, DateRange: dateRange, lines: [] };
        totals.push(orderTotal);
      }
  
      lines.forEach((line) => {
        const { ItemCode, ItemDescription, Quantity, UnitDescription } = line;
        const existingLine = orderTotal.lines.find((l) => l.ItemCode === ItemCode);

        if (existingLine) {
          existingLine.Quantity += Quantity;
        } else {
          orderTotal.lines.push(line);
        }
      });
    });
  
    return totals;
  }

  // Generate CSV File from Selected Table Filters
  const onExportCsv = () => {
    if (showAllLines) {
      // get table heading
      const tableHeading = [
        ...selectedColumn.mainColumn,
        ...selectedColumn.lineColumn,
      ];
      // get main table values

      const orders = showGroupped ? purchaseOrdersGroupped : purchaseOrders;

      const allSelectedMainValues = orders.map((list) => {
        return selectedColumn.mainColumn.map((item) => {
          return String(list[item]);
        });
      });

      // get line table values
      const ordersWithLines = showGroupped ? purchaseOrdersGroupped : purchaseOrdersWithLines;
      const allSelectedLineValues = ordersWithLines.map((list) => {
        return list.lines.map((line) => {
          return selectedColumn.lineColumn.map((key) => {
            return String(line[key]);
          });
        });
      });

      // merge main table & line table values
      let tableBody = [];
      allSelectedLineValues.map((lineItem, index) => {
        return lineItem.map((arr) => {
          return (tableBody = [
            ...tableBody,
            [allSelectedMainValues[index].concat(arr)],
          ]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody.flat()]);
    }

    if (!showAllLines && purchaseOrders.length > 0) {
      // get table heading
      const tableHeading = selectedColumn.mainColumn;
      // get main table values
      const tableBody = purchaseOrders.map((list) => {
        return selectedColumn.mainColumn.map((item) => {
          return String(list[item]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody]);
    }
  };

  // Hooks
  // Get All Columns for Main and Line Table
  useEffect(() => {
    if (purchaseOrdersWithLines.length > 0) {
      const findFields = purchaseOrdersWithLines.find((item) => {
        return item.purchase_order;
      });
      setMainFields(Object.keys(findFields?.purchase_order));
      setLineFields(Object.keys(findFields?.lines[0]));
    }
  }, [purchaseOrdersWithLines]);

  // Wrapping the Stuff in a useMemo for Performance Reason
  const contextPayload = useMemo(
    () => ({
      // States
      defaultFields,
      isLoading,
      setIsLoading,
      purchaseOrders,
      setPurchaseOrders,
      purchaseOrdersWithLines,
      setPurchaseOrdersWithLines,
      showAllLines,
      setShowAllLines,
      mainFields,
      setMainFields,
      lineFields,
      setLineFields,
      selectedColumn,
      setSelectedColumn,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      showDateError,
      setShowDateError,
      availableFilter,
      setAvailableFilter,
      selectedFilter,
      setSelectedFilter,
      csvData,
      setCsvData,
      showSaveFilterModal,
      setShowSaveFilterModal,
      showDeleteFilterModal,
      setShowDeleteFilterModal,
      showExportCsvModal,
      setShowExportCsvModal,
      savedSuppliers,
      setSavedSuppliers,

      // Form Initial States & Validations
      saveFilterInitialValues,
      saveFilterValdations,

      // Functions
      getPurchaseOrderData,
      onSaveFilter,
      getAvailableFilter,
      onLoadFilter,
      onDeleteFilter,
      onExportCsv,

      purchaseOrdersGroupped,
      setPurchaseOrdersGroupped,
      showGroupped, 
      setShowGroupped,
      calculateTotalQuantities,
    }),
    [
      // States
      defaultFields,
      isLoading,
      setIsLoading,
      purchaseOrders,
      setPurchaseOrders,
      purchaseOrdersWithLines,
      setPurchaseOrdersWithLines,
      showAllLines,
      setShowAllLines,
      mainFields,
      setMainFields,
      lineFields,
      setLineFields,
      selectedColumn,
      setSelectedColumn,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      showDateError,
      setShowDateError,
      availableFilter,
      setAvailableFilter,
      selectedFilter,
      setSelectedFilter,
      csvData,
      setCsvData,
      showSaveFilterModal,
      setShowSaveFilterModal,
      showDeleteFilterModal,
      setShowDeleteFilterModal,
      showExportCsvModal,
      setShowExportCsvModal,
      savedSuppliers,
      setSavedSuppliers,
      purchaseOrdersGroupped,
      setPurchaseOrdersGroupped,
      showGroupped, 
      setShowGroupped,
      calculateTotalQuantities,

      // Form Initial States & Validations
      saveFilterInitialValues,
      saveFilterValdations,

      // Functions
      getPurchaseOrderData,
      onSaveFilter,
      getAvailableFilter,
      onLoadFilter,
      onDeleteFilter,
      onExportCsv,
    ]
  );

  return (
    <PurchaseReportContext.Provider value={contextPayload}>
      {children}
    </PurchaseReportContext.Provider>
  );
}

export const usePurchaseReport = () => useContext(PurchaseReportContext);

export default PurchaseReportContext;
