import {useEffect, useContext} from 'react';
import {Box, Button, Alert, VStack, Center} from "@chakra-ui/react"
import { AlertIcon } from '@chakra-ui/react';
import UserContext from '../auth/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import SubscriberLayout from './SubscriberLayout';
import { customers } from '../data/mock';
import QucikPurchaseOrders from '../components/QuickPurchaseOrders';
 
const QuickOrders = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    // const router = useRouter()
    const navigate = useNavigate()

    // useEffect(() => {
    //     console.log('layout-useEffect-currentUser', currentUser);
    //     if (!currentUser?.token) {
    //       navigate("/");
    //     }
    //   }, [currentUser, navigate]);


	return (
    <>
      <SubscriberLayout>
        {currentUser?.token ? (
          <QucikPurchaseOrders />
        ) : (
          <>
          <Center>
          <VStack spacing={6}>
            <Alert status="warning">
              <AlertIcon />
              Seems your're not logged in, to see this page please log in.
            </Alert>
            <Box>
              <Link to="/">
                <Button variant="solid" colorScheme="teal">
                  Log in
                </Button>
              </Link>
            </Box>
            </VStack>
            </Center>
          </>
        )}
      </SubscriberLayout>
    </>
  );
};

export default QuickOrders;