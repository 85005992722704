import React, { useEffect, useState } from 'react';

import SubscriberLayout from './SubscriberLayout';
import { useContext } from "react";
import UserContext from '../auth/UserContext';
import { Navigate } from "react-router-dom"
// Components
import LoginForm from '../components/LoginForm';
import dataApi from '../data/dataApi';

const Home = () => {
	const { currentUser, setCurrentUser } = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		dataApi.defaults.headers.common["Authorization"] = "Bearer " + currentUser?.token;
		dataApi.get("/auth/user").then((res) => {
			setCurrentUser({ ...currentUser, ...res.data });
			setLoading(false);
		}).catch((err) => {
			console.error("Error fetching user", err);
			setLoading(false);
		});
	}, []);

	return (
		<>
		{(loading) &&
			(currentUser?.id ? (
				<Navigate to="/quick-orders" />
				
			):(
				<Navigate to="/login" />
			))
		}
			
		</>
	);
};

export default Home;