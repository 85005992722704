import {Box, Center, VStack} from "@chakra-ui/react"

function InfoBox({children}) {
  return (
    <Box bg="gray.50" width="full" borderRadius="lg" p={10}>

        <VStack spacing={4}>{children}</VStack>
  
    </Box>
  );
}

export default InfoBox;