import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  VStack,
  Heading,
  Grid,
  GridItem,
  Text,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import dataApi from "../data/dataApi";
import UserContext from "../auth/UserContext";

const SubscriptionOptions = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isLoading, setIsLoading ] = useState(false);

  const cancel = async () => {
    setIsLoading(true);
    dataApi.defaults.headers.common["Authorization"] = "Bearer " + currentUser?.token;
    const response = await dataApi.post("/subscriptions/cancel");

    if (response.status === 200) {
      let subscription = currentUser.subscription;
      subscription.active = false;
      setCurrentUser({ ...currentUser, subscription });
      toast({
        title: "Subscription cancelled",
        description: "Your subscription has been cancelled.",
        status: "info",
        colorScheme: "red",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose(); 
    }

    setIsLoading(false);
  }

  const resume = async () => {
    setIsLoading(true);
    dataApi.defaults.headers.common["Authorization"] = "Bearer " + currentUser?.token;
    const response = await dataApi.post("/subscriptions/resume");

    if (response.status === 200) {
      let subscription = currentUser.subscription;
      subscription.active = true;
      setCurrentUser({ ...currentUser, subscription });
      toast({
        title: "Subscription resumed",
        description: "Your subscription has been cancelled.",
        colorScheme: "green",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    setIsLoading(false);
    
  }

  return (
    <>
      <Card>
        <VStack w="600px" pl={16} pt={5} pb={5} alignItems="flex-start">
          <Heading
            mb={3}
            as="h5"
            id="login-form"
            size={"large"}
          >
            Subscriptions
          </Heading>


          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Subscription
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                <Text fontWeight="bold">{currentUser.subscription?.product_id}</Text>
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Ends at
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                <Text fontWeight="bold">{currentUser.subscription?.expires_at ? new Date(currentUser.subscription.expires_at).toLocaleDateString() : ""}</Text>
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Active
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                <Text fontWeight="bold">{currentUser.subscription?.active ? "Yes" : "No"}</Text>
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Type
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                <Text fontWeight="bold">Monthly</Text>
              </GridItem>
            </Grid>

            {
              currentUser.subscription?.active ? (
                <Button colorScheme="teal" onClick={onOpen}>Cancel subscription</Button>
              ) :
              (
                <Button colorScheme="teal" onClick={resume} isLoading={isLoading}>Resume subscription</Button>
              )}
        </VStack>
      </Card>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Are you sure you want to cancel your subscription?</ModalBody>

          <ModalFooter>
            <Button onClick={cancel} isLoading={isLoading} colorScheme="blue" mr={3}>
              Confirm
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubscriptionOptions;
