import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
    // all Products loaded from API
	const [ customerProducts, setCustomerProducts] = useState([]);
    // Products left in a draggable list
    const [ sourceProducts, setSourceProducts] = useState([]);
	// Products Selected in a draggable list
    const [ selectedProducts, setSelectedProducts] = useState([]);

    const navigate = useNavigate();

	useEffect(() => {

	}, [navigate]);


	return (
		<ProductsContext.Provider value={[ customerProducts, setCustomerProducts, sourceProducts, setSourceProducts, selectedProducts, setSelectedProducts]}>
            {children}
		</ProductsContext.Provider>
	);
};


export default ProductsContext;