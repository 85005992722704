import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { TbColumns3 } from "react-icons/tb";
import MultiSelect from "../MultiSelect";
import { useInventory } from "../../context/inventoryContext";
import { ArrowDownIcon, ArrowUpIcon, DownloadIcon } from "@chakra-ui/icons";

export function InventoryTable() {

  const {
    inventory,
    mainFields,
    selectedColumn,
    setSelectedColumn,
    defaultFields,
    sortedFields,
    sortInventory
  } = useInventory();

  const [ascending, setAscending] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItem = [
    {
      title: "Main Columns",
      itemList: mainFields,
      checkedItems: selectedColumn.mainColumn,
    },
  ];

  console.log({selectedColumn});

  const sortBy = (item) => {
      sortInventory(item, !ascending);
      setAscending(!ascending);
  }


  return (
    <Box>
      <Flex px={4} gap={5}>
        {/* Column Filter */}
        <Box>
          <MultiSelect
            label="Columns"
            icon={<TbColumns3 />}
            items={menuItem}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            defaultFields={defaultFields}
            selectDefaultOption
          />
        </Box>
      </Flex>

      {/* Main Table */}
      <TableContainer>

        <div style={{ textAlign: "right", padding: "0 45px 10px 0"}}>
          <Button colorScheme='teal'>Create Order</Button>
        </div>


        <Table variant="striped" cellPadding={0} size={"sm"}>
          <Thead>
            <Tr>
              {selectedColumn.mainColumn.map((item, index) => {
                return (
                  <Th key={index} textTransform={"none"}>
                    {item}
                    
                    
                    {sortedFields.find(i => i == item) && (ascending ? (<ArrowUpIcon ml={2} onClick={() => sortBy(item) } />) : (<ArrowDownIcon ml={2} onClick={() => sortBy(item)} />))}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {inventory.map((list, listIndex) => {
              return (
                <Fragment key={listIndex}>
                  <Tr>
                    {selectedColumn.mainColumn.map((item) => {
                      return (
                        item === "checkbox" ?
                          <Td key={item}>
                            <Checkbox></Checkbox>
                          </Td>
                        :
                          <Td key={item}>
                            {list[item]}
                          </Td>
                      )
                    })}
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inventory Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Inventory message</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default InventoryTable;
