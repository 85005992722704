import { useContext, useEffect, useState } from "react";
import UserContext from "../../auth/UserContext";
import ResizeTextarea from "react-textarea-autosize";
import {
  Grid,
  GridItem,
  Card,
  VStack,
  Heading,
  Textarea,
  FormControl,
  Button,
  Input,
  useToast,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import dataApi from "../../data/dataApi";

const EmailSettings = () => {
  const toast = useToast();
  const { currentUser } = useContext(UserContext);
  const [userSettings, setUserSettings] = useState({
    email_subject: "",
    email_message: "",
    reply_to: "",
    name_from: "",
    bcc: false,
    bcc_email: "",
  });

  useEffect(() => {
    dataApi.defaults.headers.common["Authorization"] =
      "Bearer " + currentUser?.token;

    dataApi.get("/auth/user/settings").then((response) => {
      setUserSettings({ ...userSettings, ...response.data.data });
    });
  }, []);

  const updateSettings = (e) => {
    e.preventDefault();

    dataApi
      .put("/auth/user/settings", userSettings)
      .then((response) => {
        toast({
          title: "Settings updated.",
          position: "top",
          description: "We've updated your settings.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "Something went wrong updating your settings.",
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Card>
        <VStack w="600px" pl={16} pt={5} pb={5} alignItems="flex-start">
          <Heading
            mb={3}
            as="h5"
            id="login-form"
            size={"large"}
          >
            Email Settings (Invoice reminders)
          </Heading>
          <FormControl rounded="md" w="100%">
            <form onSubmit={updateSettings}>
              <Grid templateColumns="0.5fr 1.5fr" gap={2}>
                <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                  pt={2}
                >
                  <FormLabel>Subject</FormLabel>
                </GridItem>
                <GridItem w="100%" h="10" textAlign="left" verticalAlign="baseline">
                  <Input
                    placeholder="Subject"
                    value={userSettings.email_subject}
                    onChange={(e) =>
                      setUserSettings({
                        ...userSettings,
                        email_subject: e.target.value,
                      })
                    }
                  />
                </GridItem>

                <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                  pt={2}
                >
                  <FormLabel>Reply to</FormLabel>
                </GridItem>
                <GridItem w="100%" h="10" textAlign="left" verticalAlign="baseline">
                  <Input
                    placeholder="Reply to"
                    value={userSettings.reply_to || ""}
                    type="email"
                    onChange={(e) =>
                      setUserSettings({ ...userSettings, reply_to: e.target.value })
                    }
                  />
                </GridItem>

                <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                  pt={2}
                >
                  <FormLabel>Name from</FormLabel>
                </GridItem>
                <GridItem w="100%" h="10" textAlign="left" verticalAlign="baseline">
                  <Input
                    placeholder="Name from"
                    value={userSettings.name_from || ""}
                    onChange={(e) =>
                      setUserSettings({ ...userSettings, name_from: e.target.value })
                    }
                  />
                </GridItem>

                <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                  pt={2}
                >
                  <FormLabel>Bcc</FormLabel>
                </GridItem>

                <GridItem  w="100%" h="10" textAlign="left" verticalAlign="baseline">
                  <Switch id='bcc' isChecked={userSettings?.bcc} onChange={(e) =>
                      setUserSettings({ ...userSettings, bcc: !userSettings?.bcc })
                  } />
                </GridItem>

                <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                  pt={2}
                >
                  <FormLabel>Bcc Email</FormLabel>
                </GridItem>
                <GridItem w="100%" h="10" textAlign="left" verticalAlign="baseline">
                  <Input
                    placeholder="Bcc Email"
                    value={userSettings.bcc_email || ""}
                    type="email"
                    onChange={(e) =>
                      setUserSettings({ ...userSettings, bcc_email: e.target.value })
                    }
                  />
                </GridItem>
              </Grid>

              <Textarea
                mt={2}
                placeholder="Email message"
                value={userSettings.email_message || ""}
                onChange={(e) =>
                  setUserSettings({
                    ...userSettings,
                    email_message: e.target.value,
                  })
                }
                as={ResizeTextarea}
              />

              <Button
                type="submit"
                w="100%"
                mt={2}
                colorScheme="teal"
                variant="solid"
              >
                Save
              </Button>
            </form>
          </FormControl>
        </VStack>
      </Card>
    </>
  );
};

export default EmailSettings;
