import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Select, useDisclosure } from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import dataApi from "../../data/dataApi";
import UserContext from "../../auth/UserContext";
import { useInventory } from "../../context/inventoryContext";
import { mockInventory } from "../../data/mock";
import ExportFile from "./ExportFile";
import { DownloadIcon } from "@chakra-ui/icons";
import DateRangePicker from "../DateRangePicker";

export function InventoryFilter() {
  const [days, setDays] = useState(14);

  const { currentUser } = useContext(UserContext);
  const { getInventoryData, setInventory, showExportCsvModal, setShowExportCsvModal, onExportCsv, setIsLoading } = useInventory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    //if (process.env.REACT_APP_ENV == "local") return;
    const req = setTimeout(() => {
      getInventoryData();
    }, 1000);

    return () => clearTimeout(req);
  }, [currentUser, days]);

  const handleDaysChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setDays(selectedValue);
  };

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"} pb={3}>
        <HStack spacing={3} w="300px">

        <label htmlFor="days">Days: </label>
        <Select 
          style={{"backgroundColor": "white"}}
          value={days}
          onChange={handleDaysChange}
        >
          <option value='7'>7</option>
          <option value='14'>14</option>
          <option value='30'>30</option>
        </Select>
        </HStack>
        <HStack spacing={3}>
          {/* Export Datatable to CSV */}
          <Button
            variant={"solid"}
            colorScheme={"teal"}
            size={"sm"}
            mr={4}
            leftIcon={<DownloadIcon />}
            onClick={() => {
              onExportCsv();
              setShowExportCsvModal(true);
              onOpen();
            }}
          >
            Export
          </Button>
        </HStack>
      </Flex>

      {/* Export File Modal*/}
      {showExportCsvModal && isOpen && (
        <ExportFile onClose={onClose} isOpen={isOpen} />
      )}
    </>
  );
}

export default InventoryFilter;
