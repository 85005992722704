import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

const SupplierContext = createContext();

export const SupplierProvider = ({ children }) => {
	const [ currentSupplier, setCurrentSupplier] = useState({});
    const navigate = useNavigate();

	return (
		<SupplierContext.Provider value={[currentSupplier, setCurrentSupplier]}>
            {children}
		</SupplierContext.Provider>
	);
};


export default SupplierContext;