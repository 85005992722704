/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "@chakra-ui/react";
import dataApi from "../data/dataApi";
import UserContext from "../auth/UserContext";

export const InvoiceReportContext = createContext({});

export function InvoiceReportProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceOrders, setInvoiceOrders] = useState([]);
  const [invoiceOrdersWithLines, setInvoiceOrdersWithLines] = useState([]);
  const [showAllLines, setShowAllLines] = useState(false);
  const [mainFields, setMainFields] = useState([]);
  const [lineFields, setLineFields] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDateError, setShowDateError] = useState(false);
  const [showGroupped, setShowGroupped] = useState(false);
  const [small, setSmall] = useState(false);


  const defaultFields = {
    mainColumn: [
      "InvoiceToName",
      "InvoiceNumber",
      "Description",
      "AmountFCExclVat",
      "StatusDescription",
      "StarterSalesInvoiceStatusDescription",
      "Created",
    ],
    lineColumn: [
      "ItemCode",
      "ItemDescription",
      "SubscriptionDescription",
      "VATCodeDescription",
    ],
  };
  const [selectedColumn, setSelectedColumn] = useState({
    mainColumn: defaultFields.mainColumn,
    lineColumn: defaultFields.lineColumn,
  });

  useEffect(() => {
    if (small) {
      setSelectedColumn({
        mainColumn: ["InvoiceNumber", "InvoiceDate", "AmountFCExclVat"],
        lineColumn: ["item_code", "item_description", "quantity"],
      })
    }
    else {
      setSelectedColumn({
        mainColumn: defaultFields.mainColumn,
        lineColumn: defaultFields.lineColumn,
      });
    }
  }, [small]);


  // Context
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  // Functions
  // On Generate Invoice Report
  const getInvoiceOrderData = async (invoice_to) => {
    console.log(
      "log for invoice_to from get: getInvoiceOrderData",
      invoice_to
    );
    try {
      // prepare params
      const params = {
        per_page: "",
      };

      setIsLoading(true);
      // request invoice report
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/invoice/${invoice_to}/orders`, params);
      setIsLoading(false);

      const { data = [], message, success = false } = res.data;
      if (data.length > 0 && success) {
        // set res data in states
        setInvoiceOrders(
          data.map((list) => {
            return list.sales_invoice;
          })
        );
        setInvoiceOrdersWithLines(data);
        return true;
      } else {
        toast({
          position: "top",
          title: message,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };

  const onExportCsv = () => {
    if (showAllLines) {
      // get table heading
      const tableHeading = [
        ...selectedColumn.mainColumn,
        ...selectedColumn.lineColumn,
      ];
      // get main table values

      // const orders = showGroupped ? purchaseOrdersGroupped : purchaseOrders;

      const orders = invoiceOrders;
      const allSelectedMainValues = orders.map((list) => {
        return selectedColumn.mainColumn.map((item) => {

          if(item == "AmountFCExclVat"){
            return "€ " +String(list[item].toFixed(2));
          }else{
          return String(list[item]);
          }
        });
      });

      // get line table values
      // const ordersWithLines = showGroupped ? purchaseOrdersGroupped : purchaseOrdersWithLines;
      const ordersWithLines = invoiceOrdersWithLines;
      const allSelectedLineValues = ordersWithLines.map((list) => {
        return list.lines.map((line) => {
          return selectedColumn.lineColumn.map((key) => {
            return String(line[key]);
          });
        });
      });

      // merge main table & line table values
      let tableBody = [];
      allSelectedLineValues.map((lineItem, index) => {
        return lineItem.map((arr) => {
          return (tableBody = [
            ...tableBody,
            [allSelectedMainValues[index].concat(arr)],
          ]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody.flat()]);
    }

    if (!showAllLines && invoiceOrders.length > 0) {
      // get table heading
      const tableHeading = selectedColumn.mainColumn;
      // get main table values
      const tableBody = invoiceOrders.map((list) => {
        return selectedColumn.mainColumn.map((item) => {
          return String(list[item]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody]);
    }
  };
  
  // Hooks
  // Get All Columns for Main and Line Table
  useEffect(() => {
    if (invoiceOrdersWithLines.length > 0) {
      const findFields = invoiceOrdersWithLines.find((item) => {
        return item.sales_invoice;
      });
      setMainFields(Object.keys(findFields?.sales_invoice));
      setLineFields(Object.keys(findFields?.lines[0]));
    }
  }, [invoiceOrdersWithLines]);


  // Wrapping the Stuff in a useMemo for Performance Reason
  const contextPayload = useMemo(
    () => ({
      // States
      defaultFields,
      isLoading,
      setIsLoading,
      invoiceOrders,
      setInvoiceOrders,
      invoiceOrdersWithLines,
      setInvoiceOrdersWithLines,
      showAllLines,
      setShowAllLines,
      mainFields,
      setMainFields,
      lineFields,
      setLineFields,
      selectedColumn,
      setSelectedColumn,
      csvData, 
      setCsvData,
      showExportCsvModal,
      setShowExportCsvModal,
      onExportCsv,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      showDateError,
      setShowDateError,

      // Functions
      getInvoiceOrderData,
      small, setSmall
    }),
    [
      // States
      defaultFields,
      isLoading,
      setIsLoading,
      invoiceOrders,
      setInvoiceOrders,
      invoiceOrdersWithLines,
      setInvoiceOrdersWithLines,
      showAllLines,
      setShowAllLines,
      mainFields,
      setMainFields,
      lineFields,
      setLineFields,
      selectedColumn,
      setSelectedColumn,
      csvData, 
      setCsvData,
      showExportCsvModal,
      setShowExportCsvModal,
      onExportCsv,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      showDateError,
      setShowDateError,

      // Functions
      getInvoiceOrderData,
      small,
      setSmall
    ]
  );

  return (
    <InvoiceReportContext.Provider value={contextPayload}>
      {children}
    </InvoiceReportContext.Provider>
  );
}

export const useInvoiceReport = () => useContext(InvoiceReportContext);

export default InvoiceReportContext;
