import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { InfoIcon, DownloadIcon } from "@chakra-ui/icons";
import { TbColumns3 } from "react-icons/tb";
import { GrClose } from "react-icons/gr";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import LinesDataTable from "./LinesDataTable";
import MultiSelect from "../MultiSelect";
import { usePurchaseReport } from "../../context/purchaseReportContext";

export function PurchaseReportsTable() {
  // State
  const [showLineDataTable, setShowLineDataTable] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState();
  const [selectedLineIndex, setSelectedLineIndex] = useState();
  const [openChildTable, setOpenChildTable] = useState(false);
  const {onOpen} = useDisclosure();

  // Context
  const {
    purchaseOrders,
    mainFields,
    lineFields,
    showAllLines,
    setShowAllLines,
    defaultFields,
    selectedColumn,
    setSelectedColumn,
    showGroupped,
    setShowGroupped,
    purchaseOrdersGroupped,
  } = usePurchaseReport();

  const menuItem = [
    {
      title: "Main Columns",
      itemList: mainFields,
      checkedItems: selectedColumn.mainColumn,
    },
    {
      title: "Line Columns",
      itemList: lineFields,
      checkedItems: selectedColumn.lineColumn,
    },
  ];

  // Search Component for Filter
  const multiSelectSearch = () => {
    const mainColumnObj = mainFields.map((item) => {
      return { name: item, code: "Main" };
    });
    const lineColumnObj = lineFields.map((item) => {
      return { name: item, code: "Line" };
    });

    const searchColumn = [...mainColumnObj, ...lineColumnObj].map(
      (item, index) => {
        return { ...item, id: index };
      }
    );

    // function to filter columns in autocomplete
    const handleOnSelect = (string) => {
      if (string && typeof string === "object") {
        if (string.code === "Main") {
          const isCheckedValue = selectedColumn.mainColumn.includes(
            string.name
          );
          if (isCheckedValue) {
            let arr = selectedColumn.mainColumn.filter((item) => {
              return item !== string.name;
            });
            setSelectedColumn({ ...selectedColumn, ...{ mainColumn: arr } });
          } else {
            let arr = [...selectedColumn.mainColumn, string.name];
            setSelectedColumn({ ...selectedColumn, ...{ mainColumn: arr } });
          }
        }

        if (string.code === "Line") {
          const isCheckedValue = selectedColumn.lineColumn.includes(
            string.name
          );
          if (isCheckedValue) {
            let arr = selectedColumn.lineColumn.filter((item) => {
              return item !== string.name;
            });
            setSelectedColumn({ ...selectedColumn, ...{ lineColumn: arr } });
          } else {
            let arr = [...selectedColumn.lineColumn, string.name];
            setSelectedColumn({ ...selectedColumn, ...{ lineColumn: arr } });
          }
        }
      }
    };

    // function to format search results in autocomplete
    const formatResult = (item) => {
      return (
        <span
          style={{
            display: "block",
            textAlign: "left",
            fontWeight: "normal",
            textTransform: "none",
          }}
        >
          {item.name} {item.code === "Main" ? ` - ${item.code}` : ""}
        </span>
      );
    };

    return (
      <ReactSearchAutocomplete
        items={searchColumn}
        onSelect={handleOnSelect}
        formatResult={formatResult}
        fuseOptions={{
          keys: ["name"],
          threshold: 0.8,
          ignoreLocation: true,
        }}
        resultStringKeyName="name"
        placeholder="Search Columns"
        inputDebounce={500}
        showIcon={false}
        styling={{
          height: "32px",
          fontSize: "14px",
          borderRadius: "2px",
          boxShadow: "xs",
        }}
      />
    );
  };

  return (
    <Box>
      <Flex px={4} gap={5}>
        <Box>
          {/* Column Filter */}
          <MultiSelect
            label="Columns"
            icon={<TbColumns3 />}
            items={menuItem}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            defaultFields={defaultFields}
            searchBar={multiSelectSearch}
            selectDefaultOption
          />
        </Box>

        {/* Expand Lines */}
        <Checkbox
          isChecked={showAllLines}
          onChange={(e) => setShowAllLines(!showAllLines)}
        >
          Expand Lines
        </Checkbox>
        <Checkbox
          isChecked={showGroupped}
          onChange={(e) => setShowGroupped(!showGroupped)}
        >
          Group by product
        </Checkbox>
      </Flex>

      {/* Main Table */}
      <TableContainer>
        <Table variant="striped" cellPadding={0} size={"sm"}>
          <Thead>
            <Tr>
              <Th textTransform={"none"}>Details</Th>
              {selectedColumn.mainColumn.map((item, index) => {
                return (
                  <Th key={index} textTransform={"none"}>
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          { !showGroupped ? (
          <Tbody>
            {purchaseOrders.map((list, listIndex) => {
              return (
                <Fragment key={listIndex}>
                  <Tr>
                    <Td>
                      <HStack>
                        <Button
                          variant={"outline"}
                          colorScheme={"teal"}
                          size={"sm"}
                          onClick={() => {
                            setShowLineDataTable(!showLineDataTable);
                            setSelectedPurchaseOrderId(list.PurchaseOrderID);
                            setSelectedLineIndex(listIndex);
                            setOpenChildTable(!openChildTable);
                          }}
                        >
                          {showLineDataTable &&
                          selectedPurchaseOrderId === list.PurchaseOrderID ? (
                            <GrClose />
                          ) : (
                            <InfoIcon />
                          )}
                        </Button>
                      </HStack>
                    </Td>
                    {selectedColumn.mainColumn.map((item) => {
                      return <Td key={item}>{list[item]}</Td>;
                    })}
                  </Tr>

                  {/* Line Table */}
                  {(showAllLines ||
                    (showLineDataTable &&
                      selectedPurchaseOrderId === list.PurchaseOrderID)) && (
                    <LinesDataTable
                      selectedLineIndex={
                        showAllLines ? listIndex : selectedLineIndex
                      }
                    />
                  )}
                </Fragment>
              );
            })}
          </Tbody>
          ) : (
            <Tbody>
                {purchaseOrdersGroupped?.map((list, listIndex) => {
              return (
                <Fragment key={listIndex}>
                  <Tr>
                    <Td>
                      <HStack>
                        <Button
                          variant={"outline"}
                          colorScheme={"teal"}
                          size="sm"
                          onClick={onOpen}
                        >
                          <DownloadIcon />
                        </Button>
                      </HStack>
                    </Td>
                    {selectedColumn.mainColumn.map((item) => {
                      return <Td key={item}>{list[item]}</Td>;
                    })}
                  </Tr>

                  {/* Line Table */}
                  {(showAllLines ||
                    (showLineDataTable &&
                      selectedPurchaseOrderId === list.PurchaseOrderID)) && (
                    <LinesDataTable
                      selectedLineIndex={
                        showAllLines ? listIndex : selectedLineIndex
                      }
                    />
                  )}
                </Fragment>
              )})}
            </Tbody>
          )
        }
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PurchaseReportsTable;
