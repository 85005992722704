import React from "react";
import { Box, Spinner as ChakraSpinner, HStack, Text } from "@chakra-ui/react";

export function Spinner({ size = "md", thickness = "2px", style }) {
  return (
    <Box alignContent={"center"}>
      <HStack>
        <ChakraSpinner
          speed="0.65s"
          emptyColor="gray.200"
          color="teal.500"
          thickness={thickness}
          size={size}
          sx={style}
        />
        <Text fontWeight={"semibold"}>Loading..</Text>
      </HStack>
    </Box>
  );
}

export default Spinner;
