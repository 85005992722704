import React, { useEffect, useState } from 'react';

import Layout from './Layout';
import { useContext } from "react";
import UserContext from '../auth/UserContext';
import dataApi from '../data/dataApi';
import { Heading, Spinner, VStack } from '@chakra-ui/react';

const SubscriptionPayment = () => {
	const { currentUser, setCurrentUser } = useContext(UserContext);

	useEffect(() => {
		const user = {...currentUser, subscribed: true};
		localStorage.setItem('user', JSON.stringify(user));
		setCurrentUser(user);
	}, []);

	return (
		<>
			<Layout>
				<VStack spacing={2} p={5}>
					<Heading as="h6" size="md">Congratulations!</Heading>
				</VStack>
				
				<p>Payment was successfull! You can now use the app.</p>
				<p>If it's not working immediately, please try logging off and on.</p>
			</Layout>
		</>
	);
};

export default SubscriptionPayment;