import React, {useEffect, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../auth/UserContext';
import { Box, Center, Heading } from "@chakra-ui/react"
// components
import SidebarWithHeader from "../components/SidebarWithHeader"
import Subscriptions from "../components/Settings/Subscriptions"
import SubscriptionList from '../components/SubscriptionList';

const SubscriberLayout = ({ children }) => {
    const navigate = useNavigate();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [ subscribed, setSubscribed ] = useState(JSON.parse(localStorage.getItem('user'))?.subscribed || JSON.parse(localStorage.getItem('user'))?.free_trial);
    useEffect(() => {
        if (currentUser.free_trial) {
            setSubscribed(true);
        }

        else {
            setSubscribed(currentUser.subscribed);
        }
    }, [currentUser]);

	return (
		<>
			{/* <Navbar /> */}
            {/* <Sidebar>
			{ children }
            </Sidebar> */}

            <SidebarWithHeader>
            { 
            subscribed ? children : (
            <>
                <Center>
                    <Box>
                        <Heading size="md" mb={3} mt={3}>Please select a plan</Heading>
                        <SubscriptionList w="min-content" />
                    </Box>
                </Center>
            </>
            ) }
            </SidebarWithHeader>
		</>
	);
};

export default SubscriberLayout;