/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "@chakra-ui/react";
import dataApi from "../data/dataApi";
import UserContext from "../auth/UserContext";
import { mockCustomerOrders, mockCustomerSearch } from "../data/mock";

export const CustomerReportContext = createContext({});

export function CustomerReportProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [customerOrdersWithLines, setCustomerOrdersWithLines] = useState([]);
  const [showAllLines, setShowAllLines] = useState(false);
  const [showGroupped, setShowGroupped] = useState(false);
  const [mainFields, setMainFields] = useState([]);
  const [lineFields, setLineFields] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDateError, setShowDateError] = useState(false);
  const [customerOrdersGroupped, setCustomerOrdersGroupped] = useState([]);
  const [small, setSmall] = useState(false);

  const defaultFields = {
    mainColumn: [
      "InvoiceToName",
      "OrderNumber",
      "OrderDate",
      "Created",
      "AmountFCExclVat",
      "Status",
      "InvoiceStatus",
      "DeliveryStatus",
    ],
    lineColumn: [
      "item_code",
      "quantity",
      "line_total",
      "item_description",
      "order_status",
    ],
  };

  useEffect(() => {
    if (small) {
      setSelectedColumn({
        mainColumn: ["OrderNumber", "OrderDate", "AmountFCExclVat", "TotalCost"],
        lineColumn: ["item_code", "item_description", "quantity"],
      })
    }
    else {
      setSelectedColumn({
        mainColumn: defaultFields.mainColumn,
        lineColumn: defaultFields.lineColumn,
      });
    }
  }, [small]);

  const [selectedColumn, setSelectedColumn] = useState({
    mainColumn: defaultFields.mainColumn,
    lineColumn: defaultFields.lineColumn,
  });

  useEffect(() => {
      if (showGroupped) {
        setSelectedColumn({
          mainColumn: ["InvoiceToName", "DateRange"],
          lineColumn: ["item_code", "item_description", "quantity"],
        })
      }

      else {
        setSelectedColumn({
          mainColumn: defaultFields.mainColumn,
          lineColumn: defaultFields.lineColumn,
        });
      }
  }, [showGroupped]);

  // Context
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  // Functions
  // On Generate Customer Report
  const getCustomerOrderData = async (invoice_to, start_date, end_date) => {
    
    if (process.env.REACT_APP_ENV == "local") {
      let data = mockCustomerOrders.data;
      setCustomerOrders(
        data.map((list) => {
          return list.sales_order;
        })
      );
      setCustomerOrdersWithLines(data);      
      setCustomerOrdersGroupped(calculateTotalQuantities(data));
      return;
    }

    console.log(
      "log for invoice_to from get: getCustomerOrderData",
      invoice_to
    );
    try {
      // prepare params
      const params = {
        per_page: "",
      };

      if (start_date) {
        params.start_date = start_date;
      }
      if(end_date) { 
        params.end_date = end_date;
      }

      setIsLoading(true);
      // request customer report
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      const res = await dataApi.post(`/customer/${invoice_to}/orders`, params);
      setIsLoading(false);

      const { data = [], message, success = false } = res.data;
      if (data.length > 0 && success) {
        // set res data in states
        setCustomerOrders(
          data.map((list) => {
            return list.sales_order;
          })
        );
        setCustomerOrdersWithLines(data);
        setCustomerOrdersGroupped(calculateTotalQuantities(data));
        return true;
      } else {
        toast({
          position: "top",
          title: message,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        position: "top",
        title: error.message,
        status: "error",
        isClosable: true,
      });
      return false;
    }
  };

  function calculateTotalQuantities(data) {
    const totals = [];
    let orders = JSON.parse(JSON.stringify(data));

    orders.forEach((order) => {
      const customerId = order.sales_order.InvoiceToName;
      let dateRange = `${startDate || "*"} - ${endDate || new Date().toISOString().slice(0, 10)}`;

      const lines = order.lines;
  
      let customerTotal = totals.find((total) => total.InvoiceToName === customerId);
      if (!customerTotal) {
        customerTotal = { InvoiceToName: customerId, DateRange: dateRange, lines: [] };
        totals.push(customerTotal);
      }
  
      lines.forEach((line) => {
        const { item_code, item_description, quantity } = line;
        const existingLine = customerTotal.lines.find((l) => l.item_code === item_code);
        if (existingLine) {
          existingLine.quantity += quantity;
        } else {
          customerTotal.lines.push(line);
        }
      });
    });
  
    return totals;
  }

  const onExportCsv = () => {
    if (showAllLines) {
      // get table heading
      const tableHeading = [
        ...selectedColumn.mainColumn,
        ...selectedColumn.lineColumn,
      ];
      // get main table values
      const orders = showGroupped ? customerOrdersGroupped : customerOrders;

      const allSelectedMainValues = orders.map((list) => {
        return selectedColumn.mainColumn.map((item) => {

          if(item == "AmountFCExclVat" || item == "TotalCost"){
            return "€ " +String(list[item].toFixed(2));
          }else {
            return String(list[item]);
          }
        });
      });
      // get line table values

      const ordersWithLines = showGroupped ? customerOrdersGroupped : customerOrdersWithLines;
      const allSelectedLineValues = ordersWithLines.map((list) => {
        return list.lines.map((line) => {
          return selectedColumn.lineColumn.map((key) => {
            return String(line[key]);
          });
        });
      });
      // merge main table & line table values
      let tableBody = [];
      allSelectedLineValues.map((lineItem, index) => {
        return lineItem.map((arr) => {
          return (tableBody = [
            ...tableBody,
            [allSelectedMainValues[index].concat(arr)],
          ]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody.flat()]);
    }

    if (!showAllLines && customerOrders.length > 0) {
      // get table heading
      const tableHeading = selectedColumn.mainColumn;
      // get main table values
      const tableBody = customerOrders.map((list) => {
        return selectedColumn.mainColumn.map((item) => {
          return String(list[item]);
        });
      });
      // set csv data
      setCsvData([tableHeading, ...tableBody]);
    }
  };

  // Hooks
  // Get All Columns for Main and Line Table
  useEffect(() => {
    if (customerOrdersWithLines.length > 0) {
      const findFields = customerOrdersWithLines.find((item) => {
        return item.sales_order;
      });
      setMainFields(Object.keys(findFields?.sales_order));
      setLineFields(Object.keys(findFields?.lines[0]));
    }
  }, [customerOrdersWithLines]);

  // Wrapping the Stuff in a useMemo for Performance Reason
  const contextPayload = useMemo(
    () => ({
      // States
      defaultFields,
      isLoading,
      setIsLoading,
      customerOrders,
      setCustomerOrders,
      customerOrdersWithLines,
      setCustomerOrdersWithLines,
      showAllLines,
      setShowAllLines,
      mainFields,
      setMainFields,
      lineFields,
      setLineFields,
      selectedColumn,
      setSelectedColumn,
      csvData, 
      setCsvData,
      showExportCsvModal,
      setShowExportCsvModal,
      onExportCsv,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      showDateError,
      setShowDateError,
      customerOrdersGroupped,
      setCustomerOrdersGroupped,
      showGroupped, 
      setShowGroupped,
      setSmall,

      // Functions
      getCustomerOrderData,
      calculateTotalQuantities,
    }),
    [
      // States
      defaultFields,
      isLoading,
      setIsLoading,
      customerOrders,
      setCustomerOrders,
      customerOrdersWithLines,
      setCustomerOrdersWithLines,
      showAllLines,
      setShowAllLines,
      mainFields,
      setMainFields,
      lineFields,
      setLineFields,
      selectedColumn,
      setSelectedColumn,
      csvData, 
      setCsvData,
      showExportCsvModal,
      setShowExportCsvModal,
      onExportCsv,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      showDateError,
      setShowDateError,
      customerOrdersGroupped,
      setCustomerOrdersGroupped,
      showGroupped, 
      setShowGroupped,
      setSmall,

      // Functions
      getCustomerOrderData,
      calculateTotalQuantities,
    ]
  );

  return (
    <CustomerReportContext.Provider value={contextPayload}>
      {children}
    </CustomerReportContext.Provider>
  );
}

export const useCustomerReport = () => useContext(CustomerReportContext);

export default CustomerReportContext;
