/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "@chakra-ui/react";
import dataApi from "../data/dataApi";
import UserContext from "../auth/UserContext";
import { mockCustomers } from "../data/mock";

export const CustomersContext = createContext({});

export function CustomersProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(null);

  // Context
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  // Functions
  
  // Wrapping the Stuff in a useMemo for Performance Reason
  const contextPayload = useMemo(
    () => ({
      customer,
      setCustomer,
      isLoading,
      setIsLoading
    }),
    [
      customer,
      setCustomer,
      isLoading,
      setIsLoading
    ]
  );

  return (
    <CustomersContext.Provider value={contextPayload}>
      {children}
    </CustomersContext.Provider>
  );
}

export const useCustomers = () => useContext(CustomersContext);

export default CustomersContext;
