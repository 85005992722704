import { useContext } from "react";
import { Box, Button, Alert, VStack, Center } from "@chakra-ui/react";
import { AlertIcon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import UserContext from "../auth/UserContext";
import { Inventory as InventoryComponent } from "../components/Inventory/index";
import SubscriberLayout from "./SubscriberLayout";

export const CustomerOrderReports = () => {
  // Context
  const { currentUser } = useContext(UserContext);

  return (
    <>
      <SubscriberLayout>
        <Box alignContent={"baseline"}>
          {currentUser?.token ? (
            <InventoryComponent />
          ) : (
            <>
              <Center>
                <VStack spacing={6}>
                  <Alert status="warning">
                    <AlertIcon />
                    Seems your're not logged in, to see this page please log in.
                  </Alert>
                  <Box>
                    <Link to="/">
                      <Button variant="solid" colorScheme="teal">
                        Log in
                      </Button>
                    </Link>
                  </Box>
                </VStack>
              </Center>
            </>
          )}
        </Box>
      </SubscriberLayout>
    </>
  );
};

export default CustomerOrderReports;
