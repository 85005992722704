import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export function DateRangePicker({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showDateError,
  setShowDateError,
  showLabel = true,
}) {
  // state
  const [showDateRange, setShowDateRange] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const refOnClick = useRef(null);

  // function to handle date change
  const handleDateChange = (date) => {
    setRange([date.selection]);
    setStartDate(format(date.selection.startDate, "yyyy-MM-dd"));
    setEndDate(format(date.selection.endDate, "yyyy-MM-dd"));
    setShowDateError(false);
  };
  // function to hide date picker when user click outside
  const handleOnClickOutside = (event) => {
    if (refOnClick.current && !refOnClick.current.contains(event.target)) {
      setShowDateRange(false);
    }
  };

  // hooks
  useEffect(() => {
    document.addEventListener("click", handleOnClickOutside, true);
  }, [startDate]);

  // change dates range on loading filter
  useEffect(() => {
    if (startDate.length > 0 && endDate.length > 0)
      setRange([
        {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: "selection",
        },
      ]);
  }, [startDate, endDate]);

  return (
    <Box display={"inline-block"} position={"relative"}>
      <HStack>
        { showLabel && <Text color={showDateError ? "red.500" : "black"}>Select Date:</Text> }
        <Box w={"13rem"}>
          <InputGroup
            size="sm"
            onClick={() => setShowDateRange(!showDateRange)}
          >
            <InputLeftElement width="3rem">
              <CalendarIcon mr={3} />
            </InputLeftElement>
            <Input
              value={
                startDate && endDate
                  ? `${format(range[0].startDate, "MMM dd")} - ${format(
                      range[0].endDate,
                      "MMM dd, yyyy"
                    )}`
                  : "Start - End Date"
              }
              focusBorderColor={"transparent"}
              borderColor={"transparent"}
              _hover={{ borderColor: "transparent" }}
              bg={"white"}
              readOnly
            />
          </InputGroup>
        </Box>
      </HStack>

      <Box ref={refOnClick}>
        {showDateRange && (
          <Box className="dateRangePickerElement">
            <DateRange
              onChange={handleDateChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={range}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="backwards"
              maxDate={new Date()}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DateRangePicker;
