import { useContext, useEffect, useState } from "react";
import UserContext from "../../auth/UserContext";
import {
  Box,
  Heading,
  Center,
  VStack,
  Text,
  Button,
  Stack,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { CheckIcon, LinkIcon, CloseIcon } from "@chakra-ui/icons";
import dataApi from "../../data/dataApi";
import { useNavigate } from "react-router-dom";
import EmailSettings from "./EmailSettings";

function UserProfile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [userSettings, setUserSettings] = useState({});
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const fetchRedirectUrl = async () => {
      dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
      let redirectUrl = await dataApi.get("/accounting/login");
      setRedirectUrl(redirectUrl.data.redirect_url);
    };
    if (!currentUser.connected && currentUser?.token) {
      fetchRedirectUrl().catch(console.error);
    }
  }, [currentUser]);

  function handleDisconnect() {
    dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;
    dataApi.post("/accounting/logout").then(() => {
      setCurrentUser({ ...currentUser, connected: false });
    }).catch(err => {
      console.error("Error disconnecting", err);
    });
  }

  return (
    <>
      <Card>
        <VStack w="600px" pl={16} pt={5} pb={5} alignItems="flex-start"> 
          <Heading as="h5" mb={3} id="login-form" size={"large"}>
            User Profile
          </Heading>

          <Box rounded="md" w="100%">
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Name
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                <Text fontWeight="bold">{currentUser.name}</Text>
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Email
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                <Text fontWeight="bold">{currentUser.email}</Text>
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                Exact Connection
              </GridItem>
              <GridItem
                w="100%"
                h="10"
                textAlign="left"
                verticalAlign="baseline"
              >
                {currentUser.connected ? (
                  <Stack direction="row" spacing={4} align="center">
                    <Button
                      leftIcon={<CheckIcon />}
                      colorScheme="teal"
                      variant="outline"
                    >
                      Connected to Exact.online
                    </Button>
                    <Button
                      leftIcon={<CloseIcon />}
                      colorScheme="red"
                      variant="link"
                      onClick={onOpen}
                    >
                      Disconnect
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    rightIcon={<LinkIcon />}
                    colorScheme="teal"
                    variant="solid"
                    onClick={() => {
                      window.location.href = redirectUrl;
                    }}
                  >
                    Connect to Exact.online
                  </Button>
                )}
              </GridItem>
            </Grid>
          </Box>

        </VStack>
      </Card>


      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Disconnecting</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Are you sure you want to disconnect exact.online?</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={
              () => {
                console.log("yoow");
                handleDisconnect();
                onClose();
              }
            }>
              Confirm
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UserProfile;
