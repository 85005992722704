import { Card, Center, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import EmailSettings from "./EmailSettings";
import UserProfile from "./UserProfile";
import SecuritySettings from "./SecuritySettings";
import Subscriptions from "./Subscriptions";

function Settings() {
  return (
    <>
      <Center>
        <Tabs variant='line' colorScheme="teal" orientation="vertical">
          <Card p={3} w={300} mt={4} h="fit-content">
            <TabList>
              <Tab>Profile</Tab>
              <Tab>Email</Tab>
              <Tab>Security</Tab>
              <Tab>Subscriptions</Tab>
            </TabList>
          </Card>
          <TabPanels>
            <TabPanel>
              <UserProfile />
            </TabPanel>
            <TabPanel>
              <EmailSettings />
            </TabPanel>
            <TabPanel>
              <SecuritySettings />
            </TabPanel>
            <TabPanel>
              <Subscriptions />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Center>
    </>
  );
}

export default Settings;
