import React from 'react';
import { Heading } from '@chakra-ui/react';

import Layout from './Layout';

const Privacy = () => {
	return (
		<>
			<Layout>
             <Heading mt={10}>Privacy Policy</Heading>
             {/* HTML with T&C markup, update */}
				<h3>exacter.online Privacy Policy</h3>
				exacter.online "the App” provides Quick and easy workflows for businesses that use Exact Online. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your store.<br /><br />
				<p><strong>Personal Information the App Collects</strong><br />
				When you signup for the App, and connect to exact online we save your e-mail address and your api token to connect to exact online.
				<br />
				We collect your login information through cookies, “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
				</p>
				<p>
					<strong>How Do We Use Your Personal Information?</strong><br />
				We use the personal information we collect from you in order to provide the Service and to operate the App.
				</p>
				<p>


				<strong>Data Retention</strong><br />
				We do not retain any customer, order, product or any other exact online information on our servers, we fetch all needed data directly through the API connection upon your request, and do not store it in any way.
				</p>
				<p>
					<strong>Changes</strong><br />
				We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
				</p>
				<p>
				<strong>Contact Us</strong><br />
				For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@tom-it.nl
				</p>
			</Layout>
		</>
	);
};

export default Privacy;