import api from './authApi';

export const login = async (username, password) => {
	const response = await api.post('login', {
		"email" : username,
		"password": password
	});

	const token = response.data.data.token;
    console.log(response.data.data);
	if (token) {
		localStorage.setItem('user', JSON.stringify(response.data.data));
	}

	return response.data.data;
};

export const isAuthenticated = () => {
	const user = localStorage.getItem('user');
	if (!user) {
		return {}
	}
	return JSON.parse(user);
}