import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../auth/UserContext";
import { SiStripe } from 'react-icons/si'
import {
  Alert,
  AlertIcon,
  Box,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
import dataApi from "../../data/dataApi";
import { PricingCard } from "./Pricing/PricingCard";
import { ActionButton } from "./Pricing/ActionButton";
import SubscriptionList from "../SubscriptionList";
import SubscriptionOptions from "../SubscriptionOptions";


const Subscriptions = (props) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [ daysLeft, setDaysLeft ] = useState(0);

  useEffect(() => {
    if (currentUser.subscribed && !currentUser.subscription) {
        dataApi.defaults.headers.common["Authorization"] = "Bearer " + currentUser?.token;
        dataApi.get("/auth/user").then((response) => {
            const user = response.data.data;
            setCurrentUser({
              ...user,
              token: currentUser.token,
            });
            
        });
    }
    if (currentUser.free_trial) {
      const currentDate = new Date();
      const targetDate = new Date(currentUser.created_at);

      const timeDiff = targetDate.getTime() - currentDate.getTime();
      const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      let daysUntil20DaysLater = daysLeft + 20;
      setDaysLeft(daysUntil20DaysLater);
    }
  }, []);

  return (
    <>
        {(currentUser.subscribed) ? <SubscriptionOptions /> : (
            !currentUser.subscribed && currentUser.free_trial && (
              <>
                <Alert status='info' mb={2} rounded="md">
                  <AlertIcon />
                    You have {daysLeft} days left on your free trial.
                </Alert>
                <SubscriptionList />
              </>
              )
               
          )
        }
    </>
  );
};

export default Subscriptions;
