import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Input,
  Tfoot
} from "@chakra-ui/react";
import { TbColumns3 } from "react-icons/tb";
import MultiSelect from "../MultiSelect";
import { useInvoiceReport } from "../../context/invoiceReportContext";
import { GrClose } from "react-icons/gr";
import { RepeatIcon, InfoIcon } from "@chakra-ui/icons";
import LinesDataTable from "./LinesDataTable";
import { response } from "msw";
import dataApi from "../../data/dataApi";
import UserContext from "../../auth/UserContext";

export function InvoiceReportsTable({small=false}) {
  // State
  const [showLineDataTable, setShowLineDataTable] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [selectedLineIndex, setSelectedLineIndex] = useState();
  const [resendIsLoading, setResendIsLoading] = useState(false);

  // Context
  const { currentUser } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    invoiceOrders,
    mainFields,
    lineFields,
    showAllLines,
    setShowAllLines,
    defaultFields,
    selectedColumn,
    setSelectedColumn,
    setSmall
  } = useInvoiceReport();

  useEffect(() => {
    setSmall(small);
  }, []);

  const menuItem = [
    {
      title: "Main Columns",
      itemList: mainFields,
      checkedItems: selectedColumn.mainColumn,
    },
    {
      title: "Line Columns",
      itemList: lineFields,
      checkedItems: selectedColumn.lineColumn,
    },
  ];

  const toast = useToast();

  const resendInvoice = () => {
    dataApi.defaults.headers.common["Authorization"] =
        "Bearer " + currentUser?.token;

    setResendIsLoading(true);
    dataApi
        .post('/invoices/resend_pdf_invoice', {
          invoice_id: selectedInvoiceId
        })
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            toast({
              position: "top",
              title: "Invoice resend successfully",
              status: "success",
              isClosable: true,
            });
          }
          else {
            toast({
              position: "top",
              title: "Error occured while resending invoice",
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          toast({
            position: "top",
            title: "Error occured while resending invoice",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          setResendIsLoading(false);
          onClose();
        });    
  };

  return (
    <Box>
      {!small && (
      <Flex px={4} gap={5}>
        {/* Column Filter */}
        <Box>
          <MultiSelect
            label="Columns"
            icon={<TbColumns3 />}
            items={menuItem}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            defaultFields={defaultFields}
            selectDefaultOption
          />
        </Box>

        {/* Expand Lines */}
        <Checkbox
          isChecked={showAllLines}
          onChange={(e) => setShowAllLines(!showAllLines)}
        >
          Expand Lines
        </Checkbox>
      </Flex>
      )}
      {/* Main Table */}
      <TableContainer>
        <Table variant="striped" cellPadding={0} size={"sm"}>
          <Thead>
            <Tr>
              {!small && <Th textTransform={"none"}>Actions</Th>}


              {selectedColumn.mainColumn.map((item, index) => {
                return (
                  <Th key={index} textTransform={"none"}>
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {invoiceOrders.map((list, listIndex) => {
              return (
                <Fragment key={listIndex}>
                  
                  <Tr>
                    {!small && (
                    <Td>
                      <HStack>
                        <Button
                          variant={"outline"}
                          colorScheme={"teal"}
                          size="sm"
                          onClick={() => {
                            onOpen();
                            setSelectedInvoiceId(list.InvoiceID);
                          }
                          }
                        >
                          <RepeatIcon />
                        </Button>
                      </HStack>
                    </Td>
                    )}
                    {selectedColumn.mainColumn.map((item) => {
                      return <Td key={item}>{list[item]}</Td>;
                    })}
                  </Tr>
                  

                  {/* Line Table */}
                  {(showAllLines ||
                    (showLineDataTable ))&& (
                    <LinesDataTable
                      selectedLineIndex={
                        showAllLines ? listIndex : selectedLineIndex
                      }
                    />
                  )}
                </Fragment>
              );
            })}
          </Tbody>


          {small && (
          <Tfoot>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th>&euro; {invoiceOrders.length > 0 && (invoiceOrders.reduce((accumulator, order) => accumulator + order.AmountFCExclVat, 0)).toFixed(2)}</Th>
            </Tr>
          </Tfoot>
          )}


        </Table>
      </TableContainer>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resend Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Are you sure you want to resend this invoice?</ModalBody>

          <ModalFooter>
            <Button onClick={resendInvoice} colorScheme="blue" mr={3} loadingText="Sending" isLoading={resendIsLoading}>
                Resend
            </Button>
            
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default InvoiceReportsTable;
