import React from "react";
import { Box, Center } from "@chakra-ui/react";
import Spinner from "../Spinner";
import { useCustomerReport } from "../../context/customerReportContext";
import { InventoryFilter } from "./InventoryFilter";
import CustomerReportsTable, { InventoryTable } from "./InventoryTable";
import { useInventory } from "../../context/inventoryContext";

export const Inventory = () => {
  // Context
  const { isLoading, inventory } = useInventory();

  return (
    <>
      {/* filter bar */}
      <Box bg={"#D9D9D9"} px={4} pt={3} mb={5} h={16} alignItems={"center"}>
        <InventoryFilter />
      </Box>

      {isLoading && (
        <Center>
          <Box>
            <br />
            <Spinner />
          </Box>
        </Center>
      )}

      {/* Main Data Table */}
      {!isLoading && inventory.length > 0 && <InventoryTable />}
    </>
  );
};

export default Inventory;
